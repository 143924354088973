import React, { useState, Fragment, useEffect } from "react";
import Logo from "../../../../assets/images/logo.png";
import "./LayoutMenu.scss";
import httpClient from "../../../services/httpClient";
import ROUTES from "../../../routes";
import store, { Message, Stored } from "../../../services/store";
import qs from "qs";
import { useForceUpdate } from "../../../utils";
import { CircleProgress } from "react-gradient-progress";
import ReactTooltip from "react-tooltip";
import { t } from "../../../services/i18n";
import ProfilePicturePlaceholder from "../../../../assets/images/profile_picture_placeholder.png";
import User from "../../../types/user";
import CampaignCompletion from "../../../types/campaign_completion";
import { HiOutlineLightBulb } from "react-icons/hi";
import { SpotlightTour, useSpotlight } from "react-spotlight-tour";
import Spotlight from "react-spotlight-tour/spotlight";

interface tuto {
  active?: boolean;
}

function Tutorial({ active }: tuto) {
  const spotlightRef = useSpotlight("Click to see the tutorial", "right");

  return active ? (
    <a
      href="/tutorial"
      className="didactitielButton"
      data-tip={t("Didactitiel")}
      ref={spotlightRef}
    >
      <HiOutlineLightBulb
        style={{ height: "1.5em", width: "1.5em" }}
      ></HiOutlineLightBulb>
    </a>
  ) : (
    <a
      href="/tutorial"
      className="didactitielButton"
      data-tip={t("Didactitiel")}
    >
      <HiOutlineLightBulb
        style={{ height: "1.5em", width: "1.5em" }}
      ></HiOutlineLightBulb>
    </a>
  );
}

export default () => {
  const [completions, setCompletions] = useState<CampaignCompletion>(undefined);
  const forceUpdate = useForceUpdate();
  const [isOpen, setOpen] = useState(true);
  const [tutoIsClicked, setTutoIsClicked] = useState(false);
  const [user, setUser] = useState<User>(undefined);
  const [isTutoActive, setIsTutoActive] = useState(false);
  let inSession: boolean = undefined;

  store.listen(Message.PreviousContent, forceUpdate);
  store.listen(Message.NextContent, forceUpdate);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await fetchUser()
    fetchCompletions();
  };

  const fetchUser = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_ME());
      setUser(res);
      if (res.signInCount <= 2) {
        setIsTutoActive(true);
      }
    } catch (e) {
      store.notify(Message.Error, "Impossible de récupérer le profil");
      console.warn(e);
    }
  };

  const fetchCompletions = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_CAMPAIGN_COMPLETION({
          id: store.store.JWT.current_campaign_id,
        })
      );
      setCompletions(res);
    } catch (e) {
      store.notify(Message.Error, t("Impossible de récupérer la complétion"));
      console.warn(e);
    }
  };

  store.listen(Message.RefreshMenu, fetchData);

  const displayProfile = () =>
    user && (
      <div data-tip={t("Profil")} className="Layout__menuScoringProfilePicture">
        <div className="Layout__menuScoringProfilePictureContentWrapper">
          {completions && (
            <div className="Layout__menuScoringProfilePictureCompletion">
              <CircleProgress
                width={75}
                percentage={Math.floor(completions.contentsCompletion)}
                strokeWidth={6}
                primaryColor={["#ad1547", "#7a1c75"]}
                secondaryColor="#dedede"
              />
            </div>
          )}
          <a href="/my_account">
            <img
              src={user?.imageUrl ? user.imageUrl : ProfilePicturePlaceholder}
              className="Layout__menuScoringProfilePictureContent"
            />
          </a>
        </div>
        <ReactTooltip place="bottom" type="dark" effect="solid" />
        {/* <div className="Layout__menuScoringProfilePicturePoints">
          {displayLanguages()}
          {t('%points points', { points: user.points })}
        </div> */}
      </div>
    );

  const displayInfos = () => {
    return (
      <div className="Layout__menuScoringInfos">
        {completions && (
          <div
            data-tip={t("Points obtenus")}
            className="Layout__menuScoringInfosPoints Layout__menuScoringInfosStat"
          >
            <div className="Layout__menuScoringInfosStatValue">
              {completions.ranking.points}
            </div>
            <div className="Layout__menuScoringInfosStatLabel">
              {t("points")}
            </div>
          </div>
        )}
        <ReactTooltip place="bottom" type="dark" effect="solid" />
        {completions && (
          <div
            data-tip={t("Classement sur la campagne")}
            className="Layout__menuScoringInfosRank Layout__menuScoringInfosStat"
          >
            <div className="Layout__menuScoringInfosStatValue">
              {completions.ranking.rank}
              <span>e</span>
            </div>
            <div className="Layout__menuScoringInfosStatLabel">
              {t("sur %number", { number: completions.ranking.total })}
            </div>
          </div>
        )}
        <ReactTooltip place="bottom" type="dark" effect="solid" />
        {completions && (
          <div
            data-tip={t("Pourcentage de complétion de la formation actuelle")}
            className="Layout__menuScoringInfosCompletion Layout__menuScoringInfosStat"
          >
            <div className="Layout__menuScoringInfosStatValue">
              {Math.floor(completions.contentsCompletion)}
              <span>%</span>
            </div>
            <div className="Layout__menuScoringInfosStatLabel">
              {t("complétion")}
            </div>
            <ReactTooltip place="bottom" type="dark" effect="solid" />
          </div>
        )}
      </div>
    );
  };

  return (
    <SpotlightTour
      open={isOpen}
      onClose={() => setOpen(false)}
      Spotlight={Spotlight}
    >
      <Fragment>
        <div className="Layout__menu">
          {inSession &&
            (qs.parse(location.search.replace("?", "")).idx == "0" ? (
              <div className="mobileVisible"></div>
            ) : (
              <div
                className="Layout__menuPrevious mobileVisible"
                onClick={() => store.notify(Message.PreviousContent)}
              >
                <i>keyboard_arrow_left</i>
                {t("Précédent")}
              </div>
            ))}
          {user && (
            <>
              <div
                className={`Layout__menuLogo${
                  inSession ? " mobileHidden" : ""
                }`}
              >
                <a href="/">
                  {store.store.JWT.license_logo_url ? (
                    <img src={store.store.JWT.license_logo_url} />
                  ) : (
                    <img src={Logo} />
                  )}
                </a>
              </div>
              <ReactTooltip place="bottom" type="dark" effect="solid" />
              {isTutoActive ? (
                <Tutorial active={true} />
              ) : (
                <Tutorial active={false} />
              )}
            </>
          )}
          {user && (
            <div
              className={`Layout__menuScoring${
                inSession ? " mobileHidden" : ""
              }`}
            >
              {completions &&
                completions.themesCompletion.length > 0 &&
                displayInfos()}
              <div className="Layout__menuScoringProfile">
                {displayProfile()}
              </div>
            </div>
          )}
          {inSession && (
            <div
              className="Layout__menuQuit mobileVisible"
              onClick={() => store.notify(Message.QuitSession)}
            >
              {t("Quitter")}
            </div>
          )}
        </div>
      </Fragment>
    </SpotlightTour>
  );
};
