import React from 'react'
import './ProgressBarTarget.scss'
import ReactTooltip from 'react-tooltip'
import { t } from '../../services/i18n'


interface Props {
    target: number
    progress: number
    average: number
    colorStart: string
    colorEnd: string
}

export default (props: Props) => {
    return (
        <div className="ProgressBar">
            <div className='ProgressBar__number' style={{ marginLeft: `${(props.progress / 2) + 0.5}%` }}>{props.progress}%</div>
            <div className='ProgressBar__target'
                style={{
                    marginLeft: `${props.target}%`
                }} data-tip data-for='target'>
                <ReactTooltip id='target'>
                    <span>{t('La complétion requise pour obtenir la certification est de ')} {props.target}%</span>
                </ReactTolltip>
            </div>
            <div className='ProgressBar__averagepeople' style={{
                marginLeft: `${props.average}%`
            }} data-tip data-for='average'>
                <ReactTooltip id='average'>
                    <span>{t('La complétion moyenne est de ')} {props.average}%</span>
                </ReactTolltip>
            </div>
            <div
                className="ProgressBar__fill"
                style={{
                    width: `${props.progress}%`,
                    background: `linear-gradient(90deg,${props.colorStart} 0%,${props.colorEnd} 100%)`,
                }}
            />
        </div >
    )
}