export default {
  Connexion: {
    en: 'Login',
    fr: 'Connexion',
  },
  'Bienvenue sur la plateforme': {
    en: 'Welcome on the platform',
    fr: 'Bienvenue sur la plateforme',
  },
  'Rentrez ici vos identifiants pour accéder à la plateforme': {
    en: 'Fill in your ids here to access the platform',
    fr: 'Rentrez ici vos identifiants pour accéder à la plateforme',
  },
  Email: {
    en: 'Email',
    fr: 'Email',
  },
  'Mot de passe': {
    en: 'Password',
    fr: 'Mot de passe',
  },
  'Vous avez oublié votre mot de passe ?': {
    en: 'Did you forget your password?',
    fr: 'Vous avez oublié votre mot de passe ?',
  },
  'Vous êtes connecté avec succès': {
    en: 'You are successfully connected',
    fr: 'Vous êtes connecté avec succès',
  },
  'Email ou mot de passe incorrect': {
    en: 'Invalid email or password',
    fr: 'Email ou mot de passe incorrect',
  },
  "Votre plate-forme de sensibilisation ludique. Apprenez, progressez et certifiez-vous !": {
    en: "Your ludic learning platform. Learn, progress and certify yourself !",
    fr: "Votre plate-forme de sensibilisation ludique. Apprenez, progressez et certifiez-vous !",
  },
  'Réinitialisation du mot de passe': {
    en: 'Reset password',
    fr: 'Réinitialisation du mot de passe',
  },
  'Rentrez ici votre nouveau mot de passe': {
    en: 'Fill in your new password',
    fr: 'Rentrez ici votre nouveau mot de passe',
  },
  'Réinitialiser': {
    en: 'Reset',
    fr: 'Réinitialiser',
  },
  'Se connecter': {
    en: 'Login',
    fr: 'Se connecter',
  }
}
