import * as React from 'react'
import './SpacedRepetitionModal.scss'
import Modal from '../../../components/Modal'
import { CircleProgress } from 'react-gradient-progress'
import Session from '../../../types/session'
import { t } from '../../../services/i18n'
import Button from '../../../components/Button'
import Trophy from '../../../../assets/images/icons/trophy.png'
import { useHistory } from 'react-router-dom'
import store, { Message } from '../../../services/store'
import ROUTES from '../../../routes'
import httpClient from '../../../services/httpClient'

export interface SpacedRepetitionModalProps {
  session: Session
  contentsCount: number
  dismiss?: () => void
}

const SpacedRepetitionModal = (props: SpacedRepetitionModalProps) => {
  const history = useHistory()
  const numberOfRightAnswers = props.session.sessionContentAssociations.filter(
    (sca) => sca.status == 'succeeded'
  ).length

  const launchSession = async () => {
    try {
      const res = await httpClient.req(ROUTES.CREATE_SESSION())

      history.push(`/sessions/${res.id}`)
      location.reload()
    } catch (e) {
      store.notify(Message.Error, t('Impossible de créer la session'))
      console.warn(e)
    }
  }

  return (
    <Modal className="SpacedRepetitionModal" dismiss={props.dismiss}>
      <div className="SpacedRepetitionModal__counter">
        <CircleProgress
          width={200}
          percentage={Math.floor(
            (numberOfRightAnswers / props.contentsCount) * 100
          )}
          strokeWidth={15}
          primaryColor={['#1A7998', '#62a5e2']}
          secondaryColor="#e3e3e1"
        />
        <div className="SpacedRepetitionModal__counterValue">
          <div className="SpacedRepetitionModal__counterValueNumbers">
            <span>{numberOfRightAnswers}</span>/{props.contentsCount}
          </div>
          <div className="SpacedRepetitionModal__counterValueLabel">
            {t('Bonnes')}
            <br />
            {t('réponses')}
          </div>
        </div>
      </div>
      <div className="SpacedRepetitionModal__body">
        <div className="SpacedRepetitionModal__bodyTitle">
          {t('Félicitations !!!')}
        </div>
        <div className="SpacedRepetitionModal__bodyText">
          {t('Cette session est terminée')}
        </div>
        <div className="SpacedRepetitionModal__bodyPoints">
          <div className="SpacedRepetitionModal__bodyPointsTrophy">
            <img src={Trophy} />
          </div>
          +
          <span>
            {props.session.sessionContentAssociations
              .map((c) => c.pointsEarned)
              .reduce((a, b) => a + b, 0)}
          </span>{' '}
          {t('points')}
        </div>
        <div className="SpacedRepetitionModal__bodyButtons">
          {props.session.campaign.oneShot || (
            <Button
              title={t('Poursuivre avec une autre session')}
              onClick={launchSession}
              secondaryColor
            />
          )}
          {numberOfRightAnswers < props.contentsCount && (
            <Button
              title={t("Revoir les questions ratées")}
              onClick={props.dismiss}
              secondaryColor
            />
          )}
          <Button
            title={t('Quitter')}
            dangerColor
            onClick={() => {
              history.push('/')
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SpacedRepetitionModal
