import React, { useEffect, useState, Fragment } from 'react';
import './Home.scss';
import Button from '../../components/Button';
import ThemeCard from '../../components/ThemeCard';
import CertificationImage from '../../../assets/images/certification.png';
import SessionImage from '../../../assets/images/session.svg';
import BackgroundDesktop from '../../../assets/images/background_desktop.svg';
import BackgroundMobile from '../../../assets/images/background_mobile.svg';
import CertificationValidatedImage from '../../../assets/images/certification_validated.png';
import httpClient from '../../services/httpClient';
import ROUTES from '../../routes';
import store, { Message, Stored } from '../../services/store';
import Theme from '../../types/theme';
import { useHistory } from 'react-router-dom';
import { t } from '../../services/i18n';
import CampaignCompletion from '../../types/campaign_completion';
import { CampaignRegistration } from '../../types/campaign_registration';
import Campaign from '../../types/campaign';
import Loader from 'react-loader-spinner';
import ThemePreview from './ThemePreview';
import Background from '../../components/Background';
import { useMediaQuery } from 'react-responsive'
import License from '../../types/license';
export default () => {
  const [displayCampaignsModal, setDisplayCampaignsModal] = useState(false);
  const [displayThemeModal, setDisplayThemeModal] = useState(false);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<Theme>(undefined);
  const [completions, setCompletions] = useState<CampaignCompletion>(undefined);
  const [registration, setRegistration] =
    useState<CampaignRegistration>(undefined);
  const [campaigns, setCampaigns] = useState<Campaign[]>(undefined);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [myLicense, setMyLicense] = useState<License>(undefined)
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => { }, [campaigns]);

  useEffect(() => {
    setLoading(!(campaigns && completions && myLicense));
  }, [campaigns, completions, myLicense])

  useEffect(() => {
    const stickyElm = document.querySelector('.Home__sessions');
    if (stickyElm) {
      const observer = new IntersectionObserver(
        ([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1),
        { threshold: [1] }
      );

      observer.observe(stickyElm);
    }
  }, [campaigns, completions, myLicense]);

  document.onscroll = (element) => {
    const stickyElm = document.querySelector('.Home__sessions');
    if (document.documentElement.scrollTop > 140) {
      stickyElm.classList.add('isSticky');
      stickyElm.classList.remove('isNotSticky');
    }
    if (document.documentElement.scrollTop < 140) {
      stickyElm.classList.remove('isSticky');
      stickyElm.classList.add('isNotSticky');
    }
  };

  const fetchData = async () => {
    fetchMyLicense();
    fetchCampaigns();
    fetchCompletions();
    fetchThemes();
    if (store.store.JWT.current_campaign_id) {
      fetchRegistration();
    }
  };

  const fetchMyLicense = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_MY_LICENSE({ id: store.store.JWT.license_id }));
      setMyLicense(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les licenses'));
      console.warn(e);
    }
  }

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES({}));
      setThemes(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les thèmes'));
      console.warn(e);
    }
  };

  const fetchCampaigns = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGNS());

      setCampaigns(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les formations'));
      console.warn(e);
    }
  };

  const fetchRegistration = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_REGISTRATION({
          campaignId: store.store.JWT.current_campaign_id,
        })
      );

      setRegistration(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t("Impossible de vérifier l'inscription à la formation")
      );
      console.warn(e);
    }
  };

  const fetchCompletions = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_CAMPAIGN_COMPLETION({
          id: store.store.JWT.current_campaign_id,
        })
      );

      setCompletions(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer la complétion'));
      console.warn(e);
    }
  };

  const launchSession = async (themes?: Theme[], sessionType?: string) => {
    setLoading(true);
    try {
      const res = await httpClient.req(
        ROUTES.CREATE_SESSION({
          themes: themes ? themes.map((t) => t.id) : [],
          sessionType,
        })
      );

      history.push(`/sessions/${res.id}?idx=0`);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de créer la session'));
      console.warn(e);
    }
  };

  const campaignThemes = () => {
    return completions.themesCompletion.map((t) => ({
      ...themes.find((t2) => t2.id == t.id),
      completion: t.completion,
    }));
  };

  const downloadCertificate = async () => {
    try {
      await httpClient.req(
        ROUTES.FETCH_CERTIFICATE({
          campaignId: store.store.JWT.current_campaign_id,
          filename: `${t('Certificat Vigiware')}.pdf`,
        })
      );
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer le certificat'));
      console.warn(e);
    }
  };

  const changeCurrentCampaign = async (campaign: Campaign) => {
    try {
      await httpClient.req(
        ROUTES.UPDATE_ME({ user: { current_campaign_id: campaign.id } })
      );

      let actualJWT = store.store.JWT;

      actualJWT.current_campaign_id = campaign.id;
      store.update(Stored.JWT, actualJWT);
      location.reload();
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de mettre à jour la formation actuelle')
      );
      console.warn(e);
    }
  };

  const currentCampaign = () =>
    campaigns.find((c) => c.id == store.store.JWT.current_campaign_id);

  const displayThemePreview = (theme: Theme) => () => {
    setSelectedTheme(theme);
    setDisplayThemeModal(true);
  };

  const closeThemePreview = () => {
    setDisplayThemeModal(false);
    setSelectedTheme(undefined);
  };

  const BigScreen = ({ children }: any) => {
    return useMediaQuery({ minWidth: 1001 }) ? children : null
  }

  const SmallScreen = ({ children }: any) => {
    return useMediaQuery({ maxWidth: 1000 }) ? children : null
  }

  return (
    <div className="Home">
      {loading ? (
        null
      ) : (
        <div className='Background'>
          <Background primaryColor={myLicense.primaryColor} secondaryColor={myLicense.secondaryColor}></Background>
        </div>
      )}
      {/* <Background color1={"#007eaf"} color2={"white"}></Background> */}
      {displayThemeModal && selectedTheme && (
        <ThemePreview
          theme={selectedTheme}
          onDismiss={closeThemePreview}
          onSessionLaunch={() => launchSession([selectedTheme])}
        />
      )}
      {displayCampaignsModal && (
        <div

          className="Home__selectCampaignsModal"
          onClick={() => setDisplayCampaignsModal(false)}
        >
          <div className="Home__selectCampaignsModalBody">
            {campaigns.map((c) => (
              <div
                key={c.id}
                className={`Home__selectCampaignsModalEntry${currentCampaign() && c.id == currentCampaign().id
                  ? ' Home__selectCampaignsModalEntry--selected'
                  : ''
                  }`}
                onClick={() => changeCurrentCampaign(c)}
              >
                {c.title}
              </div>
            ))}
          </div>
        </div>
      )}
      {loading ? (
        <div className="Home__loader">
          <Loader type="ThreeDots" color="#f9ac30" height={100} width={100} />
        </div>
      ) : currentCampaign() ? (
        completions &&
        campaigns && (
          <div className="Home__body">
            <div
              className="Home__title"
              onClick={() => setDisplayCampaignsModal(!displayCampaignsModal)}
            >
              {currentCampaign()?.title}
              <i>keyboard_arrow_down</i>
            </div>
            <div className="Home__bodyContent">
              <div className="Home__sessions">
                <BigScreen>
                  <div className="Home__sessionsRandom">
                    <div className='Home__sessionsRandomBody'>
                      <div className="Home__sessionsRandomBodyImage mobileHidden">
                        <SessionImage />
                      </div>
                      <div className="Home__sessionsRandomBodyTitle">
                        {t('Lancer une session par intelligence artificielle')}
                      </div>
                      <div className='Home__sessionsRandomBodyFooter'>
                        <div className='Home__sessionsRandomBodyFooterButton' onClick={() => launchSession()}>
                          <div className='Home__sessionsRandomBodyFooterButtonTitle'>{t("se former")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </BigScreen>
                <SmallScreen>
                  <div className="Home__sessionsRandom" onClick={() => launchSession()}>
                    <div className="Home__sessionsRandomTitlemobile">
                      {t('Lancer une session par intelligence artificielle')}
                    </div>
                  </div>
                </SmallScreen>
                <div className="Home__sessionsCertification mobileHidden">
                  <div className="Home__sessionsCertificationImage">
                    <img src={CertificationImage} />
                  </div>
                  <div className="Home__sessionsCertificationDescription">
                    {t(
                      'Une fois le seuil de %percentage% atteint, vous aurez la possiblité de tenter la certification.',
                      {
                        percentage: campaigns.find(
                          (c) => c.id == store.store.JWT.current_campaign_id
                        ).certificationUnlockThreshold,
                      }
                    )}
                  </div>
                  <div className="Home__sessionsCertificationButtons">
                    <Button
                      onClick={() =>
                        launchSession([], 'certification_training')
                      }
                      title={t('Passer un test blanc')}
                    />
                    {registration.certificationValidatedOn ? (
                      <Button
                        className="Home__sessionsCertificationButtonsCertificationValidated"
                        title={t('Télécharger le certificat')}
                        onClick={downloadCertificate}
                        icon={CertificationValidatedImage}
                      />
                    ) : (
                      <Button
                        className="Home__sessionsCertificationButtonsCertification"
                        title={t('Passer la certification')}
                        onClick={() => launchSession([], 'certification')}
                        disabled={!registration.canPassCertification}
                        progress={
                          currentCampaign().certificationUnlockThreshold == 0
                            ? 100
                            : (completions.contentsCompletion * 100) /
                            currentCampaign().certificationUnlockThreshold
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="Home__sessionsCertification mobileVisible">
                <Button
                  onClick={() => launchSession([], 'certification_training')}
                  title={t('Passer un test blanc')}
                />
                {registration.certificationValidatedOn ? (
                  <Button
                    className="Home__sessionsCertificationButtonsCertificationValidated"
                    title={t('Télécharger le certificat')}
                    onClick={downloadCertificate}
                    icon={CertificationValidatedImage}
                  />
                ) : (
                  <Button
                    className="Home__sessionsCertificationButtonsCertification"
                    title={t('Passer la certification')}
                    onClick={() => launchSession([], 'certification')}
                    disabled={!registration.canPassCertification}
                    progress={
                      (completions.contentsCompletion * 100) /
                      currentCampaign().campaignTemplate
                        .certificationUnlockThreshold
                    }
                  />
                )}
              </div>
              <div className="Home__themes">
                <div className="Home__themesTitle mobileHidden">
                  {t('Approfondir un thème :')}
                </div>
                <div className="Home__themesTitle mobileVisible">
                  {t('ou choisissez une thématique ci-dessous :')}
                </div>
                <div className="Home__themesContent">
                  {campaignThemes().map((t) => (
                    <div className="ThemeCard__wrapper" key={t.id}>
                      <ThemeCard
                        progress={t.completion}
                        theme={t}
                        onClick={displayThemePreview(t)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="Home__body">
          <div
            className="Home__title"
            onClick={() => setDisplayCampaignsModal(!displayCampaignsModal)}
          >
            {t('Sélectionnez une formation pour commencer')}
            <i>keyboard_arrow_down</i>
          </div>
          <div className="Home__bodyContent">
            <div className="Home__placeholder">
              {campaigns.length > 0
                ? t(
                  'Veuillez sélectionner une formation dans le menu pour commencer.'
                )
                : t("Vous n'avez aucune formation en cours actuellement.")}
            </div>
          </div>
        </div>
      )
      }
    </div >
  );
};
