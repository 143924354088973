import React, { useState, FormEvent } from 'react'
import './Login.scss'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Stored, Message } from '../../services/store'
import Logo from '../../../assets/images/logo.png'
import LogoWhite from '../../../assets/images/logo_white.png'
import Background from '../../../assets/images/background.png'
import IconEmail from '../../../assets/images/icons/icon_email.svg'
import LoginInput from './LoginInput'
import { t } from '../../services/i18n'

export default () => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const storeJWT = (token: string) => {
    let [rawJwt, jwt] = httpClient.storeCreds(token)

    store.update(Stored.JWT, jwt)
    store.update(Stored.RawJWT, rawJwt)
  }

  const submitLogin = async (e: FormEvent) => {
    e.preventDefault()

    try {
      let resp = await httpClient.req(
        ROUTES.REQUEST_DEMO_ACCESS({
          email,
          firstName,
          lastName,
        })
      )
      let resp2 = await httpClient.req(
        ROUTES.AUTOLOGIN({
          token: resp.autologinToken,
        })
      )
      storeJWT(resp2.jwt)
      store.notify(
        Message.Notification,
        t('Bienvenue dans la démo de Vigiware !')
      )
    } catch (e) {
      store.notify(Message.Error, t('Une erreur est survenue'))
      console.warn(e)
    }
  }

  return (
    <div className="Login">
      <div className="Login__left">
        <div className="Login__leftWelcome">
          {t('Bienvenue sur la plateforme')}
        </div>
        <img src={Logo} className="Login__leftLogo" />
        <div className="Login__leftHint">
          {t(
            "Renseignez vos informations afin d'accéder à la version démo de la plateforme"
          )}
        </div>
        <form onSubmit={submitLogin}>
          <LoginInput
            icon={IconEmail}
            value={firstName}
            onChange={setFirstName}
            placeholder={t('Prénom')}
          />
          <LoginInput
            icon={IconEmail}
            value={lastName}
            onChange={setLastName}
            placeholder={t('Nom')}
          />
          <LoginInput
            icon={IconEmail}
            type="text"
            value={email}
            onChange={setEmail}
            placeholder={t('Email')}
          />
          <input type="submit" value={t('Accéder à la démo')} />
        </form>
      </div>
      <div className="Login__right">
        <img src={Background} className="Login__rightBackground" />
        <div className="Login__rightOverlay" />
        <img src={LogoWhite} className="Login__rightLogo" />
        <div className="Login__rightText">
          Votre plate-forme de sensibilisation ludique. Apprenez, progressez et
          certifiez-vous !
        </div>
      </div>
    </div>
  )
}
