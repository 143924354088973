export default {
  Accueil: {
    en: 'Home',
    fr: 'Accueil',
  },
  Thèmes: {
    en: 'Themes',
    fr: 'Thèmes',
  },
  'formations en cours': {
    en: 'Active campaigns',
    fr: 'formations en cours',
  },
  'voir classement': {
    en: 'see ranking',
    fr: 'voir classement',
  },
  '%points points': {
    en: '%points points',
    fr: '%points points',
  },
  'Impossible de mettre à jour la langue': {
    en: 'Could not update the language',
    fr: 'Impossible de mettre à jour la langue',
  },
  'Impossible de mettre à jour la formation actuelle': {
    en: 'Could not update the active campaign',
    fr: 'Impossible de mettre à jour la formation actuelle',
  },
}
