import React, { useState, useEffect } from 'react'
import './CertificationSessionFinishedModal.scss'
import Modal from '../../../components/Modal'
import { CircleProgress } from 'react-gradient-progress'
import { t } from '../../../services/i18n'
import Button from '../../../components/Button'
import { useHistory } from 'react-router-dom'
import Session from '../../../types/session'
import httpClient from '../../../services/httpClient'
import ROUTES from '../../../routes'
import store, { Message } from '../../../services/store'
import { CampaignRegistration } from '../../../types/campaign_registration'
import dayjs from 'dayjs'

export interface Props {
  session: Session
  dismiss?: () => void
}

const CertificationSessionFinishedModal = (props: Props) => {
  const history = useHistory()
  const [registration, setRegistration] =
    useState<CampaignRegistration>(undefined)
  const numberOfContents = props.session.sessionContentAssociations.length
  const numberOfRightAnswers = props.session.sessionContentAssociations.filter(
    (sca) => sca.status == 'succeeded'
  ).length

  useEffect(() => {
    fetchRegistration()
  }, [])

  const fetchRegistration = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_REGISTRATION({ campaignId: props.session.campaignId })
      )

      setRegistration(res)
      sendCertificationMail(res)
    } catch (e) {
      store.notify(
        Message.Error,
        t('Impossible de vérifier la validation de la certification')
      )
      console.warn(e)
    }
  }

  const downloadCertificate = async () => {
    try {
      await httpClient.req(
        ROUTES.FETCH_CERTIFICATE({
          campaignId: props.session.campaignId,
          filename: `${t('Certificat Vigiware')}.pdf`,
        })
      )
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer le certificat'))
      console.warn(e)
    }
  }

  const sendCertificationMail = async (res: any) => {
    if (props.session.sessionType == 'certification' && res.certificationValidatedOn) {
      try {
        await httpClient.req(
          ROUTES.SEND_CERTIFICATION_MAIL({
            campaignId: props.session.campaignId,
          })
        )
      } catch (e) {
        store.notify(
          Message.Error,
          t("L'envoi de la certification par mail a échoué.")
        )
      }
    }
  }

  return registration ? (
    <Modal
      className="CertificationSessionFinishedModal"
      dismiss={props.dismiss}
    >
      <div className="CertificationSessionFinishedModal__counter">
        <CircleProgress
          width={200}
          percentage={Math.floor(
            (numberOfRightAnswers / numberOfContents) * 100
          )}
          strokeWidth={15}
          primaryColor={['#1A7998', '#62a5e2']}
          secondaryColor="#e3e3e1"
        />
        <div className="CertificationSessionFinishedModal__counterValue">
          <div className="CertificationSessionFinishedModal__counterValueNumbers">
            <span>{numberOfRightAnswers}</span>/{numberOfContents}
          </div>
          <div className="CertificationSessionFinishedModal__counterValueLabel">
            {t('Bonnes')}
            <br />
            {t('réponses')}
          </div>
        </div>
      </div>
      <div className="CertificationSessionFinishedModal__body">
        <div className="CertificationSessionFinishedModal__bodyTitle">
          {props.session.sessionType == 'certification_training' &&
            (numberOfContents - numberOfRightAnswers <=
              props.session.campaign.campaignTemplate.certificationMaxErrors
              ? t('Bravo')
              : t('Dommage'))}
          {props.session.sessionType == 'certification' &&
            (registration.certificationValidatedOn ? t('Bravo') : t('Dommage'))}
        </div>
        <div className="CertificationSessionFinishedModal__bodyText">
          {props.session.sessionType == 'certification_training' &&
            (numberOfContents - numberOfRightAnswers <=
              props.session.campaign.campaignTemplate.certificationMaxErrors
              ? t('Vous avez validé votre entraînement à la certification')
              : t("Vous n'avez pas réussi pour cette fois"))}
          {props.session.sessionType == 'certification' &&
            (registration.certificationValidatedOn
              ? t('Vous avez validé votre certification')
              : t("Vous n'avez pas réussi pour cette fois"))}
        </div>
        {props.session.sessionType == 'certification' &&
          !registration.certificationValidatedOn &&
          (dayjs(registration.canPassCertificationAt).isAfter(dayjs()) ? (
            <div>
              {t('Vous pourrez repasser la certification le')}{' '}
              {dayjs(registration.canPassCertificationAt).format('LLL')}
            </div>
          ) : (
            <div>{t('Vous pouvez retenter la certification')}</div>
          ))}
        <div className="CertificationSessionFinishedModal__bodyButtons">
          {props.session.sessionType == 'certification' &&
            registration.certificationValidatedOn && (
              <Button
                title={t('Télécharger')}
                onClick={() => downloadCertificate()}
                secondaryColor
              />
            )}
          <Button
            title={t('Quitter')}
            dangerColor
            onClick={() => {
              history.push('/')
            }}
          />
        </div>
      </div>
    </Modal>
  ) : null
}

export default CertificationSessionFinishedModal
