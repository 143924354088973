import React, { useEffect, useState } from 'react'
import './Themes.scss'
import { useHistory } from 'react-router-dom'
import Theme from '../../types/theme'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import Button from '../../components/Button'
import ProgressBar from '../../components/ProgressBar'
import CampaignCompletion from '../../types/campaign_completion'

export default () => {
  const [themes, setThemes] = useState<Theme[]>([])
  const history = useHistory()
  const [completions, setCompletions] = useState<CampaignCompletion>(undefined)

  useEffect(() => {
    fetchThemes()
    fetchCompletions()
  }, [])

  const fetchThemes = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_THEMES({}))

      setThemes(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les thèmes'))
      console.warn(e)
    }
  }

  const launchSession = async (theme: Theme) => {
    try {
      const res = await httpClient.req(
        ROUTES.CREATE_SESSION({ themes: [theme.id] })
      )

      history.push(`/sessions/${res.id}?idx=0`)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de créer la session'))
      console.warn(e)
    }
  }

  const fetchCompletions = async () => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_CAMPAIGN_COMPLETION({
          id: store.store.JWT.current_campaign_id,
        })
      )

      setCompletions(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les thèmes'))
      console.warn(e)
    }
  }

  const campaignThemes = () => {
    return completions.themesCompletion.map((t) => ({
      ...themes.find((t2) => t2.id == t.id),
      completion: t.completion,
    }))
  }

  return (
    <div className="Themes">
      <div className="Themes__backgroundOverlay" />
      {completions && (
        <div className="Themes__body">
          {campaignThemes().map((theme) => (
            <div className="Themes__theme" key={theme.id}>
              <div className="Themes__themeProgress">
                <ProgressBar
                  progress={theme.completion}
                  colorStart="#1A7998"
                  colorEnd="#25A9D5"
                />
              </div>
              <div className="Themes__themeImage">
                <img src={theme.imageUrl} />
              </div>
              <div className="Themes__themeTitle">{theme.name}</div>
              <div className="Themes__themeDescription">
                {theme.description}
              </div>
              <Button
                className="Themes__themeButton"
                onClick={() => launchSession(theme)}
                title={t("C'est parti")}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
