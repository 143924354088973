import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import React, { Children, Fragment, useEffect, useState } from "react";
import { Bar, Pie, Radar } from "react-chartjs-2";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import PicturePlaceholder from "../../../assets/images/profile_picture_placeholder.png";
import Button from "../../components/Button";
import InputWithLabel from "../../components/InputWithLabel";
import ROUTES from "../../routes";
import httpClient from "../../services/httpClient";
import { Lng, setLng, t } from "../../services/i18n";
import store, { Message, Stored } from '../../services/store';
import Language from "../../types/language";
import User from "../../types/user";
import Campaign from "../../types/campaign";
import ProgressBarTarget from "../../components/ProgressBarTarget";
import "./MyAccount.scss";
import { CampaignRegistration } from '../../types/campaign_registration';
import CertificationValidatedImage from '../../../assets/images/certification_validated.png';
import Theme from '../../types/theme';
import CampaignCompletion from '../../types/campaign_completion';
import { IoStatsChart, IoLogOutOutline } from "react-icons/io5"
import { FaExchangeAlt } from "react-icons/fa"
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames/bind';
import { namedColor, transparentize } from './utils';
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Filler,
);

export default () => {
  const [user, setUser] = useState<User>(undefined);
  const [campaigns, setCampaigns] = useState<Campaign>(undefined);
  const [languages, setLanguages] = useState<Language[]>(undefined);
  const [loading, setLoading] = useState(true);
  const [isProfileTab, setIsProfileTab] = useState(true);
  const [isCampaignsTab, setIsCampaignsTab] = useState(false);
  const [isStatsTab, setIsStatsTab] = useState(false);
  const [userCampaigns, setUserCampaigns] = useState<any[]>([]);
  const history = useHistory();
  const [registration, setRegistration] =
    useState<CampaignRegistration>(undefined);
  const [completions, setCompletions] = useState<CampaignCompletion>(undefined);
  const [indexCampaign, setIndexCampaign] = useState<any>([]);
  const [statsLoading, setStatsLoading] = useState(undefined)
  const [campaignStats, setCampaignStats] = useState(undefined)
  const [statIsCompletion, setStatsIsCompletion] = useState(false)

  useEffect(() => {
    fetchUser();
    fetchLanguages();
    fetchCampaigns();
  }, []);

  useEffect(() => {
    setLoading(!(user && languages && campaigns));
  }, [user, languages, campaigns]);

  useEffect(() => {
    if (user) {
      fetchUserCampaigns();
    }
  }, [user]);

  const fetchUser = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_ME());
      setUser(res);
    } catch (e) {
      store.notify(Message.Error, "Impossible de récupérer le profil");
      console.warn(e);
    }
  };

  const updateLng = () => {
    let lng: Lng = Lng.en;
    let jwt = JSON.parse(localStorage.getItem("state"));

    localStorage.setItem("state", JSON.stringify(jwt));
    if (user.languageId == "8e35cec7-c6fb-480d-a958-d1224e63ac30") lng = Lng.fr;
    else if (user.languageId == "35e3c097-506f-4654-b0e5-d703d0750cde")
      lng = Lng.en;
    jwt.JWT.language = lng;
    jwt.Language = lng;
    store.store.JWT.language = lng;
    store.store.Language = lng;
    setLng(lng);
  };

  const updateUser = async () => {
    try {
      let res = await httpClient.req(ROUTES.UPDATE_ME({ user: user }));
      setUser(res);
      httpClient.updateLocale(
        languages.find((l) => l.id == res.languageId).code
      );
      updateLng();
      store.notify(Message.RefreshMenu);
      history.push("/");
    } catch (e) {
      store.notify(Message.Error, t("Impossible de mettre à jour le profil"));
      console.warn(e);
    }
  };

  const fetchUserCampaigns = async () => {
    try {
      let res = await httpClient.req(
        ROUTES.FETCH_USER_CAMPAIGNS({ userId: user.id })
      );
      setUserCampaigns(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t("Impossible de récupérer les campagnes de l'utilisateur")
      );
      console.warn(e);
    }
  };

  const fetchRegistration = async (campaignId: string) => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_REGISTRATION({
          campaignId
        })
      );

      setRegistration(res);
    } catch (e) {
      store.notify(
        Message.Error,
        t("Impossible de vérifier l'inscription à la formation")
      );
      console.warn(e);
    }
  };

  const fetchCampaigns = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGNS());


      setCampaigns(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les formations'));
      console.warn(e);
    }
  };

  const fetchCampaign = async (id: string) => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_CAMPAIGN({ id }));

      setCampaignStats(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les formations'));
      console.warn(e);
    }
  };

  const fetchCompletions = async (id: string) => {
    try {
      const res = await httpClient.req(
        ROUTES.FETCH_CAMPAIGN_COMPLETION({
          id
        })
      );
      setCompletions(res);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer la complétion'));
      console.warn(e);
    }
  };

  const fetchLanguages = async () => {
    try {
      let res = await httpClient.req(ROUTES.FETCH_LANGUAGES());
      JSON.parse(localStorage.getItem("state"));
      setLanguages(res);
    } catch (e) {
      store.notify(Message.Error, t("Impossible de récupérer les langues"));
      console.warn(e);
    }
  };

  const updateImage = async (file: File) => {
    try {
      let res = await httpClient.req(ROUTES.UPLOAD_FILE({ file }));

      setUser({ ...user, imageUrl: res.imageUrl });
    } catch (e) {
      store.notify(Message.Error, t("Impossible de mettre à jour l'image"));
      console.warn(e);
    }
  };

  const downloadCertificate = async (campaignId: string) => {
    try {
      await httpClient.req(
        ROUTES.FETCH_CERTIFICATE({
          campaignId,
          filename: `${t('Certificat Vigiware')}.pdf`,
        })
      );
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer le certificat'));
      console.warn(e);
    }
  };

  const launchSession = async (themes?: Theme[], sessionType?: string) => {
    setLoading(true);
    try {
      const res = await httpClient.req(
        ROUTES.CREATE_SESSION({
          themes: themes ? themes.map((t) => t.id) : [],
          sessionType,
        })
      );

      history.push(`/sessions/${res.id}?idx=0`);
    } catch (e) {
      store.notify(Message.Error, t('Impossible de créer la session'));
      console.warn(e);
    }
  };

  const BigScreen = ({ children }: any) => {
    return useMediaQuery({ minWidth: 596 }) ? children : null
  }

  const SmallScreen = ({ children }: any) => {
    return useMediaQuery({ maxWidth: 595 }) ? children : null
  }

  const BigStatScreen = ({ children }: any) => {
    return useMediaQuery({ minWidth: 601 }) ? children : null
  }


  const SmallStatScreen = ({ children }: any) => {
    return useMediaQuery({ maxWidth: 600 }) ? children : null
  }

  const resetLoading = () => {
    return setStatsLoading(false)
  }

  var profileTabClass = classNames({
    "MyAccount__contentTabsSelected": isProfileTab,
    "MyAccount__contentTabsProfile": !isProfileTab
  });

  var campaignTabClass = classNames({
    "MyAccount__contentTabsSelected": isCampaignsTab,
    "MyAccount__contentTabsProfile": !isCampaignsTab
  });

  var statsTabClass = classNames({
    "MyAccount__contentTabsSelected": isStatsTab,
    "MyAccount__contentTabsProfile": !isStatsTab,
  })

  return (
    <div className="MyAccount">
      {loading ? (
        <Loader type="ThreeDots" color="#62a5e2" />
      ) : (
        <Fragment>
          <BigScreen>
            <div className="MyAccount__header">{t("Profil")}</div>
          </BigScreen>
          <div className="MyAccount__window">
            <div className="MyAccount__contentTabs">
              <div id="profile-tab" className={profileTabClass} onClick={() => {
                setIsProfileTab(true)
                setIsCampaignsTab(false)
                setIsStatsTab(false)
              }}>
                PROFIL
              </div>
              {isStatsTab ? (<div id="stats-tab" className={statsTabClass}>
                STATS
              </div>
              ) : (<div id="stats-tab" className={campaignTabClass} onClick={() => {
                setIsProfileTab(false)
                setIsCampaignsTab(true)
                setIsStatsTab(false)
              }}>
                {t("FORMATIONS")}
              </div>)}
              <SmallScreen>
                <div className="MyAccount__contentDeconnection">
                  <div className="MyAccount__contentDeconnectionCadre" onClick={() => store.notify(Message.NeedAuth)}>
                    <IoLogOutOutline style={{ fontSize: "5vw", color: "red", marginLeft: "3px" }} />
                  </div>
                </div>
              </SmallScreen>
              <BigScreen>
                <div className="MyAccount__contentDeconnection" style={{ marginBottom: "0.5em" }}>
                  <Button
                    title={t("Déconnexion")}
                    dangerColor
                    onClick={() => store.notify(Message.NeedAuth)}
                  />
                </div>
              </BigScreen>
            </div >
            {isProfileTab && (
              <div className="MyAccount__content">
                <div className="MyAccount__contentBody">
                  <div className="MyAccount__contentBodyLeft">
                    <div className="MyAccount__contentBodyLeftPicture">
                      <label>
                        <div className="MyAccount__contentBodyLeftPictureHover">
                          <i>cloud_upload</i>
                        </div>
                        <img
                          src={user.imageUrl ? user.imageUrl : PicturePlaceholder}
                        />
                        <input
                          type="file"
                          onChange={(e) => updateImage(e.target.files[0])}
                          accept="image/jpg, image/jpeg, image/png"
                        />
                      </label>
                    </div>
                    <div className="MyAccount__contentBodyLeftName">
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                  <div className="MyAccount__contentBodyRight">
                    <InputWithLabel
                      value={
                        languages.find((l) => l.id == user.languageId)?.id || "fr"
                      }
                      label={t("Langue")}
                      onChange={(v) => setUser({ ...user, languageId: v })}
                      selectOptions={languages}
                      selectLabelKey={"name"}
                      selectValueKey={"id"}
                    />
                    <InputWithLabel
                      value={user.currentPassword}
                      label={t(
                        "Mot de passe actuel (nécessaire pour changer de mot de passe)"
                      )}
                      type="password"
                      onChange={(v) => setUser({ ...user, currentPassword: v })}
                    />
                    <InputWithLabel
                      value={user.password}
                      label={t("Nouveau mot de passe")}
                      type="password"
                      onChange={(v) => setUser({ ...user, password: v })}
                    />
                    <InputWithLabel
                      value={user.passwordConfirmation}
                      label={t("Confirmation nouveau mot de passe")}
                      type="password"
                      onChange={(v) =>
                        setUser({ ...user, passwordConfirmation: v })
                      }
                    />
                  </div>
                </div>
                <div className="MyAccount__contentFooter">
                  <div style={{ marginRight: "1em" }}>
                    <Button
                      title={t("Annuler")}
                      onClick={() => history.push("/")}
                    ></Button>
                  </div>
                  <Button
                    title={t("Enregistrer")}
                    secondaryColor
                    onClick={updateUser}
                  ></Button>
                </div>
              </div>
            )}

            {isCampaignsTab && (
              <div className="MyAccountStats">
                <table className="MyAccountStats__table">
                  <thead>
                    <tr>
                      <th>{t("Campagne")}</th>
                      <th>{t("Dates")}</th>
                      <th>{t("Status")}</th>
                      <th>{t("Score")}</th>
                      <th>{t("Stats")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userCampaigns.map((campaign) => {
                      return (
                        <tr key={campaign.id} id="row">
                          <td>{campaign.title}</td>
                          <td>
                            {campaign.startDate
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("/")}{" "}
                            au{" "}
                            {campaign.endDate
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("/")}
                          </td>
                          <td>
                            {!campaign.certificationValidated &&
                              new Date(campaign.startDate) <= new Date() &&
                              new Date() <= new Date(campaign.endDate)
                              ? t("En cours")
                              : campaign.certificationValidated
                                ? t("Certifié")
                                : t("Non certifié")}
                          </td>
                          <td>{campaign.points}</td>
                          <td>
                            <button className="statsTabClass"
                              onClick={() => {
                                setStatsLoading(false)
                                setCampaignStats(false)
                                fetchCampaign(campaign.id)
                                fetchCompletions(campaign.id)
                                fetchRegistration(campaign.id)
                                setIsProfileTab(false)
                                setIsCampaignsTab(false)
                                setIsStatsTab(true)
                                setIndexCampaign(campaign)
                                setStatsLoading(true)
                              }}
                            >
                              <IoStatsChart />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {
              (statsLoading && (!(campaignStats) || !(completions))) && (
                <div className="MyAccountLoading">
                  <Loader type="ThreeDots" color="#62a5e2" />
                </div>
              )
            }

            {
              (isStatsTab && campaignStats && completions) && (
                <div className="MyAccountStats">
                  <div className="MyAccountStatsHeader">
                    <div className="MyAccountStatsHeaderTitlecampaign">
                      {campaignStats.title}
                    </div>
                    <div className="MyAccountStatsHeaderDatecampaign">
                      <div>
                        {indexCampaign.startDate
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")}{" "}
                        -{" "}
                        {indexCampaign.endDate
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")}
                      </div>
                    </div>
                  </div>
                  <div className="MyAccountStatsCharts">
                    <div className="MyAccountStatsChartsLogobar">
                    </div>
                    <div className='MyAccountStatsCharts__header'>
                      <BigStatScreen>
                        <div className='MyAccountStatsCharts__header__progress'>
                          <div className='MyAccountStatsCharts__header__progressTitle'>{t("Ma complétion")}</div>
                          <ProgressBarTarget
                            progress={Math.round(completions.contentsCompletion)}
                            colorStart="#f8a930"
                            colorEnd="#fabe37"
                            target={campaignStats.certificationUnlockThreshold}
                            average={35}
                          />
                        </div>
                        {registration.certificationValidatedOn ? (
                          <Button
                            className="MyAccountStatsCharts__header__buttonValidated"
                            title={t('Télécharger le certificat')}
                            onClick={() => {
                              downloadCertificate(campaignStats.id)
                            }}
                            icon={CertificationValidatedImage}
                          />
                        ) : (
                          <Button
                            className="MyAccountStatsCharts__header__button"
                            title={t('Télécharger le certificat')}
                            onClick={() => launchSession([], 'certification')}
                            disabled={true}
                          // progress={
                          //   (completions.contentsCompletion * 100) /
                          //   currentCampaign().campaignTemplate
                          //     .certificationUnlockThreshold
                          // }
                          />
                        )}
                      </BigStatScreen>
                      <SmallStatScreen>
                        {registration.certificationValidatedOn ? (
                          <Button
                            className="MyAccountStatsCharts__header__buttonValidated"
                            title={t('Télécharger le certificat')}
                            onClick={() => {
                              downloadCertificate(campaignStats.id)
                            }}
                            icon={CertificationValidatedImage}
                          />
                        ) : (
                          <Button
                            className="MyAccountStatsCharts__header__buttonNotvalidated"
                            title={t('Télécharger le certificat')}
                            onClick={() => launchSession([], 'certification')}
                            disabled={true}
                          // progress={
                          //   (completions.contentsCompletion * 100) /
                          //   currentCampaign().campaignTemplate
                          //     .certificationUnlockThreshold
                          // }
                          />
                        )}
                        <div className='MyAccountStatsCharts__header__progress'>
                          <div className='MyAccountStatsCharts__header__progressTitle'>{t("Ma complétion")}</div>
                          <ProgressBarTarget
                            progress={Math.round(completions.contentsCompletion)}
                            colorStart="#f8a930"
                            colorEnd="#fabe37"
                            target={campaignStats.certificationUnlockThreshold}
                            average={35}
                          />
                        </div>
                      </SmallStatScreen>
                    </div>
                    <div className="MyAccountStatsCharts__card">
                      <div className="MyAccountStatsCharts__cardSwitch">
                        <div className="MyAccountStatsCharts__cardSwitchName">{t("Performance")}</div>

                        <input type="checkbox" id="toggle" className="offscreen" />
                        <label htmlFor="toggle" className="switch" onClick={() => {
                          {
                            statIsCompletion ? (
                              setStatsIsCompletion(false)
                            ) : (
                              setStatsIsCompletion(true)
                            )
                          }
                        }}></label>
                        <div className="MyAccountStatsCharts__cardSwitchName">{t("Complétion")}</div>
                      </div>
                      <div className="MyAccountStatsCharts__card__details">
                        {!(statIsCompletion) ? (
                          <div className="MyAccountStatsCharts__card__detailsTitle">
                            {t("Performance par thème")}
                          </div>
                        ) : (
                          <div className="MyAccountStatsCharts__card__detailsTitle">
                            {t("Complétion par thème")}
                          </div>
                        )}
                        <div className="MyAccountStatsCharts__card__details__body">
                          {!(statIsCompletion) ? (
                            <Radar
                              data={{
                                labels: campaignStats.stats.themePerformance.map(
                                  (tc: { name: any; }) => tc.name
                                ),
                                datasets: [
                                  {
                                    label: t("Ma performance"),
                                    data: campaignStats.stats.themePerformance.map(
                                      (tc: { performance: any; }) => tc.performance * 100
                                    ),
                                    fill: false,
                                    backgroundColor: namedColor(0),
                                    borderColor: namedColor(0),
                                    pointBackgroundColor: namedColor(0),
                                    pointBorderColor: namedColor(0),
                                    pointHoverBackgroundColor: namedColor(0),
                                    pointHoverBorderColor: namedColor(0),
                                  },
                                  {
                                    label: t("Performance moyenne"),
                                    fill: true,
                                    data: campaignStats.stats.themeCompletion.map(
                                      (tc: { completion: any; }) => tc.completion
                                    ),
                                    backgroundColor: transparentize(namedColor(4), 0.6),
                                    borderColor: transparentize(namedColor(4), 0.6),
                                    pointBackgroundColor: transparentize(namedColor(4), 0.6),
                                    pointBorderColor: transparentize(namedColor(4), 0.6),
                                    pointHoverBackgroundColor: transparentize(namedColor(4), 0.6),
                                    pointHoverBorderColor: transparentize(namedColor(4), 0.6),
                                  }
                                ],
                              }}
                              options={{
                                scales: {
                                  r: {
                                    suggestedMin: 0,
                                    suggestedMax: 100
                                  }
                                },
                                maintainAspectRatio: false,
                              }}
                            />
                          ) : (
                            <Radar
                              data={{
                                labels: campaignStats.stats.themeCompletion.map(
                                  (tc: { name: any; }) => tc.name
                                ),
                                datasets: [
                                  {
                                    label: t("Ma completion"),
                                    data: campaignStats.stats.themePerformance.map(
                                      (tc: { performance: any; }) => tc.performance * 100
                                    ),
                                    fill: false,
                                    backgroundColor: "#7983ff",
                                    borderColor: "#7983ff",
                                    pointBackgroundColor: "#7983ff",
                                    pointBorderColor: "#7983ff",
                                    pointHoverBackgroundColor: "#7983ff",
                                    pointHoverBorderColor: "#7983ff",
                                  },
                                  {
                                    label: t("Complétion moyenne"),
                                    fill: true,
                                    data: campaignStats.stats.themeCompletion.map(
                                      (tc: { completion: any; }) => tc.completion
                                    ),
                                    backgroundColor: transparentize(namedColor(4), 0.6),
                                    borderColor: transparentize(namedColor(4), 0.6),
                                    pointBackgroundColor: transparentize(namedColor(4), 0.6),
                                    pointBorderColor: transparentize(namedColor(4), 0.6),
                                    pointHoverBackgroundColor: transparentize(namedColor(4), 0.6),
                                    pointHoverBorderColor: transparentize(namedColor(4), 0.6),
                                  }
                                ],
                              }}
                              options={{
                                scales: {
                                  r: {
                                    suggestedMin: 0,
                                    suggestedMax: 100
                                  }
                                },
                                maintainAspectRatio: false,
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="MyAccountStatsFooter">
                    <Button
                      title={t("Retour")}
                      onClick={() => {
                        setIsCampaignsTab(true);
                        setIsStatsTab(false);
                      }}
                    ></Button>
                  </div>
                </div>
              )
            }
          </div >
        </Fragment >
      )
      }
    </div >
  );
};
