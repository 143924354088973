import colorLib from '@kurkle/color';

export const transparentize = (value: any, opacity: number) => {
    var alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
}

export const CHART_COLORS = {
    red: 'rgb(240, 106, 47)',
    orange: 'rgb(242, 158, 52)',
    blue: 'rgb(69, 172, 231)',
    cyan: 'rgb(61, 227, 253)',
    green: 'rgb(180, 204, 42)',
};

const NAMED_COLORS = [
    CHART_COLORS.red,
    CHART_COLORS.orange,
    CHART_COLORS.blue,
    CHART_COLORS.cyan,
    CHART_COLORS.green,
];

export const namedColor = (index: number) => {
    return NAMED_COLORS[index % NAMED_COLORS.length];
}