import React from 'react'
import './ThemeCard.scss'
import Theme from '../../types/theme'
import { CircleProgress } from 'react-gradient-progress'
import { t } from '../../services/i18n'

interface Props {
  progress: number
  theme?: Theme
  onClick?: () => void
  title?: string
  hideProgressBar?: boolean
}

export default (props: Props) => {
  return (
    <div className="ThemeCard" onClick={props.onClick}>
      <div className="ThemeCard__progress">
        <div className="ThemeCard__progressBar">
          {!props.hideProgressBar && (
            <CircleProgress
              className="Home__certificationProgress"
              width={75}
              percentage={Math.floor(props.progress == 0 ? 1 : props.progress)}
              strokeWidth={4}
              primaryColor={['#f9ac30', '#f9ac30']}
            />
          )}
        </div>
        <div className="ThemeCard__image">
          {props.theme ? (
            <img src={props.theme.imageUrl} />
          ) : (
            <i className="ThemeCard__imageIcon">shuffle</i>
          )}
        </div>
      </div>
      <div className="ThemeCard__name">
        {props.title
          ? props.title
          : props.theme
          ? props.theme.name
          : t('Session aléatoire')}
      </div>
      <div className="ThemeCard__expand mobileVisible">
        <i>keyboard_arrow_right</i>
      </div>
    </div>
  )
}
