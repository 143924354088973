export default {
    'Profil': {
        en: 'Profile',
        fr: 'Profil'
    },
    'Langue': {
        en: 'Language',
        fr: 'Langue'
    },
    'Mot de passe actuel (nécessaire pour changer de mot de passe)': {
        en: 'Current password (required to change password)',
        fr: 'Mot de passe actuel (nécessaire pour changer de mot de passe)'
    },
    'Nouveau mot de passe': {
        en: 'New password',
        fr: 'Nouveau mot de passe'
    },
    'Confirmation nouveau mot de passe': {
        en: 'Confirm new password',
        fr: 'Confirmation nouveau mot de passe'
    },
    'Déconnexion': {
        en: 'Disconnect',
        fr: 'Déconnexion'
    },
    'Annuler': {
        en: 'Cancel',
        fr: 'Annuler'
    },
    'Enregistrer': {
        en: 'Save',
        fr: 'Enregistrer'
    },
    "FORMATIONS": {
        en: "TRAININGS",
        fr: "FORMATIONS"
    },
    "En cours": {
        en: "In progress",
        fr: "En cours"
    },
    "Certifié": {
        en: "Certified",
        fr: "Certifié"
    },
    "Non certifié": {
        en: "No certified",
        fr: "Non certifié"
    },
    "Campagne": {
        en: "Campaign",
        fr: "Campagne"
    },
    "Dates": {
        en: "Dates",
        fr: "Dates"
    },
    "Ma complétion": {
        en: "My completion",
        fr: "Ma complétion"
    },
    "Performance par thème": {
        en: "Performance by theme",
        fr: "Performance par thème"
    },
    "Complétion": {
        en: "Completion",
        fr: "Complétion"
    },
    "Ma performance": {
        en: "My performance",
        fr: "Ma performance"
    },
    "Performance moyenne": {
        en: "Average performance",
        fr: "Performance moyenne"
    },
    "Performance": {
        en: "Performance",
        fr: "Performance"
    },
    "La complétion requise pour obtenir la certification est de ": {
        en: "The required completion for certification is ",
        fr: "La complétion requise pour obtenir la certification est de "
    },
    "La complétion moyenne est de ": {
        en: "The average completion is ",
        fr: "La complétion moyenne est de "
    }
}