import React from 'react'
import './Correction.scss'
import Content from '../../../types/content'
import { UUID } from '../../../types/common'
import { t } from '../../../services/i18n'
import CrossWhite from '../../../../assets/images/icons/cross_white.png'
import CheckWhite from '../../../../assets/images/icons/check_white.png'
import CheckGreen from '../../../../assets/images/icons/check_green.png'
import SessionContent from '../../../types/session_content'

interface Props {
  content: Content
  sessionContent: SessionContent
  succeeded: boolean
  selectedAnswers: UUID[]
}

export default (props: Props) => {
  const answerForId = (id: UUID) => {
    return props.content.answers.find((a) => a.id == id)
  }

  const getExtension = (filename: string) => {
    var parts = filename.split('.')
    return parts[parts.length - 1]
  }

  const isVideo = (filename: string) => {
    const ext = getExtension(filename)

    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'mkv':
        return true
    }
    return false
  }

  return (
    <div className="Correction">
      <div
        className={`Correction__header${props.succeeded ? '' : ' Correction__header--invalid'
          }`}
      >
        {props.content.contentType == 'video'
          ? t('Explication')
          : props.succeeded
            ? t('Bonne réponse')
            : t('Mauvaise réponse')}
      </div>
      <div
        className={`Correction__body ${props.content.contentType == 'sortable_question'
          ? 'Correction__body--sortable'
          : ''
          }`}
      >
        {!props.succeeded && (
          <div className="Correction__bodyAnswered">
            <div className="Correction__bodyAnsweredLabel">
              {t('Vous avez répondu :')}
            </div>
            <div className="Correction__bodyAnsweredAnswers">
              {props.selectedAnswers
                .map((a) => answerForId(a))
                .filter((a) => a)
                .map((a, i) => (
                  <div
                    key={a.id}
                    className={`Correction__bodyAnswer${(
                      props.content.contentType == 'sortable_question'
                        ? i == a.index
                        : a.rightAnswer
                    )
                      ? ' Correction__bodyAnswer--valid'
                      : ' Correction__bodyAnswer--invalid'
                      }`}
                  >
                    <img
                      className="Correction__bodyAnswerIcon"
                      src={
                        props.content.contentType == 'sortable_question'
                          ? i == a.index
                          : a.rightAnswer
                            ? CheckWhite
                            : CrossWhite
                      }
                    />
                    <div className="Correction__bodyAnswerValue">{a.value}</div>
                  </div>
                ))}
            </div>
          </div>
        )}
        <div className="Correction__bodyQuestion">{props.content.title}</div>
        <div className="Correction__bodyRightAnswers">
          {props.content.answers
            .filter(
              (a) =>
                props.content.contentType == 'sortable_question' ||
                a.rightAnswer
            )
            .sort((a, b) => (a.index > b.index ? 1 : -1))
            .map((a) => (
              <div
                key={a.id}
                className={`Correction__bodyAnswer${props.succeeded ? ' Correction__bodyAnswer--valid' : ''
                  }`}
              >
                <img
                  className="Correction__bodyAnswerIcon"
                  src={props.succeeded ? CheckWhite : CheckGreen}
                />
                <div className="Correction__bodyAnswerValue">{a.value}</div>
              </div>
            ))}
        </div>
        <div className="Correction__bodySeparator"></div>
        {props.content.correctionImageUrl &&
          (isVideo(props.content.correctionImageUrl) ? (
            <video
              src={props.content.correctionImageUrl}
              controls
              className="Correction__bodyImage"
            />
          ) : (
            <img
              src={props.content.correctionImageUrl}
              className="Correction__bodyImage"
            />
          ))}
        <div
          className="Correction__bodyText"
          dangerouslySetInnerHTML={{ __html: props.content.correction }}
        />
        {props.content.goodPractices && (
          <div className="Correction__bodyGoodPractices">
            {t(
              "À retenir: %goodpractice.", {
              goodpractice: props.content.goodPractices
                .map((gp) => gp.name)
                .join(', ')
            })}
          </div>
        )}
        {props.succeeded && (
          <div className="Correction__bodyPoints">
            <div className="Correction__bodyPointsLabel">
              {t('Vous avez gagné')}
            </div>
            <div className="Correction__bodyPointsValue">
              <span>{props.sessionContent.pointsEarned}</span>&nbsp;
              {t('points')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
