import login from './login'
import home from './home'
import menu from './menu'
import common from './common'
import topbar from './topbar'
import profile from './profile'
import notification from './notification'
import firstConnection from './firstConnection'

interface Translations {
  [key: string]: {
    fr: string
    en: string
  }
}

const tr: Translations = {
  ...login,
  ...home,
  ...menu,
  ...common,
  ...topbar,
  ...profile,
  ...notification,
  ...firstConnection,
}

export default tr
