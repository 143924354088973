export default {
  ou: {
    en: 'or',
    fr: 'ou',
  },
  et: {
    en: 'and',
    fr: 'et',
  },
  'Impossible de récupérer le profil': {
    en: 'Could not retrieve your profile',
    fr: 'Impossible de récupérer le profil',
  },
  'Impossible de récupérer les formations': {
    en: 'Could not retrieve the campaigns',
    fr: 'Impossible de récupérer les formations',
  },
  'Impossible de récupérer les langues': {
    en: 'Could not retrieve the languages',
    fr: 'Impossible de récupérer les langues',
  },
  'Impossible de récupérer les thèmes': {
    en: 'Could not retrieve the themes',
    fr: 'Impossible de récupérer les thèmes',
  },
  Précédent: {
    en: 'Go back',
    fr: 'Précédent',
  },
  Quitter: {
    en: 'Quit',
    fr: 'Quitter',
  },
  Didactitiel: {
    fr: 'Didactitiel',
    en: "Tutorial"
  }
}
