import React from 'react'
import ReactDOM from 'react-dom'
import './default.scss'
import Layout from './app/Layout'
import 'toastify-js/src/toastify.css'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

const rootElement = document.getElementById('app')

ReactDOM.render(<Layout></Layout>, rootElement)

