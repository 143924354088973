export default {
    'points': {
        en: 'points',
        fr: 'points'
    },
    'sur %number': {
        en: 'out of %number',
        fr: 'sur %number'
    },
    'complétion': {
        en: 'completion',
        fr: 'complétion'
    },
    'Points obtenus': {
        en: 'Obtained points',
        fr: 'Points obtenus'
    },
    'Classement sur la campagne': {
        en: 'Ranking on the campaign',
        fr: 'Classement sur la campagne'
    },
    'Pourcentage de complétion de la formation actuelle': {
        en: 'Percentage of completion of current training',
        fr: 'Pourcentage de complétion de la formation actuelle'
    },
    'Profil': {
        en: 'Profile',
        fr: 'Profil'
    }
}