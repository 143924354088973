import React from "react";

import "./Background.scss";

interface Props {
  primaryColor: any;
  secondaryColor: any;
}

const Background = ({ primaryColor, secondaryColor }: Props) => {
  return (
    <svg
      width="100%"
      height="702"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient id="Dégradé_sans_nom_2" x1="0" y1="0.5" x2="1" y2="0.5">
          <stop offset="1" stop-color="#29a7de" />
          <stop offset="1" stop-color="#2e318e" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_177"
          x1="0.43078"
          y1="0.50166"
          x2="1.43775"
          y2="0.511"
        >
          <stop offset="0" stop-color="#e5e5e5" stop-opacity="0.48" />
          <stop offset="1" stop-color="#e5e5e5" stop-opacity="0.48" />
        </linearGradient>
        <linearGradient
          id="Dégradé_sans_nom_177-2"
          x1="1568.4"
          y1="24.49"
          x2="1606.7"
          y2="24.49"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-3"
          x1="1379.36"
          y1="26.89"
          x2="1428.6"
          y2="26.89"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-4"
          x1="1190.31"
          y1="29.3"
          x2="1250.5"
          y2="29.3"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-5"
          x1="1001.27"
          y1="31.71"
          x2="1072.41"
          y2="31.71"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-6"
          x1="812.23"
          y1="34.12"
          x2="894.31"
          y2="34.12"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-7"
          x1="623.19"
          y1="36.52"
          x2="716.21"
          y2="36.52"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-8"
          x1="434.14"
          y1="38.93"
          x2="538.11"
          y2="38.93"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-9"
          x1="245.1"
          y1="41.34"
          x2="360.01"
          y2="41.34"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-10"
          x1="56.06"
          y1="43.75"
          x2="181.91"
          y2="43.75"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-11"
          x1="1759.94"
          y1="122.07"
          x2="1784.92"
          y2="122.07"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-12"
          x1="1571.37"
          y1="124.48"
          x2="1606.35"
          y2="124.48"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-13"
          x1="1382.81"
          y1="126.89"
          x2="1427.77"
          y2="126.89"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-14"
          x1="1194.24"
          y1="129.29"
          x2="1249.2"
          y2="129.29"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-15"
          x1="1005.67"
          y1="131.7"
          x2="1070.62"
          y2="131.7"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-16"
          x1="817.11"
          y1="134.11"
          x2="892.05"
          y2="134.11"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-17"
          x1="628.54"
          y1="136.52"
          x2="713.48"
          y2="136.52"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-18"
          x1="439.98"
          y1="138.92"
          x2="534.9"
          y2="138.92"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-19"
          x1="251.41"
          y1="141.33"
          x2="356.33"
          y2="141.33"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-20"
          x1="62.84"
          y1="143.74"
          x2="177.75"
          y2="143.74"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-21"
          x1="1762.44"
          y1="222.06"
          x2="1785.04"
          y2="222.06"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-22"
          x1="1574.35"
          y1="224.47"
          x2="1605.99"
          y2="224.47"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-23"
          x1="1386.26"
          y1="226.88"
          x2="1426.94"
          y2="226.88"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-24"
          x1="1198.17"
          y1="229.29"
          x2="1247.89"
          y2="229.29"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-25"
          x1="1010.08"
          y1="231.69"
          x2="1068.84"
          y2="231.69"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-26"
          x1="821.99"
          y1="234.1"
          x2="889.79"
          y2="234.1"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-27"
          x1="633.9"
          y1="236.51"
          x2="710.74"
          y2="236.51"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-28"
          x1="445.81"
          y1="238.92"
          x2="531.69"
          y2="238.92"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-29"
          x1="257.72"
          y1="241.32"
          x2="352.64"
          y2="241.32"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-30"
          x1="69.63"
          y1="243.73"
          x2="173.59"
          y2="243.73"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-31"
          x1="1764.94"
          y1="322.05"
          x2="1785.16"
          y2="322.05"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-32"
          x1="1577.33"
          y1="324.46"
          x2="1605.64"
          y2="324.46"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-33"
          x1="1389.71"
          y1="326.87"
          x2="1426.11"
          y2="326.87"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-34"
          x1="1202.1"
          y1="329.28"
          x2="1246.59"
          y2="329.28"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-35"
          x1="1014.48"
          y1="331.68"
          x2="1067.06"
          y2="331.68"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-36"
          x1="826.87"
          y1="334.09"
          x2="887.53"
          y2="334.09"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-37"
          x1="639.25"
          y1="336.5"
          x2="708.01"
          y2="336.5"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-38"
          x1="451.64"
          y1="338.91"
          x2="528.48"
          y2="338.91"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-39"
          x1="264.02"
          y1="341.31"
          x2="348.96"
          y2="341.31"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-40"
          x1="76.41"
          y1="343.72"
          x2="169.43"
          y2="343.72"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-41"
          x1="1767.44"
          y1="422.05"
          x2="1785.29"
          y2="422.05"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-42"
          x1="1580.3"
          y1="424.45"
          x2="1605.29"
          y2="424.45"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-43"
          x1="1393.17"
          y1="426.86"
          x2="1425.28"
          y2="426.86"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-44"
          x1="1206.03"
          y1="429.27"
          x2="1245.28"
          y2="429.27"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-45"
          x1="1018.89"
          y1="431.68"
          x2="1065.28"
          y2="431.68"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-46"
          x1="831.75"
          y1="434.08"
          x2="885.28"
          y2="434.08"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-47"
          x1="644.61"
          y1="436.49"
          x2="705.28"
          y2="436.49"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-48"
          x1="457.47"
          y1="438.9"
          x2="525.27"
          y2="438.9"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-49"
          x1="270.33"
          y1="441.31"
          x2="345.27"
          y2="441.31"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-50"
          x1="83.19"
          y1="443.71"
          x2="165.27"
          y2="443.71"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-51"
          x1="1769.94"
          y1="522.04"
          x2="1785.41"
          y2="522.04"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-52"
          x1="1583.28"
          y1="524.44"
          x2="1604.93"
          y2="524.44"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-53"
          x1="1396.62"
          y1="526.85"
          x2="1424.45"
          y2="526.85"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-54"
          x1="1209.96"
          y1="529.26"
          x2="1243.98"
          y2="529.26"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-55"
          x1="1023.29"
          y1="531.67"
          x2="1063.5"
          y2="531.67"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-56"
          x1="836.63"
          y1="534.07"
          x2="883.02"
          y2="534.07"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-57"
          x1="649.96"
          y1="536.48"
          x2="702.54"
          y2="536.48"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-58"
          x1="463.3"
          y1="538.89"
          x2="522.07"
          y2="538.89"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-59"
          x1="276.64"
          y1="541.3"
          x2="341.59"
          y2="541.3"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-60"
          x1="89.98"
          y1="543.7"
          x2="161.11"
          y2="543.7"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-61"
          x1="1772.45"
          y1="622.03"
          x2="1785.53"
          y2="622.03"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-62"
          x1="1586.26"
          y1="624.44"
          x2="1604.58"
          y2="624.44"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-63"
          x1="1400.07"
          y1="626.84"
          x2="1423.62"
          y2="626.84"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-64"
          x1="1213.88"
          y1="629.25"
          x2="1242.67"
          y2="629.25"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-65"
          x1="1027.7"
          y1="631.66"
          x2="1061.72"
          y2="631.66"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-66"
          x1="841.51"
          y1="634.07"
          x2="880.76"
          y2="634.07"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-67"
          x1="655.32"
          y1="636.47"
          x2="699.81"
          y2="636.47"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-68"
          x1="469.13"
          y1="638.88"
          x2="518.86"
          y2="638.88"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-69"
          x1="282.95"
          y1="641.29"
          x2="337.9"
          y2="641.29"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-70"
          x1="96.76"
          y1="643.7"
          x2="156.95"
          y2="643.7"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-71"
          x1="1774.95"
          y1="722.02"
          x2="1785.65"
          y2="722.02"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-72"
          x1="1589.23"
          y1="724.43"
          x2="1604.22"
          y2="724.43"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-73"
          x1="1403.52"
          y1="726.83"
          x2="1422.79"
          y2="726.83"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-74"
          x1="1217.81"
          y1="729.24"
          x2="1241.36"
          y2="729.24"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-75"
          x1="1032.1"
          y1="731.65"
          x2="1059.94"
          y2="731.65"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-76"
          x1="846.39"
          y1="734.06"
          x2="878.51"
          y2="734.06"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-77"
          x1="660.68"
          y1="736.46"
          x2="697.08"
          y2="736.46"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-78"
          x1="474.97"
          y1="738.87"
          x2="515.65"
          y2="738.87"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-79"
          x1="289.25"
          y1="741.28"
          x2="334.22"
          y2="741.28"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-80"
          x1="103.54"
          y1="743.69"
          x2="152.79"
          y2="743.69"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-81"
          x1="1777.45"
          y1="822.01"
          x2="1785.77"
          y2="822.01"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-82"
          x1="1592.21"
          y1="824.42"
          x2="1603.87"
          y2="824.42"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-83"
          x1="1406.98"
          y1="826.83"
          x2="1421.96"
          y2="826.83"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-84"
          x1="1221.74"
          y1="829.23"
          x2="1240.06"
          y2="829.23"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-85"
          x1="1036.5"
          y1="831.64"
          x2="1058.15"
          y2="831.64"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-86"
          x1="851.27"
          y1="834.05"
          x2="876.25"
          y2="834.05"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-87"
          x1="666.03"
          y1="836.46"
          x2="694.34"
          y2="836.46"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-88"
          x1="480.8"
          y1="838.86"
          x2="512.44"
          y2="838.86"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-89"
          x1="295.56"
          y1="841.27"
          x2="330.53"
          y2="841.27"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-90"
          x1="110.33"
          y1="843.68"
          x2="148.63"
          y2="843.68"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-91"
          x1="1779.95"
          y1="922"
          x2="1785.9"
          y2="922"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-92"
          x1="1595.19"
          y1="924.41"
          x2="1603.52"
          y2="924.41"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-93"
          x1="1410.43"
          y1="926.82"
          x2="1421.13"
          y2="926.82"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-94"
          x1="1225.67"
          y1="929.22"
          x2="1238.75"
          y2="929.22"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-95"
          x1="1040.91"
          y1="931.63"
          x2="1056.37"
          y2="931.63"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-96"
          x1="856.15"
          y1="934.04"
          x2="873.99"
          y2="934.04"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-97"
          x1="671.39"
          y1="936.45"
          x2="691.61"
          y2="936.45"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-98"
          x1="486.63"
          y1="938.86"
          x2="509.23"
          y2="938.86"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-99"
          x1="301.87"
          y1="941.26"
          x2="326.85"
          y2="941.26"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-100"
          x1="117.11"
          y1="943.67"
          x2="144.47"
          y2="943.67"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-101"
          x1="1279.82"
          y1="-21.9"
          x2="1343.26"
          y2="-21.9"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-102"
          x1="1089.9"
          y1="-19.49"
          x2="1166.03"
          y2="-19.49"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-103"
          x1="899.99"
          y1="-17.08"
          x2="988.81"
          y2="-17.08"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-104"
          x1="710.07"
          y1="-14.67"
          x2="811.58"
          y2="-14.67"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-105"
          x1="520.16"
          y1="-12.27"
          x2="634.35"
          y2="-12.27"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-106"
          x1="330.24"
          y1="-9.86"
          x2="457.13"
          y2="-9.86"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-107"
          x1="140.33"
          y1="-7.45"
          x2="279.9"
          y2="-7.45"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-108"
          x1="-49.59"
          y1="-5.04"
          x2="102.68"
          y2="-5.04"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-109"
          x1="1851.93"
          y1="70.87"
          x2="1875.19"
          y2="70.87"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-110"
          x1="1662.54"
          y1="73.28"
          x2="1697.44"
          y2="73.28"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-111"
          x1="1473.16"
          y1="75.69"
          x2="1519.68"
          y2="75.69"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-112"
          x1="1283.77"
          y1="78.09"
          x2="1341.93"
          y2="78.09"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-113"
          x1="1094.39"
          y1="80.5"
          x2="1164.17"
          y2="80.5"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-114"
          x1="905"
          y1="82.91"
          x2="986.42"
          y2="82.91"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-115"
          x1="715.61"
          y1="85.32"
          x2="808.66"
          y2="85.32"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-116"
          x1="526.23"
          y1="87.72"
          x2="630.91"
          y2="87.72"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-117"
          x1="336.84"
          y1="90.13"
          x2="453.15"
          y2="90.13"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-118"
          x1="147.46"
          y1="92.54"
          x2="275.4"
          y2="92.54"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-119"
          x1="-41.93"
          y1="94.95"
          x2="97.64"
          y2="94.95"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-120"
          x1="1854.3"
          y1="170.86"
          x2="1875.45"
          y2="170.86"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-121"
          x1="1665.44"
          y1="173.27"
          x2="1697.16"
          y2="173.27"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-122"
          x1="1476.58"
          y1="175.68"
          x2="1518.88"
          y2="175.68"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-123"
          x1="1287.73"
          y1="178.09"
          x2="1340.59"
          y2="178.09"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-124"
          x1="1098.87"
          y1="180.49"
          x2="1162.31"
          y2="180.49"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-125"
          x1="910.01"
          y1="182.9"
          x2="984.03"
          y2="182.9"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-126"
          x1="721.15"
          y1="185.31"
          x2="805.75"
          y2="185.31"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-127"
          x1="532.3"
          y1="187.72"
          x2="627.46"
          y2="187.72"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-128"
          x1="343.44"
          y1="190.12"
          x2="449.18"
          y2="190.12"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-129"
          x1="154.58"
          y1="192.53"
          x2="270.89"
          y2="192.53"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-130"
          x1="-34.27"
          y1="194.94"
          x2="92.61"
          y2="194.94"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-131"
          x1="1856.67"
          y1="270.85"
          x2="1875.7"
          y2="270.85"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-132"
          x1="1668.34"
          y1="273.26"
          x2="1696.89"
          y2="273.26"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-133"
          x1="1480.01"
          y1="275.67"
          x2="1518.08"
          y2="275.67"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-134"
          x1="1291.68"
          y1="278.08"
          x2="1339.26"
          y2="278.08"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-135"
          x1="1103.35"
          y1="280.48"
          x2="1160.45"
          y2="280.48"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-136"
          x1="915.02"
          y1="282.89"
          x2="981.64"
          y2="282.89"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-137"
          x1="726.7"
          y1="285.3"
          x2="802.83"
          y2="285.3"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-138"
          x1="538.37"
          y1="287.71"
          x2="624.01"
          y2="287.71"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-139"
          x1="350.04"
          y1="290.11"
          x2="445.2"
          y2="290.11"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-140"
          x1="161.71"
          y1="292.52"
          x2="266.39"
          y2="292.52"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-141"
          x1="-26.62"
          y1="294.93"
          x2="87.58"
          y2="294.93"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-142"
          x1="1859.04"
          y1="370.85"
          x2="1875.95"
          y2="370.85"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-143"
          x1="1671.24"
          y1="373.25"
          x2="1696.61"
          y2="373.25"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-144"
          x1="1483.44"
          y1="375.66"
          x2="1517.27"
          y2="375.66"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-145"
          x1="1295.64"
          y1="378.07"
          x2="1337.93"
          y2="378.07"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-146"
          x1="1107.84"
          y1="380.48"
          x2="1158.59"
          y2="380.48"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-147"
          x1="920.04"
          y1="382.88"
          x2="979.25"
          y2="382.88"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-148"
          x1="732.24"
          y1="385.29"
          x2="799.91"
          y2="385.29"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-149"
          x1="544.44"
          y1="387.7"
          x2="620.57"
          y2="387.7"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-150"
          x1="356.64"
          y1="390.11"
          x2="441.23"
          y2="390.11"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-151"
          x1="168.84"
          y1="392.51"
          x2="261.89"
          y2="392.51"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-152"
          x1="-18.96"
          y1="394.92"
          x2="82.54"
          y2="394.92"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-153"
          x1="1861.4"
          y1="470.84"
          x2="1876.21"
          y2="470.84"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-154"
          x1="1674.13"
          y1="473.24"
          x2="1696.34"
          y2="473.24"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-155"
          x1="1486.86"
          y1="475.65"
          x2="1516.47"
          y2="475.65"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-156"
          x1="1299.59"
          y1="478.06"
          x2="1336.6"
          y2="478.06"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-157"
          x1="1112.32"
          y1="480.47"
          x2="1156.73"
          y2="480.47"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-158"
          x1="925.05"
          y1="482.87"
          x2="976.86"
          y2="482.87"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-159"
          x1="737.78"
          y1="485.28"
          x2="796.99"
          y2="485.28"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-160"
          x1="550.51"
          y1="487.69"
          x2="617.12"
          y2="487.69"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-161"
          x1="363.24"
          y1="490.1"
          x2="437.25"
          y2="490.1"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-162"
          x1="175.96"
          y1="492.51"
          x2="257.38"
          y2="492.51"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-163"
          x1="-11.31"
          y1="494.91"
          x2="77.51"
          y2="494.91"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-164"
          x1="1863.77"
          y1="570.83"
          x2="1876.46"
          y2="570.83"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-165"
          x1="1677.03"
          y1="573.24"
          x2="1696.06"
          y2="573.24"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-166"
          x1="1490.29"
          y1="575.64"
          x2="1515.67"
          y2="575.64"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-167"
          x1="1303.55"
          y1="578.05"
          x2="1335.27"
          y2="578.05"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-168"
          x1="1116.8"
          y1="580.46"
          x2="1154.87"
          y2="580.46"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-169"
          x1="930.06"
          y1="582.87"
          x2="974.47"
          y2="582.87"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-170"
          x1="743.32"
          y1="585.27"
          x2="794.07"
          y2="585.27"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-171"
          x1="556.58"
          y1="587.68"
          x2="613.67"
          y2="587.68"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-172"
          x1="369.83"
          y1="590.09"
          x2="433.28"
          y2="590.09"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-173"
          x1="183.09"
          y1="592.5"
          x2="252.88"
          y2="592.5"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-174"
          x1="-3.65"
          y1="594.9"
          x2="72.48"
          y2="594.9"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-175"
          x1="1866.14"
          y1="670.82"
          x2="1876.72"
          y2="670.82"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-176"
          x1="1679.93"
          y1="673.23"
          x2="1695.79"
          y2="673.23"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-177"
          x1="1493.71"
          y1="675.64"
          x2="1514.86"
          y2="675.64"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-178"
          x1="1307.5"
          y1="678.04"
          x2="1333.93"
          y2="678.04"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-179"
          x1="1121.29"
          y1="680.45"
          x2="1153.01"
          y2="680.45"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-180"
          x1="935.07"
          y1="682.86"
          x2="972.08"
          y2="682.86"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-181"
          x1="748.86"
          y1="685.27"
          x2="791.15"
          y2="685.27"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-182"
          x1="562.65"
          y1="687.67"
          x2="610.23"
          y2="687.67"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-183"
          x1="376.43"
          y1="690.08"
          x2="429.3"
          y2="690.08"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-184"
          x1="190.22"
          y1="692.49"
          x2="248.37"
          y2="692.49"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-185"
          x1="4"
          y1="694.9"
          x2="67.45"
          y2="694.9"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-186"
          x1="1868.51"
          y1="770.81"
          x2="1876.97"
          y2="770.81"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-187"
          x1="1682.83"
          y1="773.22"
          x2="1695.51"
          y2="773.22"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-188"
          x1="1497.14"
          y1="775.63"
          x2="1514.06"
          y2="775.63"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-189"
          x1="1311.46"
          y1="778.03"
          x2="1332.6"
          y2="778.03"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-190"
          x1="1125.77"
          y1="780.44"
          x2="1151.15"
          y2="780.44"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-191"
          x1="940.09"
          y1="782.85"
          x2="969.69"
          y2="782.85"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-192"
          x1="754.4"
          y1="785.26"
          x2="788.24"
          y2="785.26"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-193"
          x1="568.71"
          y1="787.66"
          x2="606.78"
          y2="787.66"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-194"
          x1="383.03"
          y1="790.07"
          x2="425.32"
          y2="790.07"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-195"
          x1="197.34"
          y1="792.48"
          x2="243.87"
          y2="792.48"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-196"
          x1="11.66"
          y1="794.89"
          x2="62.41"
          y2="794.89"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-197"
          x1="1870.88"
          y1="870.8"
          x2="1877.22"
          y2="870.8"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-198"
          x1="1685.72"
          y1="873.21"
          x2="1695.24"
          y2="873.21"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-199"
          x1="1500.57"
          y1="875.62"
          x2="1513.26"
          y2="875.62"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-200"
          x1="1315.41"
          y1="878.03"
          x2="1331.27"
          y2="878.03"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-201"
          x1="1130.25"
          y1="880.43"
          x2="1149.29"
          y2="880.43"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-202"
          x1="945.1"
          y1="882.84"
          x2="967.3"
          y2="882.84"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-203"
          x1="759.94"
          y1="885.25"
          x2="785.32"
          y2="885.25"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-204"
          x1="574.78"
          y1="887.66"
          x2="603.33"
          y2="887.66"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-205"
          x1="389.63"
          y1="890.06"
          x2="421.35"
          y2="890.06"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-206"
          x1="204.47"
          y1="892.47"
          x2="239.37"
          y2="892.47"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-207"
          x1="19.32"
          y1="894.88"
          x2="57.38"
          y2="894.88"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-208"
          x1="1873.25"
          y1="970.79"
          x2="1877.48"
          y2="970.79"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-209"
          x1="1688.62"
          y1="973.2"
          x2="1694.96"
          y2="973.2"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-210"
          x1="1503.99"
          y1="975.61"
          x2="1512.45"
          y2="975.61"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-211"
          x1="1319.36"
          y1="978.02"
          x2="1329.94"
          y2="978.02"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-212"
          x1="1134.74"
          y1="980.42"
          x2="1147.43"
          y2="980.42"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-213"
          x1="950.11"
          y1="982.83"
          x2="964.91"
          y2="982.83"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-214"
          x1="765.48"
          y1="985.24"
          x2="782.4"
          y2="985.24"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-215"
          x1="580.85"
          y1="987.65"
          x2="599.89"
          y2="987.65"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-216"
          x1="396.23"
          y1="990.05"
          x2="417.37"
          y2="990.05"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-217"
          x1="211.6"
          y1="992.46"
          x2="234.86"
          y2="992.46"
          xlinkHref="#Dégradé_sans_nom_177"
        />
        <linearGradient
          id="Dégradé_sans_nom_177-218"
          x1="26.97"
          y1="994.87"
          x2="52.35"
          y2="994.87"
          xlinkHref="#Dégradé_sans_nom_177"
        />
      </defs>
      <g>
        <title>Layer 1</title>
        <g
          transform="rotate(0.177712 932.57 345.329)"
          stroke="null"
          id="svg_224"
        >
          <path
            stroke="null"
            d="m1087.62265,488.1533c434.66241,171.10795 646.42102,40.3486 828.45912,-42.59019l-0.10216,-218.92852c-182.0381,82.93879 -393.79671,213.69814 -828.45912,42.59019c-156.65493,-61.66611 -484.22135,-192.34327 -750.82358,-298.87852l-405.28554,0l0,56.78692s867.79235,347.48364 1156.21129,461.02012z"
            fill={secondaryColor}
            id="svg_1"
          />
          <path
            stroke="null"
            d="m1095.05278,515.79956c434.66241,171.10795 646.42102,40.3486 828.45912,-42.59019l-0.10216,-218.92852c-182.0381,82.93879 -393.79671,213.69814 -828.45912,42.59019c-156.65493,-61.66611 -484.22135,-192.34327 -750.82358,-298.87852l-405.28554,0l0,56.78692s867.79235,347.48364 1156.21129,461.02012z"
            fill={secondaryColor}
            opacity="0.48"
            id="svg_2"
          />
          <rect
            stroke="null"
            x="-37.93937"
            y="-22.34616"
            width="1854.74536"
            height="109.83786"
            fill={secondaryColor}
            id="svg_3"
          />
          <path
            stroke="null"
            className="cls-221"
            d="m40.90358,562.49935c86.37522,51.93014 315.78038,240.22361 797.80985,63.88528c482.02946,-176.33833 708.79693,-224.70434 901.90592,-201.63839l193.10899,23.05848l-10.27215,-456.53694l-1927.24482,0s-19.40192,518.55423 -19.40192,518.55423l64.09413,52.67734z"
            fill={primaryColor}
            id="svg_4"
          />
          <g stroke="null" className="cls-220" opacity="0.28" id="svg_5">
            <g stroke="null" id="all_10">
              <g stroke="null" id="shape_10_1">
                <path
                  stroke="null"
                  className="cls-222"
                  d="m985.10548,6.57781c1.14238,-0.83686 2.30334,-0.8518 3.47358,-0.03736l5.27539,3.65379c1.17024,0.81444 1.18882,1.63636 0.03715,2.47322l-5.15465,3.76587c-1.14238,0.83686 -2.30334,0.8518 -3.47358,0.03736l-5.27539,-3.65379c-1.17024,-0.81444 -1.18882,-1.63636 -0.03715,-2.47322l5.15465,-3.76587z"
                  fill="url(#Dégradé_sans_nom_177)"
                  id="svg_6"
                />
              </g>
              <g stroke="null" id="shape_10_1-2">
                <path
                  stroke="null"
                  className="cls-9"
                  d="m879.38406,5.71107c1.60676,-1.1731 3.22282,-1.18804 4.86673,-0.0523l7.38369,5.11829c1.64392,1.13574 1.66249,2.29389 0.05573,3.46699l-7.21651,5.27521c-1.60676,1.1731 -3.22282,1.18804 -4.86673,0.0523l-7.38369,-5.11829c-1.64392,-1.13574 -1.66249,-2.29389 -0.05573,-3.46699l7.21651,-5.27521z"
                  fill="url(#Dégradé_sans_nom_177-2)"
                  id="svg_7"
                />
              </g>
              <g stroke="null" id="shape_10_1-3">
                <path
                  stroke="null"
                  className="cls-8"
                  d="m773.67194,4.85179c2.06186,-1.50934 4.15158,-1.53175 6.25988,-0.06725l9.50127,6.5828c2.1083,1.4645 2.13616,2.94395 0.0743,4.45329l-9.27837,6.77707c-2.06186,1.50934 -4.15158,1.53175 -6.25988,0.06725l-9.50127,-6.5828c-2.1083,-1.4645 -2.13616,-2.94395 -0.0743,-4.45329l9.27837,-6.77707z"
                  fill="url(#Dégradé_sans_nom_177-3)"
                  id="svg_8"
                />
              </g>
              <g stroke="null" id="shape_10_1-4">
                <path
                  stroke="null"
                  className="cls-7"
                  d="m667.95981,3.98504c2.51696,-1.8381 5.07106,-1.86799 7.65303,-0.08219l11.60957,8.0473c2.58197,1.7858 2.60983,3.60149 0.09288,5.44706l-11.34023,8.28641c-2.51696,1.8381 -5.07106,1.86799 -7.65303,0.08219l-11.60957,-8.0473c-2.58197,-1.7858 -2.60983,-3.60149 -0.09288,-5.44706l11.34023,-8.28641z"
                  fill="url(#Dégradé_sans_nom_177-4)"
                  id="svg_9"
                />
              </g>
              <g stroke="null" id="shape_10_1-5">
                <path
                  stroke="null"
                  className="cls-6"
                  d="m562.2384,3.11829c2.98134,-2.17434 5.99054,-2.2117 9.03689,-0.09714l13.71787,9.50434c3.04635,2.11457 3.0835,4.25902 0.10216,6.43336l-13.40209,9.79574c-2.98134,2.17434 -5.99054,2.2117 -9.03689,0.09714l-13.71787,-9.50434c-3.04635,-2.11457 -3.0835,-4.25902 -0.10216,-6.43336l13.40209,-9.79574z"
                  fill="url(#Dégradé_sans_nom_177-5)"
                  id="svg_10"
                />
              </g>
              <g stroke="null" id="shape_10_1-6">
                <path
                  stroke="null"
                  className="cls-5"
                  d="m456.52627,2.25155c3.43643,-2.51058 6.91002,-2.54794 10.43004,-0.11208l15.82617,10.96884c3.52002,2.43586 3.55717,4.90908 0.12074,7.41966l-15.46395,11.29761c-3.43643,2.51058 -6.91002,2.54794 -10.43004,0.11208l-15.82617,-10.96884c-3.52002,-2.43586 -3.55717,-4.90908 -0.12074,-7.41966l15.46395,-11.29761z"
                  fill="url(#Dégradé_sans_nom_177-6)"
                  id="svg_11"
                />
              </g>
              <g stroke="null" id="shape_10_1-7">
                <path
                  stroke="null"
                  className="cls-4"
                  d="m350.80486,1.39227c3.89153,-2.84682 7.83878,-2.88418 11.82319,-0.12702l17.93447,12.43335c3.98441,2.76463 4.03084,5.56661 0.13931,8.41343l-17.52581,12.80694c-3.89153,2.84682 -7.83878,2.88418 -11.82319,0.12702l-17.93447,-12.43335c-3.98441,-2.76463 -4.03084,-5.56661 -0.13931,-8.41343l17.52581,-12.80694z"
                  fill="url(#Dégradé_sans_nom_177-7)"
                  id="svg_12"
                />
              </g>
              <g stroke="null" id="shape_10_1-8">
                <path
                  stroke="null"
                  className="cls-3"
                  d="m245.09273,0.52552c4.35591,-3.18306 8.75826,-3.22789 13.21634,-0.14197l20.05205,13.89785c4.45808,3.08592 4.50451,6.22415 0.15789,9.39973l-19.58767,14.31628c-4.35591,3.18306 -8.75826,3.22789 -13.21634,0.14197l-20.05205,-13.89785c-4.45808,-3.08592 -4.50451,-6.22415 -0.15789,-9.39973l19.58767,-14.31628z"
                  fill="url(#Dégradé_sans_nom_177-8)"
                  id="svg_13"
                />
              </g>
              <g stroke="null" id="shape_10_1-9">
                <path
                  stroke="null"
                  className="cls-10"
                  d="m139.3806,-0.34122c4.81101,-3.51929 9.67774,-3.56413 14.6002,-0.15691l22.16035,15.35488c4.92246,3.41469 4.97818,6.87421 0.16718,10.3935l-21.64953,15.81815c-4.81101,3.51929 -9.67774,3.56413 -14.6002,0.15691l-22.16035,-15.35488c-4.92246,-3.41469 -4.97818,-6.87421 -0.16718,-10.3935l21.64953,-15.81815z"
                  fill="url(#Dégradé_sans_nom_177-9)"
                  id="svg_14"
                />
              </g>
              <g stroke="null" id="shape_10_1-10">
                <path
                  stroke="null"
                  className="cls-91"
                  d="m33.65919,-1.2005c5.2661,-3.84806 10.59722,-3.90784 15.99335,-0.17186l24.26865,16.81939c5.39613,3.73598 5.45186,7.53174 0.18575,11.3798l-23.71139,17.32748c-5.2661,3.84806 -10.59722,3.90784 -15.99335,0.17186l-24.26865,-16.81939c-5.39613,-3.73598 -5.45186,-7.53174 -0.18575,-11.3798l23.71139,-17.32748z"
                  fill="url(#Dégradé_sans_nom_177-10)"
                  id="svg_15"
                />
              </g>
              <g stroke="null" id="shape_10_1-11">
                <path
                  stroke="null"
                  className="cls-99"
                  d="m986.48005,81.71587c1.04951,-0.76214 2.1083,-0.77708 3.17638,-0.02989l4.82029,3.33997c1.06808,0.73972 1.08666,1.49439 0.03715,2.25653l-4.70884,3.4371c-1.04951,0.76214 -2.1083,0.77708 -3.17638,0.02989l-4.82029,-3.33997c-1.06808,-0.73972 -1.08666,-1.49439 -0.03715,-2.25653l4.70884,-3.4371z"
                  fill="url(#Dégradé_sans_nom_177-11)"
                  id="svg_16"
                />
              </g>
              <g stroke="null" id="shape_10_1-12">
                <path
                  stroke="null"
                  className="cls-100"
                  d="m880.82365,81.02845c1.46745,-1.06849 2.94419,-1.08343 4.44879,-0.04483l6.74284,4.67745c1.49531,1.0386 1.51389,2.09215 0.05573,3.16064l-6.59424,4.81194c-1.46745,1.06849 -2.94419,1.08343 -4.44879,0.04483l-6.74284,-4.67745c-1.49531,-1.0386 -1.51389,-2.09215 -0.05573,-3.16064l6.59424,-4.81194z"
                  fill="url(#Dégradé_sans_nom_177-12)"
                  id="svg_17"
                />
              </g>
              <g stroke="null" id="shape_10_1-13">
                <path
                  stroke="null"
                  className="cls-96"
                  d="m775.17654,80.33356c1.88539,-1.37484 3.78936,-1.39726 5.71191,-0.05978l8.67467,6.00746c1.92255,1.33748 1.95041,2.68991 0.06501,4.06475l-8.47034,6.18679c-1.88539,1.37484 -3.78936,1.39726 -5.71191,0.05978l-8.67467,-6.00746c-1.92255,-1.33748 -1.95041,-2.68991 -0.06501,-4.06475l8.47034,-6.18679z"
                  fill="url(#Dégradé_sans_nom_177-13)"
                  id="svg_18"
                />
              </g>
              <g stroke="null" id="shape_10_1-14">
                <path
                  stroke="null"
                  className="cls-95"
                  d="m669.52014,79.63867c2.30334,-1.68119 4.62525,-1.70361 6.98432,-0.07472l10.59722,7.34494c2.35907,1.62889 2.38693,3.28766 0.08359,4.96886l-10.35574,7.5691c-2.30334,1.68119 -4.62525,1.70361 -6.98432,0.07472l-10.59722,-7.34494c-2.35907,-1.62889 -2.38693,-3.28766 -0.08359,-4.96886l10.35574,-7.5691z"
                  fill="url(#Dégradé_sans_nom_177-14)"
                  id="svg_19"
                />
              </g>
              <g stroke="null" id="shape_10_1-15">
                <path
                  stroke="null"
                  className="cls-97"
                  d="m563.87302,78.94377c2.72128,-1.98754 5.47043,-2.01743 8.25673,-0.08966l12.52905,8.68242c2.7863,1.92777 2.81416,3.88542 0.09288,5.87296l-12.24113,8.94394c-2.72128,1.98754 -5.47043,2.01743 -8.25673,0.08966l-12.52905,-8.68242c-2.7863,-1.92777 -2.81416,-3.88542 -0.09288,-5.87296l12.24113,-8.94394z"
                  fill="url(#Dégradé_sans_nom_177-15)"
                  id="svg_20"
                />
              </g>
              <g stroke="null" id="shape_10_1-16">
                <path
                  stroke="null"
                  className="cls-98"
                  d="m458.21662,78.25635c3.13923,-2.29389 6.31561,-2.32378 9.52914,-0.09714l14.4516,10.01243c3.21353,2.22664 3.25068,4.48318 0.11145,6.77707l-14.11724,10.31878c-3.13923,2.29389 -6.31561,2.32378 -9.52914,0.09714l-14.4516,-10.01243c-3.21353,-2.22664 -3.25068,-4.48318 -0.11145,-6.77707l14.11724,-10.31878z"
                  fill="url(#Dégradé_sans_nom_177-16)"
                  id="svg_21"
                />
              </g>
              <g stroke="null" id="shape_10_1-17">
                <path
                  stroke="null"
                  className="cls-94"
                  d="m352.56951,77.56146c3.55717,-2.60024 7.1515,-2.6376 10.79226,-0.11208l16.38343,11.34991c3.64076,2.52552 3.67791,5.08093 0.12074,7.68118l-16.00264,11.69362c-3.55717,2.60024 -7.1515,2.6376 -10.79226,0.11208l-16.38343,-11.34991c-3.64076,-2.52552 -3.67791,-5.08093 -0.12074,-7.68118l16.00264,-11.69362z"
                  fill="url(#Dégradé_sans_nom_177-17)"
                  id="svg_22"
                />
              </g>
              <g stroke="null" id="shape_10_1-18">
                <path
                  stroke="null"
                  className="cls-93"
                  d="m246.91311,76.86657c3.97512,-2.90659 7.99667,-2.94395 12.06467,-0.12702l18.30597,12.68739c4.06799,2.81693 4.11443,5.67869 0.13931,8.58529l-17.88803,13.06846c-3.97512,2.90659 -7.99667,2.94395 -12.06467,0.12702l-18.30597,-12.68739c-4.06799,-2.81693 -4.11443,-5.67869 -0.13931,-8.58529l17.88803,-13.06846z"
                  fill="url(#Dégradé_sans_nom_177-18)"
                  id="svg_23"
                />
              </g>
              <g stroke="null" id="shape_10_1-19">
                <path
                  stroke="null"
                  className="cls-92"
                  d="m141.25671,76.17168c4.39306,-3.21294 8.84185,-3.25778 13.33708,-0.14197l20.22852,14.02487c4.49523,3.11581 4.55095,6.27645 0.15789,9.48939l-19.77342,14.4433c-4.39306,3.21294 -8.84185,3.25778 -13.33708,0.14197l-20.22852,-14.02487c-4.49523,-3.11581 -4.55095,-6.27645 -0.15789,-9.48939l19.77342,-14.4433z"
                  fill="url(#Dégradé_sans_nom_177-19)"
                  id="svg_24"
                />
              </g>
              <g stroke="null" id="shape_10_1-20">
                <path
                  stroke="null"
                  className="cls-50"
                  d="m35.6096,75.48426c4.81101,-3.51929 9.67774,-3.56413 14.6002,-0.15691l22.16035,15.35488c4.92246,3.41469 4.97818,6.87421 0.16718,10.3935l-21.64953,15.81815c-4.81101,3.51929 -9.67774,3.56413 -14.6002,0.15691l-22.16035,-15.35488c-4.92246,-3.41469 -4.97818,-6.87421 -0.16718,-10.3935l21.64953,-15.81815z"
                  fill="url(#Dégradé_sans_nom_177-20)"
                  id="svg_25"
                />
              </g>
              <g stroke="null" id="shape_10_1-21">
                <path
                  stroke="null"
                  className="cls-48"
                  d="m987.85462,156.8614c0.94734,-0.69489 1.90397,-0.70236 2.86989,-0.02989l4.35591,3.01867c0.96592,0.67248 0.9752,1.35243 0.03715,2.04732l-4.26304,3.10834c-0.94734,0.69489 -1.90397,0.70236 -2.86989,0.02989l-4.35591,-3.01867c-0.96592,-0.67248 -0.9752,-1.35243 -0.03715,-2.04732l4.26304,-3.10834z"
                  fill="url(#Dégradé_sans_nom_177-21)"
                  id="svg_26"
                />
              </g>
              <g stroke="null" id="shape_10_1-22">
                <path
                  stroke="null"
                  className="cls-55"
                  d="m882.26324,156.33837c1.32814,-0.97136 2.66556,-0.97883 4.02156,-0.04483l6.10199,4.22913c1.356,0.94147 1.37457,1.89041 0.04644,2.86176l-5.96268,4.35615c-1.32814,0.97136 -2.66556,0.97883 -4.02156,0.04483l-6.10199,-4.22913c-1.356,-0.94147 -1.37457,-1.89041 -0.04644,-2.86176l5.96268,-4.35615z"
                  fill="url(#Dégradé_sans_nom_177-22)"
                  id="svg_27"
                />
              </g>
              <g stroke="null" id="shape_10_1-23">
                <path
                  stroke="null"
                  className="cls-49"
                  d="m776.68114,155.81533c1.69964,-1.24782 3.42715,-1.26276 5.17323,-0.0523l7.84807,5.43959c1.74608,1.21046 1.76466,2.43586 0.05573,3.67621l-7.66232,5.60397c-1.69964,1.24782 -3.42715,1.26276 -5.17323,0.0523l-7.84807,-5.43959c-1.74608,-1.21046 -1.76466,-2.43586 -0.05573,-3.67621l7.66232,-5.60397z"
                  fill="url(#Dégradé_sans_nom_177-23)"
                  id="svg_28"
                />
              </g>
              <g stroke="null" id="shape_10_1-24">
                <path
                  stroke="null"
                  className="cls-30"
                  d="m671.08975,155.29976c2.08044,-1.52428 4.18873,-1.5467 6.31561,-0.06725l9.58486,6.64258c2.12687,1.47945 2.15474,2.97384 0.0743,4.49812l-9.37125,6.84432c-2.08044,1.52428 -4.18873,1.5467 -6.31561,0.06725l-9.58486,-6.64258c-2.12687,-1.47945 -2.15474,-2.97384 -0.0743,-4.49812l9.37125,-6.84432z"
                  fill="url(#Dégradé_sans_nom_177-24)"
                  id="svg_29"
                />
              </g>
              <g stroke="null" id="shape_10_1-25">
                <path
                  stroke="null"
                  className="cls-31"
                  d="m565.49836,154.77673c2.46123,-1.80074 4.95032,-1.82316 7.46728,-0.08219l11.33094,7.85303c2.51696,1.74844 2.54482,3.51929 0.08359,5.31257l-11.07089,8.09214c-2.46123,1.80074 -4.95032,1.82316 -7.46728,0.08219l-11.33094,-7.85303c-2.51696,-1.74844 -2.54482,-3.51929 -0.08359,-5.31257l11.07089,-8.09214z"
                  fill="url(#Dégradé_sans_nom_177-25)"
                  id="svg_30"
                />
              </g>
              <g stroke="null" id="shape_10_1-26">
                <path
                  stroke="null"
                  className="cls-29"
                  d="m459.90698,154.25369c2.84202,-2.07721 5.71191,-2.10709 8.61895,-0.08966l13.07702,9.06349c2.90704,2.00996 2.9349,4.05728 0.10216,6.13448l-12.77982,9.33248c-2.84202,2.07721 -5.71191,2.10709 -8.61895,0.08966l-13.07702,-9.06349c-2.90704,-2.00996 -2.9349,-4.05728 -0.10216,-6.13448l12.77982,-9.33248z"
                  fill="url(#Dégradé_sans_nom_177-26)"
                  id="svg_31"
                />
              </g>
              <g stroke="null" id="shape_10_1-27">
                <path
                  stroke="null"
                  className="cls-28"
                  d="m354.32488,153.73065c3.21353,-2.35367 6.4735,-2.38356 9.77062,-0.10461l14.8231,10.27395c3.29712,2.27895 3.33427,4.60273 0.11145,6.94893l-14.47946,10.5803c-3.21353,2.35367 -6.4735,2.38356 -9.77062,0.10461l-14.8231,-10.27395c-3.29712,-2.27895 -3.33427,-4.60273 -0.11145,-6.94893l14.47946,-10.5803z"
                  fill="url(#Dégradé_sans_nom_177-27)"
                  id="svg_32"
                />
              </g>
              <g stroke="null" id="shape_10_1-28">
                <path
                  stroke="null"
                  className="cls-37"
                  d="m248.73349,153.20761c3.59432,-2.63013 7.23509,-2.66749 10.913,-0.11208l16.55989,11.47694c3.67791,2.54794 3.72435,5.14071 0.13003,7.77084l-16.1791,11.82812c-3.59432,2.63013 -7.23509,2.66749 -10.913,0.11208l-16.55989,-11.47694c-3.67791,-2.54794 -3.72435,-5.14071 -0.13003,-7.77084l16.1791,-11.82812z"
                  fill="url(#Dégradé_sans_nom_177-28)"
                  id="svg_33"
                />
              </g>
              <g stroke="null" id="shape_10_1-29">
                <path
                  stroke="null"
                  className="cls-38"
                  d="m143.14211,152.69205c3.97512,-2.90659 7.99667,-2.94395 12.06467,-0.12702l18.30597,12.68739c4.06799,2.81693 4.11443,5.67869 0.13931,8.58529l-17.88803,13.06846c-3.97512,2.90659 -7.99667,2.94395 -12.06467,0.12702l-18.30597,-12.68739c-4.06799,-2.81693 -4.11443,-5.67869 -0.13931,-8.58529l17.88803,-13.06846z"
                  fill="url(#Dégradé_sans_nom_177-29)"
                  id="svg_34"
                />
              </g>
              <g stroke="null" id="shape_10_1-30">
                <path
                  stroke="null"
                  className="cls-44"
                  d="m37.55072,152.16901c4.35591,-3.18306 8.75826,-3.22789 13.21634,-0.14197l20.05205,13.89785c4.45808,3.08592 4.50451,6.22415 0.15789,9.39973l-19.58767,14.31628c-4.35591,3.18306 -8.75826,3.22789 -13.21634,0.14197l-20.05205,-13.89785c-4.45808,-3.08592 -4.50451,-6.22415 -0.15789,-9.39973l19.58767,-14.31628z"
                  fill="url(#Dégradé_sans_nom_177-30)"
                  id="svg_35"
                />
              </g>
              <g stroke="null" id="shape_10_1-31">
                <path
                  stroke="null"
                  className="cls-47"
                  d="m989.2292,231.99946c0.84518,-0.62017 1.69964,-0.62764 2.57268,-0.02989l3.90082,2.70485c0.86375,0.59776 0.87304,1.21046 0.02786,1.83063l-3.80794,2.78704c-0.84518,0.62017 -1.69964,0.62764 -2.57268,0.02989l-3.90082,-2.70485c-0.86375,-0.59776 -0.87304,-1.21046 -0.02786,-1.83063l3.80794,-2.78704z"
                  fill="url(#Dégradé_sans_nom_177-31)"
                  id="svg_36"
                />
              </g>
              <g stroke="null" id="shape_10_1-32">
                <path
                  stroke="null"
                  className="cls-46"
                  d="m883.70282,231.65575c1.18882,-0.86675 2.38693,-0.88169 3.59432,-0.03736l5.46114,3.78081c1.21668,0.84433 1.22597,1.69614 0.03715,2.56288l-5.33112,3.90036c-1.18882,0.86675 -2.38693,0.88169 -3.59432,0.03736l-5.46114,-3.78081c-1.21668,-0.84433 -1.22597,-1.69614 -0.03715,-2.56288l5.33112,-3.90036z"
                  fill="url(#Dégradé_sans_nom_177-32)"
                  id="svg_37"
                />
              </g>
              <g stroke="null" id="shape_10_1-33">
                <path
                  stroke="null"
                  className="cls-45"
                  d="m778.17645,231.30457c1.52318,-1.11332 3.06493,-1.12827 4.62525,-0.0523l7.02147,4.86425c1.56033,1.08343 1.5789,2.18181 0.05573,3.29514l-6.86358,5.01369c-1.52318,1.11332 -3.06493,1.12827 -4.62525,0.0523l-7.02147,-4.86425c-1.56033,-1.08343 -1.5789,-2.18181 -0.05573,-3.29514l6.86358,-5.01369z"
                  fill="url(#Dégradé_sans_nom_177-33)"
                  id="svg_38"
                />
              </g>
              <g stroke="null" id="shape_10_1-34">
                <path
                  stroke="null"
                  className="cls-54"
                  d="m672.65008,230.95339c1.86682,-1.3599 3.75221,-1.38231 5.65618,-0.05978l8.5818,5.94768c1.90397,1.32254 1.93183,2.66002 0.06501,4.01992l-8.38676,6.12701c-1.86682,1.3599 -3.75221,1.38231 -5.65618,0.05978l-8.5818,-5.94768c-1.90397,-1.32254 -1.93183,-2.66002 -0.06501,-4.01992l8.38676,-6.12701z"
                  fill="url(#Dégradé_sans_nom_177-34)"
                  id="svg_39"
                />
              </g>
              <g stroke="null" id="shape_10_1-35">
                <path
                  stroke="null"
                  className="cls-52"
                  d="m567.12371,230.60221c2.20118,-1.60647 4.43021,-1.62889 6.68711,-0.06725l10.14212,7.02365c2.2569,1.56164 2.27548,3.1457 0.0743,4.75217l-9.90993,7.24033c-2.20118,1.60647 -4.43021,1.62889 -6.68711,0.06725l-10.14212,-7.02365c-2.2569,-1.56164 -2.27548,-3.1457 -0.0743,-4.75217l9.90993,-7.24033z"
                  fill="url(#Dégradé_sans_nom_177-35)"
                  id="svg_40"
                />
              </g>
              <g stroke="null" id="shape_10_1-36">
                <path
                  stroke="null"
                  className="cls-51"
                  d="m461.60662,230.25103c2.54482,-1.85305 5.10821,-1.88293 7.70876,-0.08219l11.70245,8.10708c2.60054,1.80074 2.62841,3.63137 0.09288,5.48442l-11.43311,8.35365c-2.54482,1.85305 -5.10821,1.88293 -7.70876,0.08219l-11.70245,-8.10708c-2.60054,-1.80074 -2.62841,-3.63137 -0.09288,-5.48442l11.43311,-8.35365z"
                  fill="url(#Dégradé_sans_nom_177-36)"
                  id="svg_41"
                />
              </g>
              <g stroke="null" id="shape_10_1-37">
                <path
                  stroke="null"
                  className="cls-53"
                  d="m356.08025,229.89984c2.87917,-2.10709 5.78621,-2.13698 8.73969,-0.08966l13.26278,9.19051c2.94419,2.03985 2.98134,4.11705 0.10216,6.21667l-12.95628,9.46698c-2.87917,2.10709 -5.78621,2.13698 -8.73969,0.08966l-13.26278,-9.19051c-2.94419,-2.03985 -2.98134,-4.11705 -0.10216,-6.21667l12.95628,-9.46698z"
                  fill="url(#Dégradé_sans_nom_177-37)"
                  id="svg_42"
                />
              </g>
              <g stroke="null" id="shape_10_1-38">
                <path
                  stroke="null"
                  className="cls-27"
                  d="m250.55387,229.55613c3.21353,-2.35367 6.4735,-2.38356 9.77062,-0.10461l14.8231,10.27395c3.29712,2.27895 3.33427,4.60273 0.11145,6.94893l-14.47946,10.5803c-3.21353,2.35367 -6.4735,2.38356 -9.77062,0.10461l-14.8231,-10.27395c-3.29712,-2.27895 -3.33427,-4.60273 -0.11145,-6.94893l14.47946,-10.5803z"
                  fill="url(#Dégradé_sans_nom_177-38)"
                  id="svg_43"
                />
              </g>
              <g stroke="null" id="shape_10_1-39">
                <path
                  stroke="null"
                  className="cls-26"
                  d="m145.0275,229.20495c3.55717,-2.60024 7.1515,-2.6376 10.79226,-0.11208l16.38343,11.34991c3.64076,2.52552 3.67791,5.08093 0.12074,7.68118l-16.00264,11.69362c-3.55717,2.60024 -7.1515,2.6376 -10.79226,0.11208l-16.38343,-11.34991c-3.64076,-2.52552 -3.67791,-5.08093 -0.12074,-7.68118l16.00264,-11.69362z"
                  fill="url(#Dégradé_sans_nom_177-39)"
                  id="svg_44"
                />
              </g>
              <g stroke="null" id="shape_10_1-40">
                <path
                  stroke="null"
                  className="cls-87"
                  d="m39.50113,228.85377c3.89153,-2.84682 7.83878,-2.88418 11.82319,-0.12702l17.93447,12.43335c3.98441,2.76463 4.03084,5.56661 0.13931,8.41343l-17.52581,12.80694c-3.89153,2.84682 -7.83878,2.88418 -11.82319,0.12702l-17.93447,-12.43335c-3.98441,-2.76463 -4.03084,-5.56661 -0.13931,-8.41343l17.52581,-12.80694z"
                  fill="url(#Dégradé_sans_nom_177-40)"
                  id="svg_45"
                />
              </g>
              <g stroke="null" id="shape_10_1-41">
                <path
                  stroke="null"
                  className="cls-90"
                  d="m990.60377,307.145c0.74301,-0.54545 1.5046,-0.55293 2.26619,-0.02242l3.43643,2.38356c0.76159,0.53051 0.77088,1.06849 0.02786,1.61394l-3.36213,2.45828c-0.74301,0.54545 -1.5046,0.55293 -2.26619,0.02242l-3.43643,-2.38356c-0.76159,-0.53051 -0.77088,-1.06849 -0.02786,-1.61394l3.36213,-2.45828z"
                  fill="url(#Dégradé_sans_nom_177-41)"
                  id="svg_46"
                />
              </g>
              <g stroke="null" id="shape_10_1-42">
                <path
                  stroke="null"
                  className="cls-88"
                  d="m885.14241,306.96567c1.04951,-0.76214 2.1083,-0.77708 3.17638,-0.02989l4.82029,3.33997c1.06808,0.73972 1.08666,1.49439 0.03715,2.25653l-4.70884,3.4371c-1.04951,0.76214 -2.1083,0.77708 -3.17638,0.02989l-4.82029,-3.33997c-1.06808,-0.73972 -1.08666,-1.49439 -0.03715,-2.25653l4.70884,-3.4371z"
                  fill="url(#Dégradé_sans_nom_177-42)"
                  id="svg_47"
                />
              </g>
              <g stroke="null" id="shape_10_1-43">
                <path
                  stroke="null"
                  className="cls-89"
                  d="m779.68105,306.78634c1.34671,-0.9863 2.70271,-0.99377 4.07728,-0.04483l6.19487,4.29638c1.37457,0.95641 1.39315,1.92029 0.04644,2.90659l-6.05555,4.4234c-1.34671,0.9863 -2.70271,0.99377 -4.07728,0.04483l-6.19487,-4.29638c-1.37457,-0.95641 -1.39315,-1.92029 -0.04644,-2.90659l6.05555,-4.4234z"
                  fill="url(#Dégradé_sans_nom_177-43)"
                  id="svg_48"
                />
              </g>
              <g stroke="null" id="shape_10_1-44">
                <path
                  stroke="null"
                  className="cls-66"
                  d="m674.21969,306.60702c1.64392,-1.20299 3.30641,-1.21793 4.98747,-0.0523l7.56944,5.24532c1.68107,1.16563 1.69964,2.3462 0.05573,3.54918l-7.39298,5.40223c-1.64392,1.20299 -3.30641,1.21793 -4.98747,0.0523l-7.56944,-5.24532c-1.68107,-1.16563 -1.69964,-2.3462 -0.05573,-3.54918l7.39298,-5.40223z"
                  fill="url(#Dégradé_sans_nom_177-44)"
                  id="svg_49"
                />
              </g>
              <g stroke="null" id="shape_10_1-45">
                <path
                  stroke="null"
                  className="cls-67"
                  d="m568.75833,306.42769c1.94112,-1.41967 3.9101,-1.44209 5.89766,-0.05978l8.94401,6.20173c1.98756,1.37484 2.00613,2.77957 0.06501,4.19177l-8.73969,6.38853c-1.94112,1.41967 -3.9101,1.44209 -5.89766,0.05978l-8.94401,-6.20173c-1.98756,-1.37484 -2.00613,-2.77957 -0.06501,-4.19177l8.73969,-6.38853z"
                  fill="url(#Dégradé_sans_nom_177-45)"
                  id="svg_50"
                />
              </g>
              <g stroke="null" id="shape_10_1-46">
                <path
                  stroke="null"
                  className="cls-65"
                  d="m463.29697,306.25583c2.23833,-1.63636 4.5138,-1.65878 6.80785,-0.07472l10.31859,7.15067c2.29405,1.59153 2.32191,3.20547 0.0743,4.84183l-10.0864,7.36736c-2.23833,1.63636 -4.5138,1.65878 -6.80785,0.07472l-10.31859,-7.15067c-2.29405,-1.59153 -2.32191,-3.20547 -0.0743,-4.84183l10.0864,-7.36736z"
                  fill="url(#Dégradé_sans_nom_177-46)"
                  id="svg_51"
                />
              </g>
              <g stroke="null" id="shape_10_1-47">
                <path
                  stroke="null"
                  className="cls-61"
                  d="m357.83561,306.07651c2.54482,-1.85305 5.10821,-1.88293 7.70876,-0.08219l11.70245,8.10708c2.60054,1.80074 2.62841,3.63137 0.09288,5.48442l-11.43311,8.35365c-2.54482,1.85305 -5.10821,1.88293 -7.70876,0.08219l-11.70245,-8.10708c-2.60054,-1.80074 -2.62841,-3.63137 -0.09288,-5.48442l11.43311,-8.35365z"
                  fill="url(#Dégradé_sans_nom_177-47)"
                  id="svg_52"
                />
              </g>
              <g stroke="null" id="shape_10_1-48">
                <path
                  stroke="null"
                  className="cls-63"
                  d="m252.37425,305.89718c2.84202,-2.07721 5.71191,-2.10709 8.61895,-0.08966l13.07702,9.06349c2.90704,2.00996 2.9349,4.05728 0.10216,6.13448l-12.77982,9.33248c-2.84202,2.07721 -5.71191,2.10709 -8.61895,0.08966l-13.07702,-9.06349c-2.90704,-2.00996 -2.9349,-4.05728 -0.10216,-6.13448l12.77982,-9.33248z"
                  fill="url(#Dégradé_sans_nom_177-48)"
                  id="svg_53"
                />
              </g>
              <g stroke="null" id="shape_10_1-49">
                <path
                  stroke="null"
                  className="cls-64"
                  d="m146.90361,305.71785c3.13923,-2.29389 6.31561,-2.32378 9.52914,-0.09714l14.4516,10.01243c3.21353,2.22664 3.25068,4.48318 0.11145,6.77707l-14.11724,10.31878c-3.13923,2.29389 -6.31561,2.32378 -9.52914,0.09714l-14.4516,-10.01243c-3.21353,-2.22664 -3.25068,-4.48318 -0.11145,-6.77707l14.11724,-10.31878z"
                  fill="url(#Dégradé_sans_nom_177-49)"
                  id="svg_54"
                />
              </g>
              <g stroke="null" id="shape_10_1-50">
                <path
                  stroke="null"
                  className="cls-15"
                  d="m41.44225,305.53852c3.43643,-2.51058 6.91002,-2.54794 10.43004,-0.11208l15.82617,10.96884c3.52002,2.43586 3.55717,4.90908 0.12074,7.41966l-15.46395,11.29761c-3.43643,2.51058 -6.91002,2.54794 -10.43004,0.11208l-15.82617,-10.96884c-3.52002,-2.43586 -3.55717,-4.90908 -0.12074,-7.41966l15.46395,-11.29761z"
                  fill="url(#Dégradé_sans_nom_177-50)"
                  id="svg_55"
                />
              </g>
              <g stroke="null" id="shape_10_1-51">
                <path
                  stroke="null"
                  className="cls-20"
                  d="m991.98763,382.29053c0.65014,-0.47073 1.30027,-0.47821 1.96898,-0.02242l2.98134,2.06973c0.65942,0.45579 0.66871,0.92652 0.01858,1.39726l-2.91632,2.12951c-0.65014,0.47073 -1.30027,0.47821 -1.96898,0.02242l-2.98134,-2.06973c-0.65942,-0.45579 -0.66871,-0.92652 -0.01858,-1.39726l2.91632,-2.12951z"
                  fill="url(#Dégradé_sans_nom_177-51)"
                  id="svg_56"
                />
              </g>
              <g stroke="null" id="shape_10_1-52">
                <path
                  stroke="null"
                  className="cls-18"
                  d="m886.582,382.28306c0.91019,-0.665 1.82038,-0.67248 2.74915,-0.02989l4.17945,2.89165c0.92877,0.64259 0.93805,1.29265 0.02786,1.95765l-4.07728,2.98131c-0.91019,0.665 -1.82038,0.67248 -2.74915,0.02989l-4.17945,-2.89165c-0.92877,-0.64259 -0.93805,-1.29265 -0.02786,-1.95765l4.07728,-2.98131z"
                  fill="url(#Dégradé_sans_nom_177-52)"
                  id="svg_57"
                />
              </g>
              <g stroke="null" id="shape_10_1-53">
                <path
                  stroke="null"
                  className="cls-17"
                  d="m781.18565,382.27558c1.16096,-0.8518 2.34049,-0.86675 3.5386,-0.03736l5.36827,3.72104c1.18882,0.82939 1.2074,1.66625 0.03715,2.51805l-5.24753,3.83312c-1.16096,0.8518 -2.34049,0.86675 -3.5386,0.03736l-5.36827,-3.72104c-1.18882,-0.82939 -1.2074,-1.66625 -0.03715,-2.51805l5.24753,-3.83312z"
                  fill="url(#Dégradé_sans_nom_177-53)"
                  id="svg_58"
                />
              </g>
              <g stroke="null" id="shape_10_1-54">
                <path
                  stroke="null"
                  className="cls-13"
                  d="m675.78931,382.26811c1.42101,-1.0386 2.86989,-1.05355 4.32805,-0.04483l6.55709,4.54295c1.45816,1.00872 1.47674,2.03985 0.04644,3.07845l-6.40848,4.68492c-1.42101,1.0386 -2.86989,1.05355 -4.32805,0.04483l-6.55709,-4.54295c-1.45816,-1.00872 -1.47674,-2.03985 -0.04644,-3.07845l6.40848,-4.68492z"
                  fill="url(#Dégradé_sans_nom_177-54)"
                  id="svg_59"
                />
              </g>
              <g stroke="null" id="shape_10_1-55">
                <path
                  stroke="null"
                  className="cls-12"
                  d="m570.38367,382.26064c1.68107,-1.23287 3.39,-1.24782 5.10821,-0.0523l7.75519,5.37234c1.71822,1.19551 1.74608,2.40597 0.05573,3.63885l-7.57873,5.53672c-1.68107,1.23287 -3.39,1.24782 -5.10821,0.0523l-7.75519,-5.37234c-1.71822,-1.19551 -1.74608,-2.40597 -0.05573,-3.63885l7.57873,-5.53672z"
                  fill="url(#Dégradé_sans_nom_177-55)"
                  id="svg_60"
                />
              </g>
              <g stroke="null" id="shape_10_1-56">
                <path
                  stroke="null"
                  className="cls-11"
                  d="m464.98733,382.25317c1.94112,-1.41967 3.9101,-1.44209 5.89766,-0.05978l8.94401,6.20173c1.98756,1.37484 2.00613,2.77957 0.06501,4.19177l-8.73969,6.38853c-1.94112,1.41967 -3.9101,1.44209 -5.89766,0.05978l-8.94401,-6.20173c-1.98756,-1.37484 -2.00613,-2.77957 -0.06501,-4.19177l8.73969,-6.38853z"
                  fill="url(#Dégradé_sans_nom_177-56)"
                  id="svg_61"
                />
              </g>
              <g stroke="null" id="shape_10_1-57">
                <path
                  stroke="null"
                  className="cls-14"
                  d="m359.59098,382.2457c2.20118,-1.60647 4.43021,-1.62889 6.68711,-0.06725l10.14212,7.02365c2.2569,1.56164 2.27548,3.1457 0.0743,4.75217l-9.90993,7.24033c-2.20118,1.60647 -4.43021,1.62889 -6.68711,0.06725l-10.14212,-7.02365c-2.2569,-1.56164 -2.27548,-3.1457 -0.0743,-4.75217l9.90993,-7.24033z"
                  fill="url(#Dégradé_sans_nom_177-57)"
                  id="svg_62"
                />
              </g>
              <g stroke="null" id="shape_10_1-58">
                <path
                  stroke="null"
                  className="cls-19"
                  d="m254.18535,382.23822c2.46123,-1.80074 4.95032,-1.82316 7.46728,-0.08219l11.33094,7.85303c2.51696,1.74844 2.54482,3.51929 0.08359,5.31257l-11.07089,8.09214c-2.46123,1.80074 -4.95032,1.82316 -7.46728,0.08219l-11.33094,-7.85303c-2.51696,-1.74844 -2.54482,-3.51929 -0.08359,-5.31257l11.07089,-8.09214z"
                  fill="url(#Dégradé_sans_nom_177-58)"
                  id="svg_63"
                />
              </g>
              <g stroke="null" id="shape_10_1-59">
                <path
                  stroke="null"
                  className="cls-16"
                  d="m148.789,382.23075c2.72128,-1.98754 5.47043,-2.01743 8.25673,-0.08966l12.52905,8.68242c2.7863,1.92777 2.81416,3.88542 0.09288,5.87296l-12.24113,8.94394c-2.72128,1.98754 -5.47043,2.01743 -8.25673,0.08966l-12.52905,-8.68242c-2.7863,-1.92777 -2.81416,-3.88542 -0.09288,-5.87296l12.24113,-8.94394z"
                  fill="url(#Dégradé_sans_nom_177-59)"
                  id="svg_64"
                />
              </g>
              <g stroke="null" id="shape_10_1-60">
                <path
                  stroke="null"
                  className="cls-79"
                  d="m43.39266,382.22328c2.98134,-2.17434 5.99054,-2.20423 9.03689,-0.09714l13.71787,9.50434c3.04635,2.11457 3.0835,4.25902 0.10216,6.43336l-13.40209,9.79574c-2.98134,2.17434 -5.99054,2.20423 -9.03689,0.09714l-13.71787,-9.50434c-3.04635,-2.11457 -3.0835,-4.25902 -0.10216,-6.43336l13.40209,-9.79574z"
                  fill="url(#Dégradé_sans_nom_177-60)"
                  id="svg_65"
                />
              </g>
              <g stroke="null" id="shape_10_1-61">
                <path
                  stroke="null"
                  className="cls-80"
                  d="m993.36221,457.42859c0.54797,-0.40349 1.10523,-0.40349 1.66249,-0.01494l2.52624,1.74844c0.55726,0.38854 0.56655,0.78456 0.01858,1.18057l-2.46123,1.80074c-0.54797,0.40349 -1.10523,0.40349 -1.66249,0.01494l-2.52624,-1.74844c-0.55726,-0.38854 -0.56655,-0.78456 -0.01858,-1.18057l2.46123,-1.80074z"
                  fill="url(#Dégradé_sans_nom_177-61)"
                  id="svg_66"
                />
              </g>
              <g stroke="null" id="shape_10_1-62">
                <path
                  stroke="null"
                  className="cls-81"
                  d="m888.02159,457.59297c0.77088,-0.5604 1.54175,-0.56787 2.3312,-0.02242l3.52931,2.4508c0.78945,0.54545 0.78945,1.09838 0.02786,1.65878l-3.45501,2.52552c-0.77088,0.5604 -1.54175,0.56787 -2.3312,0.02242l-3.52931,-2.4508c-0.78945,-0.54545 -0.78945,-1.09838 -0.02786,-1.65878l3.45501,-2.52552z"
                  fill="url(#Dégradé_sans_nom_177-62)"
                  id="svg_67"
                />
              </g>
              <g stroke="null" id="shape_10_1-63">
                <path
                  stroke="null"
                  className="cls-82"
                  d="m782.69025,457.75735c0.98449,-0.71731 1.98756,-0.73225 2.99063,-0.02989l4.54166,3.1457c1.01235,0.70236 1.02164,1.4122 0.03715,2.12951l-4.4395,3.24283c-0.98449,0.71731 -1.98756,0.73225 -2.99063,0.02989l-4.54166,-3.1457c-1.01235,-0.70236 -1.02164,-1.4122 -0.03715,-2.12951l4.4395,-3.24283z"
                  fill="url(#Dégradé_sans_nom_177-63)"
                  id="svg_68"
                />
              </g>
              <g stroke="null" id="shape_10_1-64">
                <path
                  stroke="null"
                  className="cls-68"
                  d="m677.34963,457.92174c1.2074,-0.88169 2.42408,-0.89664 3.65934,-0.03736l5.55402,3.84806c1.23526,0.8518 1.24455,1.72602 0.04644,2.60024l-5.42399,3.96014c-1.2074,0.88169 -2.42408,0.89664 -3.65934,0.03736l-5.55402,-3.84806c-1.23526,-0.8518 -1.24455,-1.72602 -0.04644,-2.60024l5.42399,-3.96014z"
                  fill="url(#Dégradé_sans_nom_177-64)"
                  id="svg_69"
                />
              </g>
              <g stroke="null" id="shape_10_1-65">
                <path
                  stroke="null"
                  className="cls-60"
                  d="m572.0183,458.08612c1.42101,-1.0386 2.86989,-1.05355 4.32805,-0.04483l6.55709,4.54295c1.45816,1.00872 1.47674,2.03985 0.04644,3.07845l-6.40848,4.68492c-1.42101,1.0386 -2.86989,1.05355 -4.32805,0.04483l-6.55709,-4.54295c-1.45816,-1.00872 -1.47674,-2.03985 -0.04644,-3.07845l6.40848,-4.68492z"
                  fill="url(#Dégradé_sans_nom_177-65)"
                  id="svg_70"
                />
              </g>
              <g stroke="null" id="shape_10_1-66">
                <path
                  stroke="null"
                  className="cls-59"
                  d="m466.67768,458.2505c1.64392,-1.20299 3.30641,-1.21793 4.98747,-0.0523l7.56944,5.24532c1.68107,1.16563 1.69964,2.3462 0.05573,3.54918l-7.39298,5.40223c-1.64392,1.20299 -3.30641,1.21793 -4.98747,0.0523l-7.56944,-5.24532c-1.68107,-1.16563 -1.69964,-2.3462 -0.05573,-3.54918l7.39298,-5.40223z"
                  fill="url(#Dégradé_sans_nom_177-66)"
                  id="svg_71"
                />
              </g>
              <g stroke="null" id="shape_10_1-67">
                <path
                  stroke="null"
                  className="cls-58"
                  d="m361.34635,458.41489c1.86682,-1.3599 3.74293,-1.38231 5.65618,-0.05978l8.5818,5.94768c1.90397,1.32254 1.93183,2.66002 0.06501,4.01992l-8.38676,6.12701c-1.86682,1.3599 -3.74293,1.38231 -5.65618,0.05978l-8.5818,-5.94768c-1.90397,-1.32254 -1.93183,-2.66002 -0.06501,-4.01992l8.38676,-6.12701z"
                  fill="url(#Dégradé_sans_nom_177-67)"
                  id="svg_72"
                />
              </g>
              <g stroke="null" id="shape_10_1-68">
                <path
                  stroke="null"
                  className="cls-69"
                  d="m256.00573,458.57927c2.08044,-1.52428 4.18873,-1.5467 6.31561,-0.06725l9.58486,6.64258c2.12687,1.47945 2.15474,2.97384 0.0743,4.49812l-9.37125,6.84432c-2.08044,1.52428 -4.18873,1.5467 -6.31561,0.06725l-9.58486,-6.64258c-2.12687,-1.47945 -2.15474,-2.97384 -0.0743,-4.49812l9.37125,-6.84432z"
                  fill="url(#Dégradé_sans_nom_177-68)"
                  id="svg_73"
                />
              </g>
              <g stroke="null" id="shape_10_1-69">
                <path
                  stroke="null"
                  className="cls-62"
                  d="m150.6744,458.74365c2.30334,-1.68119 4.62525,-1.70361 6.98432,-0.07472l10.59722,7.34494c2.35907,1.62889 2.38693,3.28766 0.08359,4.96886l-10.35574,7.5691c-2.30334,1.68119 -4.62525,1.70361 -6.98432,0.07472l-10.59722,-7.34494c-2.35907,-1.62889 -2.38693,-3.28766 -0.08359,-4.96886l10.35574,-7.5691z"
                  fill="url(#Dégradé_sans_nom_177-69)"
                  id="svg_74"
                />
              </g>
              <g stroke="null" id="shape_10_1-70">
                <path
                  stroke="null"
                  className="cls-70"
                  d="m45.33378,458.90804c2.51696,-1.8381 5.07106,-1.86799 7.65303,-0.08219l11.60957,8.0473c2.58197,1.7858 2.60983,3.60149 0.09288,5.44706l-11.34023,8.28641c-2.51696,1.8381 -5.07106,1.86799 -7.65303,0.08219l-11.60957,-8.0473c-2.58197,-1.7858 -2.60983,-3.60149 -0.09288,-5.44706l11.34023,-8.28641z"
                  fill="url(#Dégradé_sans_nom_177-70)"
                  id="svg_75"
                />
              </g>
              <g stroke="null" id="shape_10_1-71">
                <path
                  stroke="null"
                  className="cls-72"
                  d="m994.73678,532.57412c0.44581,-0.32877 0.9009,-0.33624 1.356,-0.01494l2.06186,1.42714c0.4551,0.32129 0.46438,0.64259 0.01858,0.97136l-2.01542,1.47198c-0.44581,0.32877 -0.9009,0.33624 -1.356,0.01494l-2.06186,-1.42714c-0.4551,-0.32129 -0.46438,-0.64259 -0.01858,-0.97136l2.01542,-1.47198z"
                  fill="url(#Dégradé_sans_nom_177-71)"
                  id="svg_76"
                />
              </g>
              <g stroke="null" id="shape_10_1-72">
                <path
                  stroke="null"
                  className="cls-71"
                  d="m889.46117,532.91036c0.63156,-0.45579 1.26312,-0.46326 1.90397,-0.02242l2.88846,2.00249c0.64085,0.44832 0.65014,0.89664 0.01858,1.35243l-2.82345,2.06226c-0.63156,0.45579 -1.26312,0.46326 -1.90397,0.02242l-2.88846,-2.00249c-0.64085,-0.44832 -0.65014,-0.89664 -0.01858,-1.35243l2.82345,-2.06226z"
                  fill="url(#Dégradé_sans_nom_177-72)"
                  id="svg_77"
                />
              </g>
              <g stroke="null" id="shape_10_1-73">
                <path
                  stroke="null"
                  className="cls-74"
                  d="m784.19485,533.23912c0.80803,-0.59029 1.62534,-0.59776 2.45194,-0.02242l3.71506,2.57783c0.8266,0.57534 0.83589,1.15068 0.02786,1.74097l-3.63147,2.65255c-0.80803,0.59029 -1.62534,0.59776 -2.45194,0.02242l-3.71506,-2.57783c-0.8266,-0.57534 -0.83589,-1.15068 -0.02786,-1.74097l3.63147,-2.65255z"
                  fill="url(#Dégradé_sans_nom_177-73)"
                  id="svg_78"
                />
              </g>
              <g stroke="null" id="shape_10_1-74">
                <path
                  stroke="null"
                  className="cls-73"
                  d="m678.91925,533.58283c0.98449,-0.71731 1.98756,-0.73225 2.99063,-0.02989l4.54166,3.1457c1.01235,0.70236 1.02164,1.4122 0.03715,2.12951l-4.4395,3.24283c-0.98449,0.71731 -1.98756,0.73225 -2.99063,0.02989l-4.54166,-3.1457c-1.01235,-0.70236 -1.02164,-1.4122 -0.03715,-2.12951l4.4395,-3.24283z"
                  fill="url(#Dégradé_sans_nom_177-74)"
                  id="svg_79"
                />
              </g>
              <g stroke="null" id="shape_10_1-75">
                <path
                  stroke="null"
                  className="cls-86"
                  d="m573.64364,533.9116c1.16096,-0.8518 2.34049,-0.86675 3.5386,-0.03736l5.36827,3.72104c1.18882,0.82939 1.2074,1.66625 0.03715,2.51805l-5.24753,3.83312c-1.16096,0.8518 -2.34049,0.86675 -3.5386,0.03736l-5.36827,-3.72104c-1.18882,-0.82939 -1.2074,-1.66625 -0.03715,-2.51805l5.24753,-3.83312z"
                  fill="url(#Dégradé_sans_nom_177-75)"
                  id="svg_80"
                />
              </g>
              <g stroke="null" id="shape_10_1-76">
                <path
                  stroke="null"
                  className="cls-84"
                  d="m468.36803,534.24784c1.34671,-0.9863 2.70271,-0.99377 4.08657,-0.04483l6.19487,4.29638c1.37457,0.95641 1.39315,1.92029 0.04644,2.90659l-6.05555,4.4234c-1.34671,0.9863 -2.70271,0.99377 -4.08657,0.04483l-6.19487,-4.29638c-1.37457,-0.95641 -1.39315,-1.92029 -0.04644,-2.90659l6.05555,-4.4234z"
                  fill="url(#Dégradé_sans_nom_177-76)"
                  id="svg_81"
                />
              </g>
              <g stroke="null" id="shape_10_1-77">
                <path
                  stroke="null"
                  className="cls-56"
                  d="m363.10172,534.58408c1.52318,-1.11332 3.06493,-1.12827 4.62525,-0.0523l7.02147,4.86425c1.56033,1.08343 1.5789,2.18181 0.05573,3.29514l-6.85429,5.01369c-1.52318,1.11332 -3.06493,1.12827 -4.62525,0.0523l-7.02147,-4.86425c-1.56033,-1.08343 -1.5789,-2.18181 -0.05573,-3.29514l6.85429,-5.01369z"
                  fill="url(#Dégradé_sans_nom_177-77)"
                  id="svg_82"
                />
              </g>
              <g stroke="null" id="shape_10_1-78">
                <path
                  stroke="null"
                  className="cls-85"
                  d="m257.82611,534.92032c1.69964,-1.24782 3.42715,-1.26276 5.17323,-0.0523l7.84807,5.43959c1.74608,1.21046 1.76466,2.43586 0.05573,3.68368l-7.66232,5.60397c-1.69964,1.24782 -3.42715,1.26276 -5.17323,0.0523l-7.84807,-5.43959c-1.74608,-1.21046 -1.76466,-2.43586 -0.05573,-3.68368l7.66232,-5.60397z"
                  fill="url(#Dégradé_sans_nom_177-78)"
                  id="svg_83"
                />
              </g>
              <g stroke="null" id="shape_10_1-79">
                <path
                  stroke="null"
                  className="cls-57"
                  d="m152.5505,535.25655c1.88539,-1.37484 3.78936,-1.39726 5.71191,-0.05978l8.67467,6.00746c1.92255,1.33748 1.95041,2.68991 0.06501,4.06475l-8.47034,6.19426c-1.88539,1.37484 -3.78936,1.39726 -5.71191,0.05978l-8.67467,-6.00746c-1.92255,-1.33748 -1.95041,-2.68991 -0.06501,-4.06475l8.47034,-6.19426z"
                  fill="url(#Dégradé_sans_nom_177-79)"
                  id="svg_84"
                />
              </g>
              <g stroke="null" id="shape_10_1-80">
                <path
                  stroke="null"
                  className="cls-43"
                  d="m47.28418,535.59279c2.06186,-1.50934 4.15158,-1.53175 6.25988,-0.06725l9.50127,6.5828c2.1083,1.4645 2.13616,2.94395 0.0743,4.45329l-9.27837,6.77707c-2.06186,1.50934 -4.15158,1.53175 -6.25988,0.06725l-9.50127,-6.5828c-2.1083,-1.4645 -2.13616,-2.94395 -0.0743,-4.45329l9.27837,-6.77707z"
                  fill="url(#Dégradé_sans_nom_177-80)"
                  id="svg_85"
                />
              </g>
              <g stroke="null" id="shape_10_1-81">
                <path
                  stroke="null"
                  className="cls-41"
                  d="m996.11135,607.71218c0.35293,-0.25405 0.70586,-0.26152 1.05879,-0.00747l1.60676,1.11332c0.35293,0.24657 0.36222,0.50062 0.00929,0.75467l-1.56961,1.14321c-0.35293,0.25405 -0.70586,0.26152 -1.05879,0.00747l-1.60676,-1.11332c-0.35293,-0.24657 -0.36222,-0.50062 -0.00929,-0.75467l1.56961,-1.14321z"
                  fill="url(#Dégradé_sans_nom_177-81)"
                  id="svg_86"
                />
              </g>
              <g stroke="null" id="shape_10_1-82">
                <path
                  stroke="null"
                  className="cls-42"
                  d="m890.90076,608.22027c0.49225,-0.35865 0.98449,-0.35865 1.48603,-0.01494l2.24761,1.55417c0.50153,0.34371 0.50153,0.69489 0.01858,1.05355l-2.19189,1.60647c-0.49225,0.35865 -0.98449,0.35865 -1.48603,0.01494l-2.24761,-1.55417c-0.50153,-0.34371 -0.50153,-0.69489 -0.01858,-1.05355l2.19189,-1.60647z"
                  fill="url(#Dégradé_sans_nom_177-82)"
                  id="svg_87"
                />
              </g>
              <g stroke="null" id="shape_10_1-83">
                <path
                  stroke="null"
                  className="cls-35"
                  d="m785.69017,608.72837c0.63156,-0.45579 1.26312,-0.46326 1.90397,-0.02242l2.88846,2.00249c0.64085,0.44832 0.65014,0.89664 0.01858,1.35243l-2.82345,2.06226c-0.63156,0.45579 -1.26312,0.46326 -1.90397,0.02242l-2.88846,-2.00249c-0.64085,-0.44832 -0.65014,-0.89664 -0.01858,-1.35243l2.82345,-2.06226z"
                  fill="url(#Dégradé_sans_nom_177-83)"
                  id="svg_88"
                />
              </g>
              <g stroke="null" id="shape_10_1-84">
                <path
                  stroke="null"
                  className="cls-36"
                  d="m680.47957,609.23646c0.77088,-0.5604 1.54175,-0.56787 2.3312,-0.02242l3.52931,2.4508c0.78945,0.54545 0.78945,1.09838 0.02786,1.65878l-3.45501,2.52552c-0.77088,0.5604 -1.54175,0.56787 -2.3312,0.02242l-3.52931,-2.4508c-0.78945,-0.54545 -0.78945,-1.09838 -0.02786,-1.65878l3.45501,-2.52552z"
                  fill="url(#Dégradé_sans_nom_177-84)"
                  id="svg_89"
                />
              </g>
              <g stroke="null" id="shape_10_1-85">
                <path
                  stroke="null"
                  className="cls-76"
                  d="m575.27827,609.74455c0.91019,-0.665 1.82038,-0.67248 2.74915,-0.02989l4.17016,2.89165c0.92877,0.64259 0.93805,1.29265 0.02786,1.95765l-4.07728,2.98131c-0.91019,0.665 -1.82038,0.67248 -2.74915,0.02989l-4.17016,-2.89165c-0.92877,-0.64259 -0.93805,-1.29265 -0.02786,-1.95765l4.07728,-2.98131z"
                  fill="url(#Dégradé_sans_nom_177-85)"
                  id="svg_90"
                />
              </g>
              <g stroke="null" id="shape_10_1-86">
                <path
                  stroke="null"
                  className="cls-83"
                  d="m470.06768,610.25265c1.04951,-0.76214 2.1083,-0.77708 3.17638,-0.02989l4.82029,3.33997c1.06808,0.73972 1.08666,1.49439 0.03715,2.25653l-4.70884,3.4371c-1.04951,0.76214 -2.1083,0.77708 -3.17638,0.02989l-4.82029,-3.33997c-1.06808,-0.73972 -1.08666,-1.49439 -0.03715,-2.25653l4.70884,-3.4371z"
                  fill="url(#Dégradé_sans_nom_177-86)"
                  id="svg_91"
                />
              </g>
              <g stroke="null" id="shape_10_1-87">
                <path
                  stroke="null"
                  className="cls-78"
                  d="m364.85708,610.76074c1.18882,-0.86675 2.38693,-0.88169 3.59432,-0.03736l5.46114,3.78081c1.21668,0.84433 1.22597,1.69614 0.03715,2.56288l-5.33112,3.90036c-1.18882,0.86675 -2.38693,0.88169 -3.59432,0.03736l-5.46114,-3.78081c-1.21668,-0.84433 -1.22597,-1.69614 -0.03715,-2.56288l5.33112,-3.90036z"
                  fill="url(#Dégradé_sans_nom_177-87)"
                  id="svg_92"
                />
              </g>
              <g stroke="null" id="shape_10_1-88">
                <path
                  stroke="null"
                  className="cls-77"
                  d="m259.64649,611.26136c1.32814,-0.97136 2.66556,-0.97883 4.02156,-0.04483l6.10199,4.22913c1.356,0.94147 1.37457,1.89041 0.04644,2.86176l-5.96268,4.35615c-1.32814,0.97136 -2.66556,0.97883 -4.02156,0.04483l-6.10199,-4.22913c-1.356,-0.94147 -1.37457,-1.89041 -0.04644,-2.86176l5.96268,-4.35615z"
                  fill="url(#Dégradé_sans_nom_177-88)"
                  id="svg_93"
                />
              </g>
              <g stroke="null" id="shape_10_1-89">
                <path
                  stroke="null"
                  className="cls-75"
                  d="m154.4359,611.76946c1.46745,-1.06849 2.94419,-1.08343 4.44879,-0.04483l6.74284,4.66998c1.49531,1.0386 1.51389,2.09215 0.05573,3.16064l-6.58495,4.81194c-1.46745,1.06849 -2.94419,1.08343 -4.44879,0.04483l-6.74284,-4.66998c-1.49531,-1.0386 -1.51389,-2.09215 -0.05573,-3.16064l6.58495,-4.81194z"
                  fill="url(#Dégradé_sans_nom_177-89)"
                  id="svg_94"
                />
              </g>
              <g stroke="null" id="shape_10_1-90">
                <path
                  stroke="null"
                  className="cls-24"
                  d="m49.2253,612.27755c1.60676,-1.1731 3.22282,-1.18804 4.86673,-0.0523l7.38369,5.11829c1.64392,1.13574 1.66249,2.29389 0.05573,3.46699l-7.21651,5.27521c-1.60676,1.1731 -3.22282,1.18804 -4.86673,0.0523l-7.38369,-5.11829c-1.64392,-1.13574 -1.66249,-2.29389 -0.05573,-3.46699l7.21651,-5.27521z"
                  fill="url(#Dégradé_sans_nom_177-90)"
                  id="svg_95"
                />
              </g>
              <g stroke="null" id="shape_10_1-91">
                <path
                  stroke="null"
                  className="cls-32"
                  d="m997.48593,682.85771c0.25077,-0.17933 0.50153,-0.1868 0.7523,-0.00747l1.14238,0.79203c0.25077,0.17933 0.26005,0.35865 0,0.53798l-1.12381,0.82192c-0.25077,0.17933 -0.50153,0.1868 -0.7523,0.00747l-1.14238,-0.79203c-0.25077,-0.17933 -0.26005,-0.35865 0,-0.53798l1.12381,-0.82192z"
                  fill="url(#Dégradé_sans_nom_177-91)"
                  id="svg_96"
                />
              </g>
              <g stroke="null" id="shape_10_1-92">
                <path
                  stroke="null"
                  className="cls-34"
                  d="m892.34035,683.53766c0.35293,-0.25405 0.70586,-0.26152 1.05879,-0.00747l1.60676,1.11332c0.35293,0.24657 0.36222,0.50062 0.00929,0.75467l-1.56961,1.14321c-0.35293,0.25405 -0.70586,0.26152 -1.05879,0.00747l-1.60676,-1.11332c-0.35293,-0.24657 -0.36222,-0.50062 -0.00929,-0.75467l1.56961,-1.14321z"
                  fill="url(#Dégradé_sans_nom_177-92)"
                  id="svg_97"
                />
              </g>
              <g stroke="null" id="shape_10_1-93">
                <path
                  stroke="null"
                  className="cls-25"
                  d="m787.19477,684.21014c0.44581,-0.32877 0.9009,-0.33624 1.356,-0.01494l2.06186,1.42714c0.4551,0.32129 0.46438,0.64259 0.01858,0.97136l-2.01542,1.47198c-0.44581,0.32877 -0.9009,0.33624 -1.356,0.01494l-2.06186,-1.42714c-0.4551,-0.32129 -0.46438,-0.64259 -0.01858,-0.97136l2.01542,-1.47198z"
                  fill="url(#Dégradé_sans_nom_177-93)"
                  id="svg_98"
                />
              </g>
              <g stroke="null" id="shape_10_1-94">
                <path
                  stroke="null"
                  className="cls-33"
                  d="m682.04919,684.89009c0.54797,-0.40349 1.10523,-0.40349 1.66249,-0.01494l2.52624,1.74844c0.55726,0.38854 0.56655,0.78456 0.01858,1.18057l-2.46123,1.80074c-0.54797,0.40349 -1.10523,0.40349 -1.66249,0.01494l-2.52624,-1.74844c-0.55726,-0.38854 -0.56655,-0.78456 -0.01858,-1.18057l2.46123,-1.80074z"
                  fill="url(#Dégradé_sans_nom_177-94)"
                  id="svg_99"
                />
              </g>
              <g stroke="null" id="shape_10_1-95">
                <path
                  stroke="null"
                  className="cls-22"
                  d="m576.90361,685.57003c0.65014,-0.47073 1.30027,-0.47821 1.9597,-0.02242l2.98134,2.06973c0.65942,0.45579 0.66871,0.92652 0.01858,1.39726l-2.91632,2.12951c-0.65014,0.47073 -1.30027,0.47821 -1.9597,0.02242l-2.98134,-2.06973c-0.65942,-0.45579 -0.66871,-0.92652 -0.01858,-1.39726l2.91632,-2.12951z"
                  fill="url(#Dégradé_sans_nom_177-95)"
                  id="svg_100"
                />
              </g>
              <g stroke="null" id="shape_10_1-96">
                <path
                  stroke="null"
                  className="cls-23"
                  d="m471.75803,686.24998c0.74301,-0.54545 1.5046,-0.55293 2.26619,-0.02242l3.44572,2.38356c0.76159,0.53051 0.77088,1.06849 0.02786,1.61394l-3.36213,2.45828c-0.74301,0.54545 -1.5046,0.55293 -2.26619,0.02242l-3.44572,-2.38356c-0.76159,-0.53051 -0.77088,-1.06849 -0.02786,-1.61394l3.36213,-2.45828z"
                  fill="url(#Dégradé_sans_nom_177-96)"
                  id="svg_101"
                />
              </g>
              <g stroke="null" id="shape_10_1-97">
                <path
                  stroke="null"
                  className="cls-40"
                  d="m366.61245,686.92993c0.84518,-0.62017 1.69964,-0.62764 2.57268,-0.02989l3.90082,2.70485c0.86375,0.59776 0.87304,1.21046 0.02786,1.83063l-3.80794,2.78704c-0.84518,0.62017 -1.69964,0.62764 -2.57268,0.02989l-3.90082,-2.70485c-0.86375,-0.59776 -0.87304,-1.21046 -0.02786,-1.83063l3.80794,-2.78704z"
                  fill="url(#Dégradé_sans_nom_177-97)"
                  id="svg_102"
                />
              </g>
              <g stroke="null" id="shape_10_1-98">
                <path
                  stroke="null"
                  className="cls-21"
                  d="m261.46687,687.60988c0.94734,-0.69489 1.90397,-0.70236 2.86989,-0.02989l4.35591,3.01867c0.96592,0.67248 0.9752,1.35243 0.03715,2.04732l-4.25375,3.10834c-0.94734,0.69489 -1.90397,0.70236 -2.86989,0.02989l-4.35591,-3.01867c-0.96592,-0.67248 -0.9752,-1.35243 -0.03715,-2.04732l4.25375,-3.10834z"
                  fill="url(#Dégradé_sans_nom_177-98)"
                  id="svg_103"
                />
              </g>
              <g stroke="null" id="shape_10_1-99">
                <path
                  stroke="null"
                  className="cls-39"
                  d="m156.32129,688.28983c1.04951,-0.76214 2.1083,-0.77708 3.17638,-0.02989l4.82029,3.33997c1.06808,0.73972 1.08666,1.49439 0.03715,2.25653l-4.70884,3.4371c-1.04951,0.76214 -2.1083,0.77708 -3.17638,0.02989l-4.82029,-3.33997c-1.06808,-0.73972 -1.08666,-1.49439 -0.03715,-2.25653l4.70884,-3.4371z"
                  fill="url(#Dégradé_sans_nom_177-99)"
                  id="svg_104"
                />
              </g>
              <g stroke="null" id="shape_10_1-100">
                <path
                  stroke="null"
                  className="cls-189"
                  d="m51.17571,688.96231c1.14238,-0.83686 2.30334,-0.8518 3.47358,-0.03736l5.27539,3.65379c1.17024,0.81444 1.18882,1.63636 0.03715,2.47322l-5.15465,3.76587c-1.14238,0.83686 -2.30334,0.8518 -3.47358,0.03736l-5.27539,-3.65379c-1.17024,-0.81444 -1.18882,-1.63636 -0.03715,-2.47322l5.15465,-3.76587z"
                  fill="url(#Dégradé_sans_nom_177-100)"
                  id="svg_105"
                />
              </g>
              <g stroke="null" id="shape_other_10_1">
                <path
                  stroke="null"
                  className="cls-199"
                  d="m719.62705,-34.51051c2.65627,-1.94271 5.3404,-1.9726 8.06169,-0.08219l12.23185,8.48068c2.72128,1.88293 2.74915,3.79576 0.09288,5.73847l-11.95322,8.73472c-2.65627,1.94271 -5.3404,1.9726 -8.06169,0.08219l-12.23185,-8.48068c-2.72128,-1.88293 -2.74915,-3.79576 -0.09288,-5.73847l11.95322,-8.73472z"
                  fill="url(#Dégradé_sans_nom_177-101)"
                  id="svg_106"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-2">
                <path
                  stroke="null"
                  className="cls-198"
                  d="m613.79419,-35.69108c3.18567,-2.33125 6.41777,-2.36114 9.67774,-0.10461l14.68379,10.17681c3.25997,2.264 3.29712,4.5579 0.11145,6.88915l-14.34943,10.48316c-3.18567,2.33125 -6.40848,2.36114 -9.67774,0.10461l-14.68379,-10.17681c-3.25997,-2.264 -3.29712,-4.5579 -0.11145,-6.88915l14.34014,-10.48316l0.00929,0z"
                  fill="url(#Dégradé_sans_nom_177-102)"
                  id="svg_107"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-3">
                <path
                  stroke="null"
                  className="cls-201"
                  d="m507.96132,-36.87165c3.71506,-2.71979 7.48585,-2.75715 11.2845,-0.11955l17.12644,11.87295c3.80794,2.6376 3.85438,5.31257 0.13003,8.03236l-16.73636,12.2316c-3.71506,2.71979 -7.48585,2.75715 -11.2845,0.11955l-17.12644,-11.87295c-3.80794,-2.6376 -3.85438,-5.31257 -0.13003,-8.03236l16.73636,-12.2316z"
                  fill="url(#Dégradé_sans_nom_177-103)"
                  id="svg_108"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-4">
                <path
                  stroke="null"
                  className="cls-195"
                  d="m402.12846,-38.05222c4.25375,-3.10834 8.55393,-3.15317 12.90056,-0.1345l19.57838,13.56908c4.34662,3.01867 4.40235,6.07471 0.1486,9.18304l-19.12329,13.97257c-4.25375,3.10834 -8.55393,3.15317 -12.90056,0.1345l-19.57838,-13.56908c-4.34662,-3.01867 -4.40235,-6.07471 -0.1486,-9.18304l19.12329,-13.97257z"
                  fill="url(#Dégradé_sans_nom_177-104)"
                  id="svg_109"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-5">
                <path
                  stroke="null"
                  className="cls-194"
                  d="m296.29559,-39.22532c4.78314,-3.49688 9.62201,-3.54171 14.51661,-0.14944l22.02104,15.26522c4.8946,3.39227 4.95032,6.83685 0.16718,10.32625l-21.5195,15.72101c-4.78314,3.49688 -9.62201,3.54171 -14.51661,0.14944l-22.01175,-15.26522c-4.8946,-3.39227 -4.95032,-6.83685 -0.16718,-10.32625l21.5195,-15.72101l-0.00929,0z"
                  fill="url(#Dégradé_sans_nom_177-105)"
                  id="svg_110"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-6">
                <path
                  stroke="null"
                  className="cls-193"
                  d="m190.46272,-40.40589c5.31254,-3.88542 10.69009,-3.93772 16.12337,-0.17186l24.47298,16.96136c5.43328,3.76587 5.49829,7.59151 0.18575,11.47694l-23.90643,17.46945c-5.31254,3.88542 -10.69009,3.93772 -16.12337,0.17186l-24.47298,-16.96136c-5.43328,-3.76587 -5.49829,-7.59151 -0.18575,-11.47694l23.90643,-17.46945z"
                  fill="url(#Dégradé_sans_nom_177-106)"
                  id="svg_111"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-7">
                <path
                  stroke="null"
                  className="cls-200"
                  d="m84.63915,-41.58646c5.84194,-4.26649 11.75818,-4.33374 17.73943,-0.1868l26.91563,18.65749c5.98125,4.14694 6.04627,8.35365 0.20433,12.62015l-26.30265,19.21789c-5.84194,4.26649 -11.75818,4.33374 -17.73943,0.1868l-26.91563,-18.65749c-5.98125,-4.14694 -6.04627,-8.35365 -0.20433,-12.62015l26.30265,-19.21789z"
                  fill="url(#Dégradé_sans_nom_177-107)"
                  id="svg_112"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-8">
                <path
                  stroke="null"
                  className="cls-197"
                  d="m-21.19372,-42.76703c6.37133,-4.65503 12.82626,-4.72975 19.35548,-0.20174l29.35829,20.34616c6.52922,4.52054 6.60353,9.11579 0.2229,13.77083l-28.68958,20.96633c-6.37133,4.65503 -12.82626,4.72975 -19.35548,0.20174l-29.35829,-20.34616c-6.52922,-4.52054 -6.60353,-9.11579 -0.2229,-13.77083l28.68958,-20.96633z"
                  fill="url(#Dégradé_sans_nom_177-108)"
                  id="svg_113"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-9">
                <path
                  stroke="null"
                  className="cls-202"
                  d="m1038.48165,44.11696c0.9752,-0.70984 1.9597,-0.72478 2.95348,-0.02989l4.48594,3.10834c0.99378,0.68742 1.01235,1.38979 0.03715,2.10709l-4.38377,3.20547c-0.9752,0.70984 -1.9597,0.72478 -2.95348,0.02989l-4.48594,-3.10834c-0.99378,-0.68742 -1.01235,-1.38979 -0.03715,-2.10709l4.38377,-3.20547z"
                  fill="url(#Dégradé_sans_nom_177-109)"
                  id="svg_114"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-10">
                <path
                  stroke="null"
                  className="cls-196"
                  d="m932.7138,43.13066c1.45816,-1.06849 2.9349,-1.08343 4.43021,-0.04483l6.73355,4.6625c1.49531,1.0386 1.51389,2.08468 0.05573,3.15317l-6.57566,4.80447c-1.45816,1.06849 -2.9349,1.08343 -4.43021,0.04483l-6.73355,-4.6625c-1.49531,-1.0386 -1.51389,-2.08468 -0.05573,-3.15317l6.57566,-4.80447z"
                  fill="url(#Dégradé_sans_nom_177-110)"
                  id="svg_115"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-11">
                <path
                  stroke="null"
                  className="cls-174"
                  d="m826.95523,42.14436c1.95041,-1.41967 3.91939,-1.44209 5.91624,-0.05978l8.97188,6.21667c1.99685,1.38231 2.01542,2.78704 0.06501,4.20672l-8.76755,6.40347c-1.95041,1.41967 -3.91939,1.44209 -5.91624,0.05978l-8.97188,-6.21667c-1.99685,-1.38231 -2.01542,-2.78704 -0.06501,-4.20672l8.76755,-6.40347z"
                  fill="url(#Dégradé_sans_nom_177-111)"
                  id="svg_116"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-12">
                <path
                  stroke="null"
                  className="cls-173"
                  d="m721.19667,41.15059c2.43337,-1.77833 4.8946,-1.80822 7.39298,-0.07472l11.21949,7.77084c2.48909,1.72602 2.51696,3.48193 0.08359,5.26026l-10.95944,8.00994c-2.43337,1.77833 -4.8946,1.80822 -7.39298,0.07472l-11.21949,-7.77084c-2.48909,-1.72602 -2.51696,-3.48193 -0.08359,-5.26026l10.95944,-8.00994z"
                  fill="url(#Dégradé_sans_nom_177-112)"
                  id="svg_117"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-13">
                <path
                  stroke="null"
                  className="cls-172"
                  d="m615.4381,40.16429c2.92561,-2.13698 5.87909,-2.16687 8.86971,-0.09714l13.45782,9.32501c2.99063,2.06973 3.02778,4.17683 0.10216,6.31381l-13.15132,9.60894c-2.92561,2.13698 -5.87909,2.16687 -8.86971,0.09714l-13.45782,-9.32501c-2.99063,-2.06973 -3.02778,-4.17683 -0.10216,-6.31381l13.15132,-9.60894z"
                  fill="url(#Dégradé_sans_nom_177-113)"
                  id="svg_118"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-14">
                <path
                  stroke="null"
                  className="cls-204"
                  d="m509.67025,39.17799c3.40857,-2.48816 6.85429,-2.52552 10.34645,-0.11208l15.70543,10.87918c3.49216,2.42092 3.52931,4.87172 0.12074,7.36736l-15.34321,11.20794c-3.40857,2.48816 -6.85429,2.52552 -10.34645,0.11208l-15.70543,-10.87918c-3.49216,-2.42092 -3.52931,-4.87172 -0.12074,-7.36736l15.34321,-11.20794z"
                  fill="url(#Dégradé_sans_nom_177-114)"
                  id="svg_119"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-15">
                <path
                  stroke="null"
                  className="cls-205"
                  d="m403.91169,38.19169c3.90082,-2.84682 7.83878,-2.89165 11.82319,-0.12702l17.94376,12.43335c3.98441,2.76463 4.03084,5.56661 0.13931,8.41343l-17.5351,12.81442c-3.90082,2.84682 -7.83878,2.89165 -11.82319,0.12702l-17.94376,-12.43335c-3.98441,-2.76463 -4.03084,-5.56661 -0.13931,-8.41343l17.5351,-12.81442z"
                  fill="url(#Dégradé_sans_nom_177-115)"
                  id="svg_120"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-16">
                <path
                  stroke="null"
                  className="cls-171"
                  d="m298.15312,37.19792c4.38377,-3.20547 8.81399,-3.2503 13.29993,-0.14197l20.19137,13.98751c4.48594,3.10834 4.54166,6.2615 0.15789,9.46698l-19.72699,14.41342c-4.38377,3.20547 -8.81399,3.2503 -13.29993,0.14197l-20.19137,-13.98751c-4.48594,-3.10834 -4.54166,-6.2615 -0.15789,-9.46698l19.72699,-14.41342z"
                  fill="url(#Dégradé_sans_nom_177-116)"
                  id="svg_121"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-17">
                <path
                  stroke="null"
                  className="cls-203"
                  d="m192.38527,36.21162c4.86673,-3.55665 9.79848,-3.60896 14.78595,-0.15691l22.42969,15.54915c4.98747,3.45205 5.0432,6.96387 0.16718,10.52052l-21.91887,16.01242c-4.86673,3.55665 -9.79848,3.60896 -14.78595,0.15691l-22.42969,-15.54915c-4.98747,-3.45205 -5.0432,-6.96387 -0.16718,-10.52052l21.91887,-16.01242z"
                  fill="url(#Dégradé_sans_nom_177-117)"
                  id="svg_122"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-18">
                <path
                  stroke="null"
                  className="cls-206"
                  d="m86.6267,35.22532c5.35898,-3.91531 10.77368,-3.97508 16.26269,-0.17186l24.67731,17.10332c5.47972,3.80323 5.54473,7.65876 0.18575,11.57407l-24.11076,17.61142c-5.35898,3.91531 -10.77368,3.97508 -16.26269,0.17186l-24.67731,-17.10332c-5.47972,-3.80323 -5.54473,-7.65876 -0.18575,-11.57407l24.11076,-17.61142z"
                  fill="url(#Dégradé_sans_nom_177-118)"
                  id="svg_123"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-19">
                <path
                  stroke="null"
                  className="cls-175"
                  d="m-19.13186,34.23155c5.84194,-4.26649 11.75818,-4.33374 17.73943,-0.1868l26.91563,18.65749c5.98125,4.14694 6.04627,8.35365 0.20433,12.62015l-26.30265,19.21789c-5.84194,4.26649 -11.75818,4.33374 -17.73943,0.1868l-26.91563,-18.65749c-5.98125,-4.14694 -6.04627,-8.35365 -0.20433,-12.62015l26.30265,-19.21789z"
                  fill="url(#Dégradé_sans_nom_177-119)"
                  id="svg_124"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-20">
                <path
                  stroke="null"
                  className="cls-170"
                  d="m1039.83765,119.21018c0.88233,-0.65006 1.78323,-0.65753 2.68413,-0.02989l4.07728,2.8244c0.91019,0.62764 0.91948,1.26276 0.02786,1.91282l-3.98441,2.91407c-0.88233,0.65006 -1.78323,0.65753 -2.68413,0.02989l-4.07728,-2.8244c-0.91019,-0.62764 -0.91948,-1.26276 -0.02786,-1.91282l3.98441,-2.91407z"
                  fill="url(#Dégradé_sans_nom_177-120)"
                  id="svg_125"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-21">
                <path
                  stroke="null"
                  className="cls-215"
                  d="m934.1441,118.41816c1.32814,-0.97136 2.67485,-0.9863 4.03084,-0.04483l6.12057,4.2366c1.356,0.94147 1.37457,1.89788 0.04644,2.86923l-5.98125,4.3711c-1.32814,0.97136 -2.67485,0.9863 -4.03084,0.04483l-6.12057,-4.2366c-1.356,-0.94147 -1.37457,-1.89788 -0.04644,-2.86923l5.98125,-4.3711z"
                  fill="url(#Dégradé_sans_nom_177-121)"
                  id="svg_126"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-22">
                <path
                  stroke="null"
                  className="cls-212"
                  d="m828.45055,117.61866c1.77394,-1.29265 3.56646,-1.31507 5.37755,-0.05978l8.15456,5.65628c1.81109,1.25529 1.82967,2.533 0.06501,3.82565l-7.96881,5.82066c-1.77394,1.29265 -3.56646,1.31507 -5.37755,0.05978l-8.15456,-5.65628c-1.81109,-1.25529 -1.82967,-2.533 -0.06501,-3.82565l7.96881,-5.82066z"
                  fill="url(#Dégradé_sans_nom_177-122)"
                  id="svg_127"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-23">
                <path
                  stroke="null"
                  className="cls-211"
                  d="m722.76628,116.81916c2.21046,-1.61394 4.45808,-1.64383 6.72426,-0.06725l10.19785,7.06848c2.26619,1.56911 2.29405,3.16811 0.0743,4.78206l-9.96566,7.27769c-2.21046,1.61394 -4.45808,1.64383 -6.72426,0.06725l-10.19785,-7.06848c-2.26619,-1.56911 -2.29405,-3.16811 -0.0743,-4.78206l9.96566,-7.27769z"
                  fill="url(#Dégradé_sans_nom_177-123)"
                  id="svg_128"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-24">
                <path
                  stroke="null"
                  className="cls-207"
                  d="m617.07273,116.01966c2.65627,-1.94271 5.3404,-1.9726 8.06169,-0.08219l12.23185,8.48068c2.72128,1.88293 2.74915,3.79576 0.09288,5.73847l-11.95322,8.73472c-2.65627,1.94271 -5.3404,1.9726 -8.06169,0.08219l-12.23185,-8.48068c-2.72128,-1.88293 -2.74915,-3.79576 -0.09288,-5.73847l11.95322,-8.73472z"
                  fill="url(#Dégradé_sans_nom_177-124)"
                  id="svg_129"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-25">
                <path
                  stroke="null"
                  className="cls-213"
                  d="m511.37918,115.22016c3.10208,-2.264 6.23202,-2.30136 9.4084,-0.09714l14.27513,9.89288c3.17638,2.19676 3.20424,4.43087 0.11145,6.69488l-13.95006,10.19176c-3.10208,2.264 -6.23202,2.30136 -9.4084,0.09714l-14.27513,-9.89288c-3.16709,-2.19676 -3.20424,-4.43087 -0.11145,-6.69488l13.95006,-10.19176z"
                  fill="url(#Dégradé_sans_nom_177-125)"
                  id="svg_130"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-26">
                <path
                  stroke="null"
                  className="cls-209"
                  d="m405.69492,114.42813c3.5386,-2.5853 7.12363,-2.62266 10.75511,-0.11208l16.30913,11.30508c3.62219,2.51058 3.66863,5.06599 0.12074,7.65129l-15.93762,11.64879c-3.5386,2.5853 -7.12363,2.62266 -10.75511,0.11208l-16.30913,-11.30508c-3.62219,-2.51058 -3.66863,-5.06599 -0.12074,-7.65129l15.93762,-11.64879z"
                  fill="url(#Dégradé_sans_nom_177-126)"
                  id="svg_131"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-27">
                <path
                  stroke="null"
                  className="cls-208"
                  d="m300.00137,113.62863c3.98441,-2.91407 8.01525,-2.95143 12.09253,-0.12702l18.35241,12.71728c4.07728,2.8244 4.12372,5.69364 0.13931,8.6077l-17.93447,13.09835c-3.98441,2.91407 -8.01525,2.95143 -12.09253,0.12702l-18.35241,-12.71728c-4.07728,-2.8244 -4.12372,-5.69364 -0.13931,-8.6077l17.93447,-13.09835z"
                  fill="url(#Dégradé_sans_nom_177-127)"
                  id="svg_132"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-28">
                <path
                  stroke="null"
                  className="cls-210"
                  d="m194.30781,112.82913c4.43021,-3.23536 8.90686,-3.28019 13.43924,-0.14197l20.38641,14.12948c4.53238,3.13822 4.57882,6.32875 0.15789,9.56411l-19.92203,14.55538c-4.43021,3.23536 -8.90686,3.28019 -13.43924,0.14197l-20.38641,-14.12948c-4.53238,-3.13822 -4.57882,-6.32875 -0.15789,-9.56411l19.92203,-14.55538z"
                  fill="url(#Dégradé_sans_nom_177-128)"
                  id="svg_133"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-29">
                <path
                  stroke="null"
                  className="cls-214"
                  d="m88.61426,112.02963c4.86673,-3.55665 9.79848,-3.60896 14.78595,-0.15691l22.42969,15.54915c4.98747,3.45205 5.0432,6.96387 0.16718,10.52052l-21.91887,16.01242c-4.86673,3.55665 -9.79848,3.60896 -14.78595,0.15691l-22.42969,-15.54915c-4.98747,-3.45205 -5.0432,-6.96387 -0.16718,-10.52052l21.91887,-16.01242z"
                  fill="url(#Dégradé_sans_nom_177-129)"
                  id="svg_134"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-30">
                <path
                  stroke="null"
                  className="cls-216"
                  d="m-17.07,111.2376c5.31254,-3.88542 10.69009,-3.93772 16.12337,-0.17186l24.47298,16.96136c5.43328,3.76587 5.49829,7.59151 0.18575,11.47694l-23.90643,17.46945c-5.31254,3.88542 -10.69009,3.93772 -16.12337,0.17186l-24.47298,-16.96136c-5.43328,-3.76587 -5.49829,-7.59151 -0.18575,-11.47694l23.90643,-17.46945z"
                  fill="url(#Dégradé_sans_nom_177-130)"
                  id="svg_135"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-31">
                <path
                  stroke="null"
                  className="cls-218"
                  d="m1041.19365,194.30341c0.79874,-0.58281 1.60676,-0.59029 2.41479,-0.02242l3.66863,2.54047c0.81731,0.56787 0.8266,1.13574 0.02786,1.71855l-3.58504,2.62266c-0.79874,0.58281 -1.60676,0.59029 -2.41479,0.02242l-3.66863,-2.54047c-0.81731,-0.56787 -0.8266,-1.13574 -0.02786,-1.71855l3.58504,-2.62266z"
                  fill="url(#Dégradé_sans_nom_177-131)"
                  id="svg_136"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-32">
                <path
                  stroke="null"
                  className="cls-219"
                  d="m935.5744,193.69818c1.19811,-0.87422 2.4055,-0.88916 3.63147,-0.03736l5.50758,3.81817c1.22597,0.84433 1.23526,1.71108 0.04644,2.5853l-5.37755,3.93025c-1.19811,0.87422 -2.4055,0.88916 -3.63147,0.03736l-5.50758,-3.81817c-1.22597,-0.84433 -1.23526,-1.71108 -0.04644,-2.5853l5.37755,-3.93025z"
                  fill="url(#Dégradé_sans_nom_177-132)"
                  id="svg_137"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-33">
                <path
                  stroke="null"
                  className="cls-217"
                  d="m829.95515,193.09295c1.59748,-1.16563 3.20424,-1.18057 4.83887,-0.0523l7.33725,5.08841c1.63463,1.12827 1.6532,2.27895 0.05573,3.44457l-7.17007,5.23785c-1.59748,1.16563 -3.20424,1.18057 -4.83887,0.0523l-7.33725,-5.08841c-1.63463,-1.12827 -1.6532,-2.27895 -0.05573,-3.44457l7.17007,-5.23785z"
                  fill="url(#Dégradé_sans_nom_177-133)"
                  id="svg_138"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-34">
                <path
                  stroke="null"
                  className="cls-188"
                  d="m724.3359,192.48772c1.99685,-1.45703 4.01227,-1.47945 6.04627,-0.06725l9.17621,6.35864c2.04328,1.4122 2.06186,2.84682 0.0743,4.30385l-8.96259,6.55291c-1.99685,1.45703 -4.01227,1.47945 -6.04627,0.06725l-9.17621,-6.35864c-2.04328,-1.4122 -2.06186,-2.84682 -0.0743,-4.30385l8.96259,-6.55291z"
                  fill="url(#Dégradé_sans_nom_177-134)"
                  id="svg_139"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-35">
                <path
                  stroke="null"
                  className="cls-187"
                  d="m618.71665,191.87502c2.38693,-1.74844 4.81101,-1.77086 7.25366,-0.07472l11.01516,7.62887c2.44265,1.69614 2.47052,3.41469 0.08359,5.16313l-10.75511,7.86051c-2.38693,1.74844 -4.81101,1.77086 -7.25366,0.07472l-11.01516,-7.62887c-2.44265,-1.69614 -2.47052,-3.41469 -0.08359,-5.16313l10.75511,-7.86051z"
                  fill="url(#Dégradé_sans_nom_177-135)"
                  id="svg_140"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-36">
                <path
                  stroke="null"
                  className="cls-191"
                  d="m513.08811,191.26979c2.7863,-2.03985 5.60975,-2.06973 8.47034,-0.08966l12.84483,8.90658c2.85131,1.98007 2.88846,3.99003 0.10216,6.0224l-12.54763,9.1681c-2.7863,2.03985 -5.60975,2.06973 -8.47034,0.08966l-12.84483,-8.90658c-2.85131,-1.98007 -2.88846,-3.99003 -0.10216,-6.0224l12.54763,-9.1681z"
                  fill="url(#Dégradé_sans_nom_177-136)"
                  id="svg_141"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-37">
                <path
                  stroke="null"
                  className="cls-186"
                  d="m407.46886,190.66457c3.18567,-2.33125 6.41777,-2.36114 9.67774,-0.10461l14.68379,10.17681c3.25997,2.264 3.29712,4.5579 0.11145,6.88915l-14.34014,10.48316c-3.18567,2.33125 -6.41777,2.36114 -9.67774,0.10461l-14.68379,-10.17681c-3.25997,-2.264 -3.29712,-4.5579 -0.11145,-6.88915l14.34014,-10.48316z"
                  fill="url(#Dégradé_sans_nom_177-137)"
                  id="svg_142"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-38">
                <path
                  stroke="null"
                  className="cls-190"
                  d="m301.84961,190.05934c3.58504,-2.62266 7.21651,-2.66002 10.88514,-0.11208l16.51346,11.44705c3.66863,2.54047 3.71506,5.12577 0.13003,7.74843l-16.14195,11.79076c-3.58504,2.62266 -7.21651,2.66002 -10.88514,0.11208l-16.51346,-11.44705c-3.66863,-2.54047 -3.71506,-5.12577 -0.13003,-7.74843l16.14195,-11.79076z"
                  fill="url(#Dégradé_sans_nom_177-138)"
                  id="svg_143"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-39">
                <path
                  stroke="null"
                  className="cls-192"
                  d="m196.23036,189.44664c3.98441,-2.91407 8.01525,-2.95143 12.09253,-0.12702l18.35241,12.71728c4.07728,2.8244 4.12372,5.69364 0.13931,8.6077l-17.93447,13.09835c-3.98441,2.91407 -8.01525,2.95143 -12.09253,0.12702l-18.35241,-12.71728c-4.07728,-2.8244 -4.12372,-5.69364 -0.13931,-8.6077l17.93447,-13.09835z"
                  fill="url(#Dégradé_sans_nom_177-139)"
                  id="svg_144"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-40">
                <path
                  stroke="null"
                  className="cls-179"
                  d="m90.61111,188.84141c4.38377,-3.20547 8.81399,-3.2503 13.29993,-0.14197l20.19137,13.98751c4.48594,3.10834 4.54166,6.2615 0.15789,9.46698l-19.72699,14.41342c-4.38377,3.20547 -8.81399,3.2503 -13.29993,0.14197l-20.19137,-13.98751c-4.48594,-3.10834 -4.54166,-6.2615 -0.15789,-9.46698l19.72699,-14.41342z"
                  fill="url(#Dégradé_sans_nom_177-140)"
                  id="svg_145"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-41">
                <path
                  stroke="null"
                  className="cls-176"
                  d="m-15.00814,188.23618c4.78314,-3.49688 9.62201,-3.54171 14.51661,-0.14944l22.02104,15.26522c4.8946,3.39227 4.95032,6.83685 0.16718,10.32625l-21.5195,15.72101c-4.78314,3.49688 -9.62201,3.54171 -14.51661,0.14944l-22.02104,-15.26522c-4.8946,-3.39227 -4.95032,-6.83685 -0.16718,-10.32625l21.5195,-15.72101z"
                  fill="url(#Dégradé_sans_nom_177-141)"
                  id="svg_146"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-42">
                <path
                  stroke="null"
                  className="cls-181"
                  d="m1042.54964,269.40411c0.70586,-0.51557 1.42101,-0.52304 2.14545,-0.02242l3.25997,2.264c0.72444,0.50062 0.73373,1.01619 0.02786,1.53175l-3.18567,2.33125c-0.70586,0.51557 -1.42101,0.52304 -2.14545,0.02242l-3.25997,-2.264c-0.72444,-0.50062 -0.73373,-1.01619 -0.02786,-1.53175l3.18567,-2.33125z"
                  fill="url(#Dégradé_sans_nom_177-142)"
                  id="svg_147"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-43">
                <path
                  stroke="null"
                  className="cls-178"
                  d="m937.0047,268.98568c1.05879,-0.77708 2.13616,-0.78456 3.22282,-0.03736l4.8946,3.39227c1.08666,0.75467 1.09594,1.51681 0.03715,2.29389l-4.78314,3.49688c-1.05879,0.77708 -2.13616,0.78456 -3.22282,0.03736l-4.8946,-3.39227c-1.08666,-0.75467 -1.09594,-1.51681 -0.03715,-2.29389l4.78314,-3.49688z"
                  fill="url(#Dégradé_sans_nom_177-143)"
                  id="svg_148"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-44">
                <path
                  stroke="null"
                  className="cls-183"
                  d="m831.45046,268.56725c1.42101,-1.0386 2.85131,-1.05355 4.30019,-0.04483l6.52922,4.52054c1.44887,1.00124 1.46745,2.0249 0.04644,3.0635l-6.37133,4.6625c-1.42101,1.0386 -2.85131,1.05355 -4.30019,0.04483l-6.52922,-4.52054c-1.44887,-1.00124 -1.46745,-2.0249 -0.04644,-3.0635l6.37133,-4.6625z"
                  fill="url(#Dégradé_sans_nom_177-144)"
                  id="svg_149"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-45">
                <path
                  stroke="null"
                  className="cls-180"
                  d="m725.90551,268.14882c1.77394,-1.29265 3.56646,-1.31507 5.37755,-0.05978l8.15456,5.65628c1.81109,1.25529 1.82967,2.533 0.06501,3.82565l-7.96881,5.82066c-1.77394,1.29265 -3.56646,1.31507 -5.37755,0.05978l-8.15456,-5.65628c-1.81109,-1.25529 -1.82967,-2.533 -0.06501,-3.82565l7.96881,-5.82066z"
                  fill="url(#Dégradé_sans_nom_177-145)"
                  id="svg_150"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-46">
                <path
                  stroke="null"
                  className="cls-185"
                  d="m620.35127,267.73786c2.12687,-1.55417 4.27232,-1.57658 6.45492,-0.06725l9.78919,6.78454c2.17331,1.50934 2.20118,3.04109 0.0743,4.58779l-9.56629,6.98629c-2.12687,1.55417 -4.27232,1.57658 -6.45492,0.06725l-9.78919,-6.78454c-2.17331,-1.50934 -2.20118,-3.04109 -0.0743,-4.58779l9.56629,-6.98629z"
                  fill="url(#Dégradé_sans_nom_177-146)"
                  id="svg_151"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-47">
                <path
                  stroke="null"
                  className="cls-182"
                  d="m514.80633,267.31943c2.4798,-1.80822 4.98747,-1.8381 7.523,-0.08219l11.41453,7.91281c2.53553,1.75591 2.56339,3.54171 0.08359,5.3574l-11.15448,8.15191c-2.4798,1.80822 -4.98747,1.8381 -7.523,0.08219l-11.41453,-7.91281c-2.53553,-1.75591 -2.56339,-3.54171 -0.08359,-5.3574l11.15448,-8.15191z"
                  fill="url(#Dégradé_sans_nom_177-147)"
                  id="svg_152"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-48">
                <path
                  stroke="null"
                  className="cls-177"
                  d="m409.25209,266.901c2.83274,-2.06973 5.70262,-2.09962 8.60037,-0.08966l13.04916,9.04108c2.89775,2.00996 2.9349,4.0498 0.10216,6.11954l-12.75195,9.31754c-2.83274,2.06973 -5.70262,2.09962 -8.60037,0.08966l-13.04916,-9.04108c-2.89775,-2.00996 -2.9349,-4.0498 -0.10216,-6.11954l12.75195,-9.31754z"
                  fill="url(#Dégradé_sans_nom_177-148)"
                  id="svg_153"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-49">
                <path
                  stroke="null"
                  className="cls-184"
                  d="m303.69785,266.48257c3.18567,-2.33125 6.41777,-2.36114 9.67774,-0.10461l14.68379,10.17681c3.25997,2.264 3.29712,4.5579 0.11145,6.88915l-14.34014,10.48316c-3.18567,2.33125 -6.41777,2.36114 -9.67774,0.10461l-14.68379,-10.17681c-3.25997,-2.264 -3.29712,-4.5579 -0.11145,-6.88915l14.34014,-10.48316z"
                  fill="url(#Dégradé_sans_nom_177-149)"
                  id="svg_154"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-50">
                <path
                  stroke="null"
                  className="cls-125"
                  d="m198.15291,266.06414c3.5386,-2.5853 7.12363,-2.62266 10.75511,-0.11208l16.30913,11.30508c3.62219,2.51058 3.66863,5.06599 0.12074,7.65129l-15.93762,11.64879c-3.5386,2.5853 -7.12363,2.62266 -10.75511,0.11208l-16.30913,-11.30508c-3.62219,-2.51058 -3.66863,-5.06599 -0.12074,-7.65129l15.93762,-11.64879z"
                  fill="url(#Dégradé_sans_nom_177-150)"
                  id="svg_155"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-51">
                <path
                  stroke="null"
                  className="cls-124"
                  d="m92.59867,265.65319c3.90082,-2.84682 7.83878,-2.88418 11.82319,-0.12702l17.94376,12.43335c3.98441,2.76463 4.03084,5.56661 0.13931,8.41343l-17.5351,12.81442c-3.90082,2.84682 -7.83878,2.88418 -11.82319,0.12702l-17.94376,-12.43335c-3.98441,-2.76463 -4.03084,-5.56661 -0.13931,-8.41343l17.5351,-12.81442z"
                  fill="url(#Dégradé_sans_nom_177-151)"
                  id="svg_156"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-52">
                <path
                  stroke="null"
                  className="cls-123"
                  d="m-12.94628,265.23476c4.25375,-3.10834 8.55393,-3.15317 12.90056,-0.1345l19.57838,13.56908c4.34662,3.0112 4.40235,6.07471 0.1486,9.18304l-19.12329,13.97257c-4.25375,3.10834 -8.55393,3.15317 -12.90056,0.1345l-19.57838,-13.56908c-4.34662,-3.0112 -4.40235,-6.07471 -0.1486,-9.18304l19.12329,-13.97257z"
                  fill="url(#Dégradé_sans_nom_177-152)"
                  id="svg_157"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-53">
                <path
                  stroke="null"
                  className="cls-120"
                  d="m1043.91493,344.49734c0.62227,-0.45579 1.24455,-0.46326 1.88539,-0.02242l2.85131,1.98007c0.63156,0.44085 0.64085,0.88916 0.01858,1.33748l-2.7863,2.03985c-0.62227,0.45579 -1.24455,0.46326 -1.88539,0.02242l-2.85131,-1.98007c-0.63156,-0.44085 -0.64085,-0.88916 -0.01858,-1.33748l2.7863,-2.03985z"
                  fill="url(#Dégradé_sans_nom_177-153)"
                  id="svg_158"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-54">
                <path
                  stroke="null"
                  className="cls-129"
                  d="m938.43499,344.27318c0.92877,-0.67995 1.86682,-0.68742 2.82345,-0.02989l4.28161,2.96637c0.94734,0.65753 0.96592,1.33001 0.03715,2.00996l-4.18873,3.05603c-0.92877,0.67995 -1.86682,0.68742 -2.82345,0.02989l-4.28161,-2.96637c-0.94734,-0.65753 -0.96592,-1.33001 -0.03715,-2.00996l4.18873,-3.05603z"
                  fill="url(#Dégradé_sans_nom_177-154)"
                  id="svg_159"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-55">
                <path
                  stroke="null"
                  className="cls-128"
                  d="m832.95506,344.04155c1.23526,-0.90411 2.49838,-0.91905 3.7615,-0.03736l5.71191,3.96014c1.27241,0.88169 1.2817,1.77086 0.04644,2.67496l-5.58188,4.07969c-1.23526,0.90411 -2.49838,0.91905 -3.7615,0.03736l-5.71191,-3.96014c-1.27241,-0.88169 -1.2817,-1.77086 -0.04644,-2.67496l5.58188,-4.07969z"
                  fill="url(#Dégradé_sans_nom_177-155)"
                  id="svg_160"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-56">
                <path
                  stroke="null"
                  className="cls-127"
                  d="m727.47513,343.81739c1.55104,-1.13574 3.12065,-1.15068 4.69956,-0.0523l7.13292,4.94644c1.58819,1.09838 1.60676,2.2117 0.05573,3.34744l-6.97503,5.09588c-1.55104,1.13574 -3.12065,1.15068 -4.69956,0.0523l-7.13292,-4.94644c-1.58819,-1.09838 -1.60676,-2.2117 -0.05573,-3.34744l6.97503,-5.09588z"
                  fill="url(#Dégradé_sans_nom_177-156)"
                  id="svg_161"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-57">
                <path
                  stroke="null"
                  className="cls-126"
                  d="m621.99519,343.59323c1.85753,-1.3599 3.74293,-1.37484 5.6469,-0.05978l8.56322,5.93274c1.90397,1.31507 1.92255,2.66002 0.06501,4.01244l-8.36818,6.11207c-1.85753,1.3599 -3.74293,1.37484 -5.6469,0.05978l-8.56322,-5.93274c-1.90397,-1.31507 -1.92255,-2.66002 -0.06501,-4.01244l8.36818,-6.11207z"
                  fill="url(#Dégradé_sans_nom_177-157)"
                  id="svg_162"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-58">
                <path
                  stroke="null"
                  className="cls-122"
                  d="m516.51526,343.3616c2.17331,-1.58406 4.3652,-1.60647 6.58495,-0.06725l9.99352,6.92651c2.21975,1.53922 2.24761,3.10086 0.0743,4.68492l-9.76133,7.13572c-2.17331,1.58406 -4.3652,1.60647 -6.58495,0.06725l-9.99352,-6.92651c-2.21975,-1.53922 -2.24761,-3.10086 -0.0743,-4.68492l9.76133,-7.13572z"
                  fill="url(#Dégradé_sans_nom_177-158)"
                  id="svg_163"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-59">
                <path
                  stroke="null"
                  className="cls-121"
                  d="m411.03532,343.13744c2.4798,-1.80822 4.98747,-1.8381 7.523,-0.08219l11.41453,7.91281c2.53553,1.75591 2.56339,3.54171 0.08359,5.3574l-11.15448,8.15191c-2.4798,1.80822 -4.98747,1.8381 -7.523,0.08219l-11.41453,-7.91281c-2.53553,-1.75591 -2.56339,-3.54171 -0.08359,-5.3574l11.15448,-8.15191z"
                  fill="url(#Dégradé_sans_nom_177-159)"
                  id="svg_164"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-60">
                <path
                  stroke="null"
                  className="cls-164"
                  d="m305.55539,342.91328c2.7863,-2.03985 5.60975,-2.06973 8.47034,-0.08966l12.84483,8.90658c2.85131,1.98007 2.88846,3.99003 0.10216,6.0224l-12.54763,9.1681c-2.7863,2.03985 -5.60975,2.06973 -8.47034,0.08966l-12.84483,-8.90658c-2.85131,-1.98007 -2.88846,-3.99003 -0.10216,-6.0224l12.54763,-9.1681z"
                  fill="url(#Dégradé_sans_nom_177-160)"
                  id="svg_165"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-61">
                <path
                  stroke="null"
                  className="cls-165"
                  d="m200.07545,342.68912c3.10208,-2.264 6.23202,-2.29389 9.4084,-0.09714l14.27513,9.89288c3.17638,2.19676 3.20424,4.43087 0.11145,6.69488l-13.95006,10.19176c-3.10208,2.264 -6.23202,2.29389 -9.4084,0.09714l-14.27513,-9.89288c-3.16709,-2.19676 -3.20424,-4.43087 -0.11145,-6.69488l13.95006,-10.19176z"
                  fill="url(#Dégradé_sans_nom_177-161)"
                  id="svg_166"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-62">
                <path
                  stroke="null"
                  className="cls-162"
                  d="m94.59552,342.45749c3.40857,-2.48816 6.85429,-2.52552 10.34645,-0.11208l15.70543,10.87918c3.49216,2.42092 3.52931,4.87172 0.12074,7.35988l-15.34321,11.20794c-3.40857,2.48816 -6.85429,2.52552 -10.34645,0.11208l-15.70543,-10.87918c-3.49216,-2.42092 -3.52931,-4.87172 -0.12074,-7.35988l15.34321,-11.20794z"
                  fill="url(#Dégradé_sans_nom_177-162)"
                  id="svg_167"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-63">
                <path
                  stroke="null"
                  className="cls-163"
                  d="m-10.88442,342.23333c3.71506,-2.71979 7.48585,-2.75715 11.2845,-0.11955l17.12644,11.87295c3.80794,2.6376 3.85438,5.31257 0.13003,8.03236l-16.73636,12.2316c-3.71506,2.71979 -7.48585,2.75715 -11.2845,0.11955l-17.12644,-11.87295c-3.80794,-2.6376 -3.85438,-5.31257 -0.13003,-8.03236l16.73636,-12.2316z"
                  fill="url(#Dégradé_sans_nom_177-163)"
                  id="svg_168"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-64">
                <path
                  stroke="null"
                  className="cls-168"
                  d="m1045.27093,419.59057c0.5294,-0.38854 1.06808,-0.39601 1.61605,-0.01494l2.44265,1.69614c0.54797,0.3736 0.54797,0.76214 0.01858,1.15068l-2.38693,1.74844c-0.5294,0.38854 -1.06808,0.39601 -1.61605,0.01494l-2.44265,-1.69614c-0.54797,-0.3736 -0.54797,-0.76214 -0.01858,-1.15068l2.38693,-1.74844z"
                  fill="url(#Dégradé_sans_nom_177-164)"
                  id="svg_169"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-65">
                <path
                  stroke="null"
                  className="cls-169"
                  d="m939.86529,419.55321c0.79874,-0.58281 1.60676,-0.59029 2.41479,-0.02242l3.66863,2.54047c0.81731,0.56787 0.8266,1.13574 0.02786,1.71855l-3.58504,2.62266c-0.79874,0.58281 -1.60676,0.59029 -2.41479,0.02242l-3.66863,-2.54047c-0.81731,-0.56787 -0.8266,-1.13574 -0.02786,-1.71855l3.58504,-2.62266z"
                  fill="url(#Dégradé_sans_nom_177-165)"
                  id="svg_170"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-66">
                <path
                  stroke="null"
                  className="cls-166"
                  d="m834.45037,419.51585c1.05879,-0.77708 2.13616,-0.78456 3.22282,-0.03736l4.8946,3.39227c1.08666,0.75467 1.09594,1.51681 0.03715,2.29389l-4.78314,3.49688c-1.05879,0.77708 -2.13616,0.78456 -3.22282,0.03736l-4.8946,-3.39227c-1.08666,-0.75467 -1.09594,-1.51681 -0.03715,-2.29389l4.78314,-3.49688z"
                  fill="url(#Dégradé_sans_nom_177-166)"
                  id="svg_171"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-67">
                <path
                  stroke="null"
                  className="cls-167"
                  d="m729.04474,419.48596c1.32814,-0.97136 2.67485,-0.9863 4.03084,-0.04483l6.12057,4.2366c1.356,0.94147 1.37457,1.89788 0.04644,2.86923l-5.98125,4.36363c-1.32814,0.97136 -2.67485,0.9863 -4.03084,0.04483l-6.12057,-4.2366c-1.356,-0.94147 -1.37457,-1.89788 -0.04644,-2.86923l5.98125,-4.36363z"
                  fill="url(#Dégradé_sans_nom_177-167)"
                  id="svg_172"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-68">
                <path
                  stroke="null"
                  className="cls-160"
                  d="m623.62982,419.4486c1.59748,-1.16563 3.20424,-1.18057 4.83887,-0.0523l7.33725,5.08841c1.63463,1.12827 1.6532,2.27895 0.05573,3.44457l-7.17007,5.23785c-1.59748,1.16563 -3.20424,1.18057 -4.83887,0.0523l-7.33725,-5.08841c-1.63463,-1.12827 -1.6532,-2.27895 -0.05573,-3.44457l7.17007,-5.23785z"
                  fill="url(#Dégradé_sans_nom_177-168)"
                  id="svg_173"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-69">
                <path
                  stroke="null"
                  className="cls-161"
                  d="m518.22418,419.41124c1.85753,-1.3599 3.74293,-1.37484 5.6469,-0.05978l8.56322,5.93274c1.90397,1.31507 1.92255,2.66002 0.06501,4.01244l-8.36818,6.11207c-1.85753,1.3599 -3.74293,1.37484 -5.6469,0.05978l-8.56322,-5.93274c-1.90397,-1.31507 -1.92255,-2.66002 -0.06501,-4.01244l8.36818,-6.11207z"
                  fill="url(#Dégradé_sans_nom_177-169)"
                  id="svg_174"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-70">
                <path
                  stroke="null"
                  className="cls-157"
                  d="m412.80926,419.37388c2.12687,-1.55417 4.27232,-1.57658 6.45492,-0.06725l9.78919,6.78454c2.17331,1.50934 2.20118,3.04109 0.0743,4.58779l-9.56629,6.98629c-2.12687,1.55417 -4.27232,1.57658 -6.45492,0.06725l-9.78919,-6.78454c-2.17331,-1.50934 -2.20118,-3.04109 -0.0743,-4.58779l9.56629,-6.98629z"
                  fill="url(#Dégradé_sans_nom_177-170)"
                  id="svg_175"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-71">
                <path
                  stroke="null"
                  className="cls-158"
                  d="m307.40363,419.34399c2.38693,-1.74844 4.81101,-1.77086 7.25366,-0.07472l11.01516,7.62887c2.44265,1.69614 2.47052,3.41469 0.08359,5.16313l-10.75511,7.86051c-2.38693,1.74844 -4.81101,1.77086 -7.25366,0.07472l-11.01516,-7.62887c-2.44265,-1.69614 -2.47052,-3.41469 -0.08359,-5.16313l10.75511,-7.86051z"
                  fill="url(#Dégradé_sans_nom_177-171)"
                  id="svg_176"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-72">
                <path
                  stroke="null"
                  className="cls-159"
                  d="m201.998,419.30663c2.65627,-1.94271 5.3404,-1.9726 8.06169,-0.08219l12.23185,8.48068c2.72128,1.88293 2.74915,3.79576 0.09288,5.73847l-11.95322,8.73472c-2.65627,1.94271 -5.3404,1.9726 -8.06169,0.08219l-12.23185,-8.48068c-2.72128,-1.88293 -2.74915,-3.79576 -0.09288,-5.73847l11.95322,-8.73472z"
                  fill="url(#Dégradé_sans_nom_177-172)"
                  id="svg_177"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-73">
                <path
                  stroke="null"
                  className="cls-152"
                  d="m96.58307,419.26927c2.92561,-2.13698 5.87909,-2.16687 8.86971,-0.09714l13.45782,9.32501c2.99063,2.06973 3.02778,4.17683 0.10216,6.31381l-13.15132,9.60894c-2.92561,2.13698 -5.87909,2.16687 -8.86971,0.09714l-13.45782,-9.32501c-2.99063,-2.06973 -3.02778,-4.17683 -0.10216,-6.31381l13.15132,-9.60894z"
                  fill="url(#Dégradé_sans_nom_177-173)"
                  id="svg_178"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-74">
                <path
                  stroke="null"
                  className="cls-150"
                  d="m-8.82256,419.23191c3.18567,-2.33125 6.41777,-2.36114 9.67774,-0.10461l14.68379,10.17681c3.25997,2.264 3.29712,4.5579 0.11145,6.88915l-14.34014,10.48316c-3.18567,2.33125 -6.41777,2.36114 -9.67774,0.10461l-14.68379,-10.17681c-3.25997,-2.264 -3.29712,-4.5579 -0.11145,-6.88915l14.34014,-10.48316z"
                  fill="url(#Dégradé_sans_nom_177-174)"
                  id="svg_179"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-75">
                <path
                  stroke="null"
                  className="cls-151"
                  d="m1046.62693,494.6838c0.44581,-0.32129 0.89162,-0.32877 1.34671,-0.01494l2.04328,1.4122c0.4551,0.31382 0.4551,0.63512 0.01858,0.95641l-1.99685,1.45703c-0.44581,0.32129 -0.89162,0.32877 -1.34671,0.01494l-2.04328,-1.4122c-0.4551,-0.31382 -0.4551,-0.63512 -0.01858,-0.95641l1.99685,-1.45703z"
                  fill="url(#Dégradé_sans_nom_177-175)"
                  id="svg_180"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-76">
                <path
                  stroke="null"
                  className="cls-153"
                  d="m941.28631,494.84071c0.66871,-0.48568 1.33742,-0.49315 2.01542,-0.02242l3.05564,2.12204c0.678,0.47073 0.68729,0.94894 0.02786,1.43462l-2.99063,2.18181c-0.66871,0.48568 -1.33742,0.49315 -2.01542,0.02242l-3.05564,-2.12204c-0.678,-0.47073 -0.68729,-0.94894 -0.02786,-1.43462l2.99063,-2.18181z"
                  fill="url(#Dégradé_sans_nom_177-176)"
                  id="svg_181"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-77">
                <path
                  stroke="null"
                  className="cls-155"
                  d="m835.95497,494.99762c0.88233,-0.65006 1.78323,-0.65753 2.68413,-0.02989l4.07728,2.8244c0.91019,0.62764 0.91948,1.26276 0.02786,1.91282l-3.98441,2.91407c-0.88233,0.65006 -1.78323,0.65753 -2.68413,0.02989l-4.07728,-2.8244c-0.91019,-0.62764 -0.91948,-1.26276 -0.02786,-1.91282l3.98441,-2.91407z"
                  fill="url(#Dégradé_sans_nom_177-177)"
                  id="svg_182"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-78">
                <path
                  stroke="null"
                  className="cls-154"
                  d="m730.61435,495.14706c1.10523,-0.80697 2.22904,-0.82192 3.36213,-0.03736l5.09892,3.53424c1.13309,0.78456 1.14238,1.58406 0.03715,2.39103l-4.97818,3.63885c-1.10523,0.80697 -2.22904,0.82192 -3.36213,0.03736l-5.09892,-3.53424c-1.13309,-0.78456 -1.14238,-1.58406 -0.03715,-2.39103l4.97818,-3.63885z"
                  fill="url(#Dégradé_sans_nom_177-178)"
                  id="svg_183"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-79">
                <path
                  stroke="null"
                  className="cls-156"
                  d="m625.27373,495.30397c1.32814,-0.97136 2.67485,-0.9863 4.03084,-0.04483l6.12057,4.2366c1.356,0.94147 1.37457,1.89788 0.04644,2.86923l-5.98125,4.36363c-1.32814,0.97136 -2.67485,0.9863 -4.03084,0.04483l-6.12057,-4.2366c-1.356,-0.94147 -1.37457,-1.89788 -0.04644,-2.86923l5.98125,-4.36363z"
                  fill="url(#Dégradé_sans_nom_177-179)"
                  id="svg_184"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-80">
                <path
                  stroke="null"
                  className="cls-131"
                  d="m519.93311,495.46088c1.55104,-1.13574 3.12065,-1.15068 4.69956,-0.0523l7.13292,4.94644c1.58819,1.09838 1.60676,2.2117 0.05573,3.34744l-6.97503,5.09588c-1.55104,1.13574 -3.12065,1.15068 -4.69956,0.0523l-7.13292,-4.94644c-1.58819,-1.09838 -1.60676,-2.2117 -0.05573,-3.34744l6.97503,-5.09588z"
                  fill="url(#Dégradé_sans_nom_177-180)"
                  id="svg_185"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-81">
                <path
                  stroke="null"
                  className="cls-148"
                  d="m414.59249,495.61032c1.77394,-1.29265 3.56646,-1.31507 5.37755,-0.05978l8.15456,5.65628c1.81109,1.25529 1.82967,2.533 0.06501,3.82565l-7.96881,5.82066c-1.77394,1.29265 -3.56646,1.31507 -5.37755,0.05978l-8.15456,-5.65628c-1.81109,-1.25529 -1.82967,-2.533 -0.06501,-3.82565l7.96881,-5.82066z"
                  fill="url(#Dégradé_sans_nom_177-181)"
                  id="svg_186"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-82">
                <path
                  stroke="null"
                  className="cls-130"
                  d="m309.25187,495.76723c1.99685,-1.45703 4.01227,-1.47945 6.04627,-0.06725l9.17621,6.35864c2.04328,1.4122 2.06186,2.84682 0.0743,4.30385l-8.96259,6.55291c-1.99685,1.45703 -4.01227,1.47945 -6.04627,0.06725l-9.17621,-6.35864c-2.04328,-1.4122 -2.06186,-2.84682 -0.0743,-4.30385l8.96259,-6.55291z"
                  fill="url(#Dégradé_sans_nom_177-182)"
                  id="svg_187"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-83">
                <path
                  stroke="null"
                  className="cls-143"
                  d="m203.91125,495.92414c2.21046,-1.62142 4.44879,-1.64383 6.72426,-0.06725l10.19785,7.06848c2.26619,1.56911 2.29405,3.16064 0.0743,4.78206l-9.96566,7.27769c-2.21046,1.62142 -4.44879,1.64383 -6.72426,0.06725l-10.19785,-7.06848c-2.26619,-1.56911 -2.29405,-3.16064 -0.0743,-4.78206l9.96566,-7.27769z"
                  fill="url(#Dégradé_sans_nom_177-183)"
                  id="svg_188"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-84">
                <path
                  stroke="null"
                  className="cls-149"
                  d="m98.57992,496.08105c2.43337,-1.77833 4.8946,-1.80822 7.39298,-0.07472l11.21949,7.77084c2.48909,1.72602 2.51696,3.48193 0.08359,5.26026l-10.95944,8.00994c-2.43337,1.77833 -4.8946,1.80822 -7.39298,0.07472l-11.21949,-7.77084c-2.48909,-1.72602 -2.51696,-3.48193 -0.08359,-5.26026l10.95944,-8.00994z"
                  fill="url(#Dégradé_sans_nom_177-184)"
                  id="svg_189"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-85">
                <path
                  stroke="null"
                  className="cls-142"
                  d="m-6.7607,496.23049c2.65627,-1.94271 5.3404,-1.9726 8.06169,-0.08219l12.23185,8.48068c2.72128,1.88293 2.74915,3.79576 0.09288,5.73847l-11.95322,8.73472c-2.65627,1.94271 -5.3404,1.9726 -8.06169,0.08219l-12.23185,-8.48068c-2.72128,-1.88293 -2.74915,-3.79576 -0.09288,-5.73847l11.95322,-8.73472z"
                  fill="url(#Dégradé_sans_nom_177-185)"
                  id="svg_190"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-86">
                <path
                  stroke="null"
                  className="cls-147"
                  d="m1047.99221,569.77702c0.35293,-0.26152 0.71515,-0.26152 1.07737,-0.01494l1.63463,1.12827c0.36222,0.25405 0.36222,0.50809 0.00929,0.76214l-1.59748,1.16563c-0.35293,0.26152 -0.71515,0.26152 -1.07737,0.01494l-1.63463,-1.12827c-0.36222,-0.25405 -0.36222,-0.50809 -0.00929,-0.76214l1.59748,-1.16563z"
                  fill="url(#Dégradé_sans_nom_177-186)"
                  id="svg_191"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-87">
                <path
                  stroke="null"
                  className="cls-145"
                  d="m942.71661,570.12073c0.5294,-0.38854 1.06808,-0.39601 1.61605,-0.01494l2.44265,1.69614c0.54797,0.3736 0.54797,0.76214 0.01858,1.15068l-2.38693,1.74844c-0.5294,0.38854 -1.06808,0.39601 -1.61605,0.01494l-2.44265,-1.69614c-0.54797,-0.3736 -0.54797,-0.76214 -0.01858,-1.15068l2.38693,-1.74844z"
                  fill="url(#Dégradé_sans_nom_177-187)"
                  id="svg_192"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-88">
                <path
                  stroke="null"
                  className="cls-144"
                  d="m837.45029,570.47192c0.70586,-0.51557 1.42101,-0.52304 2.14545,-0.02242l3.25997,2.264c0.72444,0.50062 0.73373,1.01619 0.02786,1.53175l-3.18567,2.33125c-0.70586,0.51557 -1.42101,0.52304 -2.14545,0.02242l-3.25997,-2.264c-0.72444,-0.50062 -0.73373,-1.01619 -0.02786,-1.53175l3.18567,-2.33125z"
                  fill="url(#Dégradé_sans_nom_177-188)"
                  id="svg_193"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-89">
                <path
                  stroke="null"
                  className="cls-146"
                  d="m732.18397,570.81563c0.88233,-0.65006 1.78323,-0.65753 2.68413,-0.02989l4.07728,2.8244c0.91019,0.62764 0.91948,1.26276 0.02786,1.91282l-3.98441,2.91407c-0.88233,0.65006 -1.78323,0.65753 -2.68413,0.02989l-4.07728,-2.8244c-0.91019,-0.62764 -0.91948,-1.26276 -0.02786,-1.91282l3.98441,-2.91407z"
                  fill="url(#Dégradé_sans_nom_177-189)"
                  id="svg_194"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-90">
                <path
                  stroke="null"
                  className="cls-135"
                  d="m626.90836,571.15934c1.05879,-0.77708 2.13616,-0.78456 3.22282,-0.03736l4.8946,3.39227c1.08666,0.75467 1.09594,1.51681 0.03715,2.29389l-4.78314,3.49688c-1.05879,0.77708 -2.13616,0.78456 -3.22282,0.03736l-4.8946,-3.39227c-1.08666,-0.75467 -1.09594,-1.51681 -0.03715,-2.29389l4.78314,-3.49688z"
                  fill="url(#Dégradé_sans_nom_177-190)"
                  id="svg_195"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-91">
                <path
                  stroke="null"
                  className="cls-140"
                  d="m521.64204,571.50305c1.23526,-0.90411 2.49838,-0.91905 3.7615,-0.03736l5.71191,3.96014c1.27241,0.88169 1.2817,1.77086 0.04644,2.67496l-5.58188,4.07969c-1.23526,0.90411 -2.49838,0.91905 -3.7615,0.03736l-5.71191,-3.96014c-1.27241,-0.88169 -1.2817,-1.77086 -0.04644,-2.67496l5.58188,-4.07969z"
                  fill="url(#Dégradé_sans_nom_177-191)"
                  id="svg_196"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-92">
                <path
                  stroke="null"
                  className="cls-132"
                  d="m416.37572,571.85423c1.42101,-1.0386 2.85131,-1.05355 4.30019,-0.04483l6.52922,4.52054c1.44887,1.00124 1.46745,2.0249 0.04644,3.0635l-6.38062,4.6625c-1.42101,1.0386 -2.85131,1.05355 -4.30019,0.04483l-6.52922,-4.52054c-1.44887,-1.00124 -1.46745,-2.0249 -0.04644,-3.0635l6.38062,-4.6625z"
                  fill="url(#Dégradé_sans_nom_177-192)"
                  id="svg_197"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-93">
                <path
                  stroke="null"
                  className="cls-137"
                  d="m311.1094,572.19794c1.59748,-1.16563 3.20424,-1.18057 4.83887,-0.0523l7.33725,5.08841c1.63463,1.12827 1.6532,2.27895 0.05573,3.44457l-7.17007,5.23785c-1.59748,1.16563 -3.20424,1.18057 -4.83887,0.0523l-7.33725,-5.08841c-1.63463,-1.12827 -1.6532,-2.27895 -0.05573,-3.44457l7.17007,-5.23785z"
                  fill="url(#Dégradé_sans_nom_177-193)"
                  id="svg_198"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-94">
                <path
                  stroke="null"
                  className="cls-141"
                  d="m205.8338,572.54165c1.77394,-1.29265 3.56646,-1.31507 5.37755,-0.05978l8.15456,5.65628c1.81109,1.25529 1.82967,2.533 0.06501,3.82565l-7.96881,5.82066c-1.77394,1.29265 -3.56646,1.31507 -5.37755,0.05978l-8.15456,-5.65628c-1.81109,-1.25529 -1.82967,-2.533 -0.06501,-3.82565l7.96881,-5.82066z"
                  fill="url(#Dégradé_sans_nom_177-194)"
                  id="svg_199"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-95">
                <path
                  stroke="null"
                  className="cls-138"
                  d="m100.56748,572.88536c1.95041,-1.42714 3.91939,-1.44209 5.91624,-0.05978l8.97188,6.21667c1.99685,1.38231 2.01542,2.78704 0.06501,4.20672l-8.76755,6.40347c-1.95041,1.42714 -3.91939,1.44209 -5.91624,0.05978l-8.97188,-6.21667c-1.99685,-1.38231 -2.01542,-2.78704 -0.06501,-4.20672l8.76755,-6.40347z"
                  fill="url(#Dégradé_sans_nom_177-195)"
                  id="svg_200"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-96">
                <path
                  stroke="null"
                  className="cls-136"
                  d="m-4.69884,573.23654c2.12687,-1.55417 4.27232,-1.57658 6.45492,-0.06725l9.78919,6.78454c2.17331,1.50934 2.20118,3.04109 0.0743,4.58779l-9.56629,6.98629c-2.12687,1.55417 -4.27232,1.57658 -6.45492,0.06725l-9.78919,-6.78454c-2.17331,-1.50934 -2.20118,-3.04109 -0.0743,-4.58779l9.56629,-6.98629z"
                  fill="url(#Dégradé_sans_nom_177-196)"
                  id="svg_201"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-97">
                <path
                  stroke="null"
                  className="cls-134"
                  d="m1049.34821,644.87025c0.26934,-0.19427 0.53868,-0.19427 0.80803,-0.00747l1.22597,0.8518c0.26934,0.1868 0.27863,0.38107 0.00929,0.57534l-1.19811,0.87422c-0.26934,0.19427 -0.53868,0.19427 -0.80803,0.00747l-1.22597,-0.8518c-0.26934,-0.1868 -0.27863,-0.38107 -0.00929,-0.57534l1.19811,-0.87422z"
                  fill="url(#Dégradé_sans_nom_177-197)"
                  id="svg_202"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-98">
                <path
                  stroke="null"
                  className="cls-139"
                  d="m944.1469,645.40823c0.39937,-0.29141 0.79874,-0.29888 1.2074,-0.01494l1.83896,1.27023c0.40866,0.28393 0.40866,0.56787 0.01858,0.85928l-1.79252,1.30759c-0.39937,0.29141 -0.79874,0.29888 -1.2074,0.01494l-1.83896,-1.27023c-0.40866,-0.28393 -0.40866,-0.56787 -0.01858,-0.85928l1.79252,-1.30759z"
                  fill="url(#Dégradé_sans_nom_177-198)"
                  id="svg_203"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-99">
                <path
                  stroke="null"
                  className="cls-133"
                  d="m838.9456,645.94621c0.5294,-0.38854 1.06808,-0.39601 1.61605,-0.01494l2.44265,1.69614c0.54797,0.3736 0.54797,0.76214 0.01858,1.15068l-2.38693,1.74844c-0.5294,0.38854 -1.06808,0.39601 -1.61605,0.01494l-2.44265,-1.69614c-0.54797,-0.3736 -0.54797,-0.76214 -0.01858,-1.15068l2.38693,-1.74844z"
                  fill="url(#Dégradé_sans_nom_177-199)"
                  id="svg_204"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-100">
                <path
                  stroke="null"
                  className="cls-110"
                  d="m733.75358,646.4842c0.66871,-0.48568 1.33742,-0.49315 2.01542,-0.02242l3.05564,2.12204c0.678,0.47073 0.68729,0.94894 0.02786,1.43462l-2.99063,2.18181c-0.66871,0.48568 -1.33742,0.49315 -2.01542,0.02242l-3.05564,-2.12204c-0.678,-0.47073 -0.68729,-0.94894 -0.02786,-1.43462l2.99063,-2.18181z"
                  fill="url(#Dégradé_sans_nom_177-200)"
                  id="svg_205"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-101">
                <path
                  stroke="null"
                  className="cls-101"
                  d="m628.55228,647.0147c0.79874,-0.58281 1.60676,-0.59029 2.42408,-0.02242l3.66863,2.54047c0.81731,0.56787 0.8266,1.13574 0.02786,1.71855l-3.58504,2.62266c-0.79874,0.58281 -1.60676,0.59029 -2.42408,0.02242l-3.66863,-2.54047c-0.81731,-0.56787 -0.8266,-1.13574 -0.02786,-1.71855l3.58504,-2.62266z"
                  fill="url(#Dégradé_sans_nom_177-201)"
                  id="svg_206"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-102">
                <path
                  stroke="null"
                  className="cls-102"
                  d="m523.35097,647.55269c0.92877,-0.67995 1.86682,-0.68742 2.82345,-0.02989l4.28161,2.96637c0.95663,0.65753 0.96592,1.33001 0.03715,2.00996l-4.18873,3.05603c-0.92877,0.67995 -1.86682,0.68742 -2.82345,0.02989l-4.28161,-2.96637c-0.95663,-0.65753 -0.96592,-1.33001 -0.03715,-2.00996l4.18873,-3.05603z"
                  fill="url(#Dégradé_sans_nom_177-202)"
                  id="svg_207"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-103">
                <path
                  stroke="null"
                  className="cls-118"
                  d="m418.14967,648.09067c1.05879,-0.77708 2.13616,-0.78456 3.22282,-0.03736l4.8946,3.39227c1.08666,0.75467 1.09594,1.51681 0.03715,2.29389l-4.78314,3.49688c-1.05879,0.77708 -2.13616,0.78456 -3.22282,0.03736l-4.8946,-3.39227c-1.08666,-0.75467 -1.09594,-1.51681 -0.03715,-2.29389l4.78314,-3.49688z"
                  fill="url(#Dégradé_sans_nom_177-203)"
                  id="svg_208"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-104">
                <path
                  stroke="null"
                  className="cls-119"
                  d="m312.95765,648.62865c1.19811,-0.87422 2.4055,-0.88916 3.63147,-0.03736l5.50758,3.81817c1.22597,0.8518 1.23526,1.71108 0.04644,2.5853l-5.37755,3.93025c-1.19811,0.87422 -2.4055,0.88916 -3.63147,0.03736l-5.50758,-3.81817c-1.22597,-0.8518 -1.23526,-1.71108 -0.04644,-2.5853l5.37755,-3.93025z"
                  fill="url(#Dégradé_sans_nom_177-204)"
                  id="svg_209"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-105">
                <path
                  stroke="null"
                  className="cls-115"
                  d="m207.75634,649.15916c1.32814,-0.97136 2.67485,-0.9863 4.03084,-0.04483l6.12057,4.2366c1.356,0.94147 1.37457,1.89788 0.04644,2.86923l-5.98125,4.36363c-1.32814,0.97136 -2.67485,0.9863 -4.03084,0.04483l-6.12057,-4.2366c-1.356,-0.94147 -1.37457,-1.89788 -0.04644,-2.86923l5.98125,-4.36363z"
                  fill="url(#Dégradé_sans_nom_177-205)"
                  id="svg_210"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-106">
                <path
                  stroke="null"
                  className="cls-116"
                  d="m102.55504,649.69714c1.45816,-1.06849 2.94419,-1.08343 4.4395,-0.04483l6.73355,4.6625c1.49531,1.0386 1.51389,2.09215 0.05573,3.15317l-6.57566,4.80447c-1.45816,1.06849 -2.94419,1.08343 -4.4395,0.04483l-6.73355,-4.6625c-1.49531,-1.0386 -1.51389,-2.09215 -0.05573,-3.15317l6.57566,-4.80447z"
                  fill="url(#Dégradé_sans_nom_177-206)"
                  id="svg_211"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-107">
                <path
                  stroke="null"
                  className="cls-113"
                  d="m-2.63698,650.23512c1.59748,-1.16563 3.20424,-1.18057 4.83887,-0.0523l7.33725,5.08841c1.63463,1.12827 1.6532,2.27895 0.05573,3.44457l-7.17007,5.23785c-1.59748,1.16563 -3.20424,1.18057 -4.83887,0.0523l-7.33725,-5.08841c-1.63463,-1.12827 -1.6532,-2.27895 -0.05573,-3.44457l7.17007,-5.23785z"
                  fill="url(#Dégradé_sans_nom_177-207)"
                  id="svg_212"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-108">
                <path
                  stroke="null"
                  className="cls-114"
                  d="m1050.70421,719.96348c0.17647,-0.12702 0.35293,-0.12702 0.53868,0l0.81731,0.56787c0.18575,0.12702 0.18575,0.25405 0,0.38107l-0.79874,0.58281c-0.17647,0.12702 -0.35293,0.12702 -0.53868,0l-0.81731,-0.56787c-0.18575,-0.12702 -0.18575,-0.25405 0,-0.38107l0.79874,-0.58281z"
                  fill="url(#Dégradé_sans_nom_177-208)"
                  id="svg_213"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-109">
                <path
                  stroke="null"
                  className="cls-117"
                  d="m945.5772,720.69573c0.26934,-0.19427 0.53868,-0.19427 0.80803,-0.00747l1.22597,0.8518c0.26934,0.1868 0.27863,0.38107 0.00929,0.57534l-1.19811,0.87422c-0.26934,0.19427 -0.53868,0.19427 -0.80803,0.00747l-1.22597,-0.8518c-0.26934,-0.1868 -0.27863,-0.38107 -0.00929,-0.57534l1.19811,-0.87422z"
                  fill="url(#Dégradé_sans_nom_177-209)"
                  id="svg_214"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-110">
                <path
                  stroke="null"
                  className="cls-103"
                  d="m840.4502,721.42051c0.35293,-0.26152 0.71515,-0.26152 1.07737,-0.01494l1.63463,1.12827c0.36222,0.25405 0.36222,0.50809 0.00929,0.76214l-1.59748,1.16563c-0.35293,0.26152 -0.71515,0.26152 -1.07737,0.01494l-1.63463,-1.12827c-0.36222,-0.25405 -0.36222,-0.50809 -0.00929,-0.76214l1.59748,-1.16563z"
                  fill="url(#Dégradé_sans_nom_177-210)"
                  id="svg_215"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-111">
                <path
                  stroke="null"
                  className="cls-104"
                  d="m735.3232,722.14529c0.44581,-0.32129 0.89162,-0.32877 1.34671,-0.01494l2.04328,1.4122c0.4551,0.31382 0.4551,0.63512 0.01858,0.95641l-1.99685,1.45703c-0.44581,0.32129 -0.89162,0.32877 -1.34671,0.01494l-2.04328,-1.4122c-0.4551,-0.31382 -0.4551,-0.63512 -0.01858,-0.95641l1.99685,-1.45703z"
                  fill="url(#Dégradé_sans_nom_177-211)"
                  id="svg_216"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-112">
                <path
                  stroke="null"
                  className="cls-105"
                  d="m630.19619,722.87007c0.5294,-0.38854 1.06808,-0.39601 1.61605,-0.01494l2.45194,1.69614c0.54797,0.3736 0.54797,0.76214 0.01858,1.15068l-2.39622,1.74844c-0.5294,0.38854 -1.06808,0.39601 -1.61605,0.01494l-2.45194,-1.69614c-0.54797,-0.3736 -0.54797,-0.76214 -0.01858,-1.15068l2.39622,-1.74844z"
                  fill="url(#Dégradé_sans_nom_177-212)"
                  id="svg_217"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-113">
                <path
                  stroke="null"
                  className="cls-112"
                  d="m525.0599,723.60233c0.62227,-0.45579 1.24455,-0.46326 1.88539,-0.02242l2.85131,1.98007c0.63156,0.44085 0.64085,0.88916 0.01858,1.33748l-2.7863,2.03985c-0.62227,0.45579 -1.24455,0.46326 -1.88539,0.02242l-2.85131,-1.98007c-0.63156,-0.44085 -0.64085,-0.88916 -0.01858,-1.33748l2.7863,-2.03985z"
                  fill="url(#Dégradé_sans_nom_177-213)"
                  id="svg_218"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-114">
                <path
                  stroke="null"
                  className="cls-111"
                  d="m419.9329,724.32711c0.70586,-0.51557 1.4303,-0.52304 2.15474,-0.02242l3.25997,2.264c0.72444,0.50062 0.73373,1.01619 0.02786,1.53175l-3.18567,2.33125c-0.70586,0.51557 -1.4303,0.52304 -2.15474,0.02242l-3.25997,-2.264c-0.72444,-0.50062 -0.73373,-1.01619 -0.02786,-1.53175l3.18567,-2.33125z"
                  fill="url(#Dégradé_sans_nom_177-214)"
                  id="svg_219"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-115">
                <path
                  stroke="null"
                  className="cls-109"
                  d="m314.80589,725.05189c0.79874,-0.58281 1.60676,-0.59029 2.42408,-0.02242l3.66863,2.54047c0.81731,0.56787 0.8266,1.13574 0.02786,1.71855l-3.58504,2.62266c-0.79874,0.58281 -1.60676,0.59029 -2.42408,0.02242l-3.66863,-2.54047c-0.81731,-0.56787 -0.8266,-1.13574 -0.02786,-1.71855l3.58504,-2.62266z"
                  fill="url(#Dégradé_sans_nom_177-215)"
                  id="svg_220"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-116">
                <path
                  stroke="null"
                  className="cls-108"
                  d="m209.67889,725.77667c0.88233,-0.65006 1.78323,-0.65753 2.68413,-0.02989l4.07728,2.8244c0.91019,0.62764 0.91948,1.26276 0.02786,1.91282l-3.98441,2.91407c-0.88233,0.65006 -1.78323,0.65753 -2.68413,0.02989l-4.07728,-2.8244c-0.91019,-0.62764 -0.91948,-1.26276 -0.02786,-1.91282l3.98441,-2.91407z"
                  fill="url(#Dégradé_sans_nom_177-216)"
                  id="svg_221"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-117">
                <path
                  stroke="null"
                  className="cls-107"
                  d="m104.55188,726.50892c0.9752,-0.70984 1.9597,-0.72478 2.95348,-0.02989l4.48594,3.10834c0.99378,0.68742 1.01235,1.38979 0.03715,2.10709l-4.38377,3.20547c-0.9752,0.70984 -1.9597,0.72478 -2.95348,0.02989l-4.48594,-3.10834c-0.99378,-0.68742 -1.01235,-1.38979 -0.03715,-2.10709l4.38377,-3.20547z"
                  fill="url(#Dégradé_sans_nom_177-217)"
                  id="svg_222"
                />
              </g>
              <g stroke="null" id="shape_other_10_1-118">
                <path
                  stroke="null"
                  className="cls-106"
                  d="m-0.57512,727.2337c1.05879,-0.77708 2.13616,-0.78456 3.22282,-0.03736l4.8946,3.39227c1.08666,0.75467 1.09594,1.51681 0.03715,2.29389l-4.78314,3.49688c-1.05879,0.77708 -2.13616,0.78456 -3.22282,0.03736l-4.8946,-3.39227c-1.08666,-0.75467 -1.09594,-1.51681 -0.03715,-2.29389l4.78314,-3.49688z"
                  fill="url(#Dégradé_sans_nom_177-218)"
                  id="svg_223"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Background;
