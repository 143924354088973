import React from 'react'
import './Button.scss'

interface Props {
  title: string
  onClick: () => void
  disabled?: boolean
  secondaryColor?: boolean
  dangerColor?: boolean
  accentColor?: boolean
  className?: string
  progress?: number
  icon?: any
}

export default (props: Props) => {
  return (
    <div
      className={`Button${props.className ? ` ${props.className}` : ''}${
        props.disabled
          ? ' Button--disabled'
          : props.dangerColor
          ? ' Button--dangerColor'
          : props.secondaryColor
          ? ' Button--secondaryColor'
          : props.accentColor
          ? ' Button--accentColor'
          : ''
      }`}
      onClick={props.disabled ? undefined : props.onClick}
    >
      {!!props.progress && (
        <div
          className="Button__progress"
          style={{ width: `${props.progress}%` }}
        />
      )}
      <div className="Button__content">
        {props.icon && <img src={props.icon} />}
        {props.title}
      </div>
    </div>
  )
}
