import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import translations from '../locales'

import dayjs from 'dayjs'
import store from './store'

export enum Lng {
  en = 'en',
  fr = 'fr',
}

export const stringToLng = (code: string) => {
  if (Object.values(Lng).includes(code as Lng)) {
    return code as Lng
  }
  return Lng.fr
}

const fallbackLng = 'fr' as Lng
const navLng =
  // (store.store.JWT?.language && stringToLng(store.store.JWT?.language)) ||
  // ((store.store.Language || navigator.language.split('-')[0] || fallbackLng) as Lng)
  ((store.store.Language || navigator.language.split('-')[0] || fallbackLng) as Lng)

dayjs.extend(LocalizedFormat)

class I18n {
  public navLng!: Lng
  public fallbackLng: Lng

  constructor(navLng: Lng, fallbackLng: Lng) {
    this.fallbackLng = fallbackLng
    this.setLng(navLng)
  }

  public setLng(lng: Lng) {
    this.navLng = lng
    dayjs.locale(lng)
  }

  public getLng(): Lng {
    return this.navLng
  }

  public translate(key: string): string {
    try {
      return (
        translations[key][this.navLng] ||
        translations[key][this.fallbackLng] ||
        key
      )
    } catch (err) {
      //Report err if needed
      console.warn(`Missing translation: ${key}`)
      return key
    }
  }
}

const i18n = new I18n(navLng, fallbackLng)

export function setLng(lng: Lng) {
  i18n.setLng(lng)
}

export function interpolate(str: string, vars: any): string {
  let res = str
  Object.keys(vars).forEach((key: string) => {
    res = res.replace(`%${key}`, vars[key].toString())
  })
  return res
}

export function t(key: string, interpolation?: any): string {
  const translated = i18n.translate(key)
  return interpolation ? interpolate(translated, interpolation) : translated
}

export default i18n
