import React from "react";
import "./ThemePreview.scss";
import Theme from "../../../types/theme";
import Button from "../../../components/Button";
import { t } from "../../../services/i18n";
import store from "../../../services/store";

interface Props {
  theme: Theme;
  onDismiss: () => void;
  onSessionLaunch: () => void;
}

export default (props: Props) => {
  const isPdfExist = () => {
    const associatedTranslation = props.theme.themeTranslations.find(
      (translation) => translation.locale === store.store.Language
    );
    if (associatedTranslation !== undefined)
      if (associatedTranslation.pdfUrl !== null) return 1;
    return -1;
  };

  const findPdfIndex = () => {
    const index = props.theme.themeTranslations.find(
      (translation) => translation.locale === store.store.Language
    );
    if (index !== undefined) {
      if (index.pdfUrl !== null) {
        return index.pdfUrl;
      }
    }
  };

  return (
    <div className="ThemePreview" onClick={props.onDismiss}>
      <div className="ThemePreview__body" onClick={(e) => e.stopPropagation()}>
        <div className="ThemePreview__bodyContent">
          <div className="ThemePreview__bodyContentImage">
            <img src={props.theme.imageUrl}></img>
          </div>
          <div className="ThemePreview__bodyContentInfo">
            <div className="ThemePreview__bodyContentInfoTitle">
              {props.theme.name}
            </div>
            <div className="ThemePreview__bodyContentInfoDesc">
              {props.theme.description}
            </div>
          </div>
        </div>
        <div className="ThemePreview__bodyButtons">
          <Button
            className="ThemePreview__bodyButtonsEntry ThemePreview__bodyButtonsEntry--accent"
            title={t("C'est parti")}
            onClick={props.onSessionLaunch}
          />
          {isPdfExist() !== -1 && (
            <a
              href={findPdfIndex()}
              target="_blank"
              className="ThemePreview__bodyButtonsEntry"
            >
              <Button
                title={t("Télécharger le support de formation")}
                onClick={() => {}}
              />
            </a>
          )}
          <Button
            className="ThemePreview__bodyButtonsEntry"
            title={t("Retour")}
            onClick={props.onDismiss}
          />
        </div>
      </div>
    </div>
  );
};
