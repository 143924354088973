import React from 'react'
import './InputWithLabel.scss'

interface Props {
  label: string
  type?: string
  value: string
  selectOptions?: any[]
  selectLabelKey?: string
  selectValueKey?: string
  onChange: (v: string) => void
}

export default (props: Props) => {
  return (
    <div className="InputWithLabel">
      <div className="InputWithLabel__label">{props.label}</div>
      <div className="InputWithLabel__input">
        {props.selectOptions ? (
          <select onChange={(e) => props.onChange(e.target.value)}>
            {props.selectOptions.map((o) => (
              <option
                selected={o[props.selectValueKey] == props.value}
                value={o[props.selectValueKey]}
              >
                {o[props.selectLabelKey]}
              </option>
            ))}
          </select>
        ) : (
          <input
            onChange={(e) => props.onChange(e.target.value)}
            type={props.type}
            value={props.value}
          />
        )}
      </div>
    </div>
  )
}
