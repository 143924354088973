export default {
  'Lancer une session aléatoire': {
    en: 'Launch a random session',
    fr: 'Lancer une session aléatoire',
  },
  'Lancer une session thématique': {
    en: 'Launch a session from themes',
    fr: 'Lancer une session thématique',
  },
  Entrainement: {
    en: 'Training',
    fr: 'Entrainement',
  },
  Certification: {
    en: 'Certification',
    fr: 'Certification',
  },
  'Une fois le seuil de %percentage% atteint, vous aurez la possiblité de tenter la certification.': {
    en:
      'Once you reach the %percentage% completion, you can pass the certification',
    fr:
      'Une fois le seuil de %percentage% atteint, vous aurez la possiblité de tenter la certification.',
  },
  'Impossible de créer la session': {
    en: 'Could not create the session',
    fr: 'Impossible de créer la session',
  },
  'Passer un test blanc': {
    en: 'Start a dry run',
    fr: 'Passer un test blanc'
  },
  'Passer la certification': {
    en: 'Pass the certification',
    fr: 'Passer la certification'
  },
  'Lancer une session par intelligence artificielle': {
    en: 'Launch a session using artificial intelligence',
    fr: 'Lancer une session par intelligence artificielle'
  },
  'Choisissez une thématique ci-dessous :': {
    en: 'Choose a topic below:',
    fr: 'Choisissez une thématique ci-dessous :'
  },
  'ou choisissez une thématique ci-dessous :': {
    en: 'or choose a theme below:',
    fr: 'ou choisissez une thématique ci-dessous :'
  },
  "C'est parti": {
    en: "Let's get started",
    fr: "C'est parti"
  },
  'Retour': {
    en: 'Back',
    fr: 'Retour'
  },
  'Valider': {
    en: 'Confirm',
    fr: 'Valider'
  },
  'Signaler un problème': {
    en: 'Report a problem',
    fr: 'Signaler un problème'
  },
  'Bonne réponse': {
    en: 'Good answer',
    fr: 'Bonne réponse'
  },
  'Mauvaise réponse': {
    en: 'Wrong answer',
    fr: 'Mauvaise réponse'
  },
  'Révision': {
    en: 'Review',
    fr: 'Révision'
  },
  'Vous avez répondu :': {
    en: 'You answered:',
    fr: 'Vous avez répondu :'
  },
  'Suite': {
    en: 'Next',
    fr: 'Suite'
  },
  'Bonnes': {
    en: 'Good',
    fr: 'Bonnes'
  },
  'réponses': {
    en: 'answers',
    fr: 'réponses'
  },
  'Félicitations !!!': {
    en: 'Congratulations !!!',
    fr: 'Félicitations !!!'
  },
  'Cette session est terminée': {
    en: 'This session is over',
    fr: 'Cette session est terminée'
  },
  'Poursuivre avec une autre session': {
    en: 'Restart a session',
    fr: 'Poursuivre avec une autre session'
  },
  'On se quitte déjà ?': {
    en: 'Shall we say goodbye ?',
    fr: 'On se quitte déjà ?'
  },
  'Voulez-vous vraiment quitter la session ?': {
    en: 'Do you really want to leave the session ?',
    fr: 'Voulez-vous vraiment quitter la session ?'
  },
  'Rester': {
    en: 'Stay',
    fr: 'Rester'
  },
  'À retenir: %goodpractice.': {
    en: 'To remember: %goodpractice.',
    fr: 'À retenir: %goodpractice.'
  },
  'Revoir les questions ratées': {
    en: 'Review failed questions',
    fr: 'Revoir les questions ratées'
  },
  'Vous avez gagné': {
    en: "You've won",
    fr: 'Vous avez gagné'
  },
  'Sélectionnez une formation pour commencer': {
    en: 'Select a training to start',
    fr: 'Sélectionnez une formation pour commencer'
  },
  'Vous n\'avez aucune formation en cours actuellement.': {
    en: 'You have no current training.',
    fr: 'Vous n\'avez aucune formation en cours actuellement.'
  },
  'Rentrez ici votre email afin de pouvoir réinitaliser votre mot de passe': {
    en: 'Enter your email to reset your password',
    fr: 'Rentrez ici votre email afin de pouvoir réinitaliser votre mot de passe'
  },
  "Télécharger le support de formation": {
    en: "Download the training's support",
    fr: "Télécharger le support de formation"
  },
  "Télécharger le certificat": {
    en: "Download the certificate",
    fr: "Télécharger le certificat"
  },
  "se former": {
    en: "train myself",
    fr: "se former"
  },
  "Approfondir un thème :": {
    en: "Going deeper into a theme :",
    fr: "Appronfondir un thème :"
  },
  "Tutorial": {
    en: "Tutorial",
    fr: "Didactitiel"
  },
  "Comment se former ?": {
    en: "How to get trained",
    fr: "Comment se former ?"
  },
  "Comment fonctionne le quiz ?": {
    en: "How does the quiz work?",
    fr: "Comment fonctionne le quiz ?"
  },
  "Comment approfondir un thème ?": {
    en: "How to go deeper into a theme",
    fr: "Comment approfondir un thème ?"
  },
  "Mon profil": {
    en: "My profil",
    fr: "Mon profil"
  },
  "Mes statistiques": {
    en: "My statistics",
    fr: "Mes statistiques"
  },
  "Ma certification": {
    en: "My certification",
    fr: "Ma certification"
  },
}
