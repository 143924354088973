import * as React from 'react'
import './QuitSessionModal.scss'
import Modal from '../../../components/Modal'
import { t } from '../../../services/i18n'
import Button from '../../../components/Button'
import { useHistory } from 'react-router-dom'

export interface QuitSessionModalProps {
  dismiss: () => void
}

const QuitSessionModal = (props: QuitSessionModalProps) => {
  const history = useHistory()

  return (
    <Modal className="QuitSessionModal" dismiss={props.dismiss}>
      <div className="QuitSessionModal__body">
        <div className="QuitSessionModal__bodyTitle">
          {t('On se quitte déjà ?')}
        </div>
        <div className="QuitSessionModal__bodyText">
          {t('Voulez-vous vraiment quitter la session ?')}
        </div>
        <div className="QuitSessionModal__bodyButtons">
          <Button
            title={t('Rester')}
            onClick={() => props.dismiss()}
            secondaryColor
          />
          <Button
            title={t('Quitter')}
            dangerColor
            onClick={() => {
              history.push('/')
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default QuitSessionModal
