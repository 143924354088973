import React, { useState, useEffect, useRef } from 'react'
import './Session.scss'
import Button from '../../components/Button'
import { useParams, useHistory } from 'react-router-dom'
import httpClient from '../../services/httpClient'
import ROUTES from '../../routes'
import store, { Message } from '../../services/store'
import { t } from '../../services/i18n'
import Session from '../../types/session'
import ProgressBar from '../../components/ProgressBar'
import Loader from 'react-loader-spinner'
import Content from './Content'
import { UUID } from '../../types/common'
import Correction from './Correction'
import SessionFinishedModal from './SessionFinishedModal'
import { usePrevious, shuffleArray } from '../../utils'
import QuitSessionModal from './QuitSessionModal'
import CertificationSessionFinishedModal from './CertificationSessionFinishedModal'
import { Answer } from '../../types/answer'
import BackgroundDesktop from '../../../assets/images/background_desktop.svg'
import BackgroundMobile from '../../../assets/images/background_mobile.svg'
import dayjs from 'dayjs'
import SpacedRepetitionModal from './SpacedRepetitionModal'
import SessionContent from '../../types/session_content'
import Background from '../../components/Background';
import License from '../../types/license';

export default () => {
  const [dirtySession, setDirtySession] = useState(false)
  const [session, setSession] = useState<Session>(undefined)
  const [
    displayFinishedSessionModal,
    setDisplayFinishedSessionModal,
  ] = useState(false)
  const [displayQuitSessionModal, setDisplayQuitSessionModal] = useState(false)
  const [
    displaySpacedRepetitionModal,
    setDisplaySpacedRepetitionModal,
  ] = useState(false)
  const [shownSpacedRepetitionModal, setShownSpacedRepetitionModal] = useState(
    false
  )
  const prevShowSpacedRepetitionModal = usePrevious(shownSpacedRepetitionModal)
  const [actualContentIndex, setActualContentIndex] = useState(0)
  const prevContentIndex = usePrevious(actualContentIndex)
  const [selectedAnswers, setSelectedAnswers] = useState<UUID[]>([])
  const { id } = useParams<any>()
  const [displayCorrection, setDisplayCorrection] = useState(false)
  const history = useHistory()
  const [orderedAnswers, setOrderedAnswers] = useState<Answer[]>(undefined)
  const [timeLeft, setTimeLeft] = useState(0)
  const [timer, setTimer] = useState(undefined)
  const [shoudlValidateContent, setShouldValidateContent] = useState(false)
  const correctionRef = useRef()
  const [questionLength, setQuestionLength] = useState(0)
  const [isSessionRecap, setIsSessionRecap] = useState(false)
  const [myLicense, setMyLicense] = useState<License>(undefined)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMyLicense()
  }, [])

  useEffect(() => {
    setLoading(!(myLicense));
  }, [myLicense])

  useEffect(() => {
    if (isSessionRecap) {
      window.onbeforeunload = (e: Event) => {
        e.preventDefault()
        return t('Êtes-vous sûr de vouloir quitter la session en cours ?')
      }
    }

    return () => {
      window.onbeforeunload = undefined
    }
  }, [isSessionRecap])

  useEffect(() => {
    id && fetchSession()
  }, [id])

  useEffect(() => {
    if (
      shownSpacedRepetitionModal == true &&
      prevShowSpacedRepetitionModal == false
    )
      nextQuestion()
  }, [shownSpacedRepetitionModal])

  useEffect(() => {
    history.push({
      search: `?idx=${actualContentIndex}`,
    })
    if (prevContentIndex < actualContentIndex) {
      store.notify(Message.NextContent)
    }
  }, [actualContentIndex])

  useEffect(() => {
    if (!session || dirtySession) return

    let i = 0

    while (
      i < session.sessionContentAssociations.length &&
      session.sessionContentAssociations[actualContentIndex].status != 'waiting'
    ) {
      i++
    }
    if (i >= session.sessionContentAssociations.length) {
      i = session.sessionContentAssociations.length - 1
    }
    setActualContentIndex(i)
    setDirtySession(true)
    startContentCountdown()
  }, [session])

  useEffect(() => {
    if (!actualSessionContent()) return

    const content = actualContent()

    setSelectedAnswers(actualSessionContent().selectedAnswers)
    setDisplayCorrection(
      session.sessionType != 'certification' &&
      session.sessionType != 'certification_training' &&
      actualSessionContent() &&
      actualSessionContent().status != 'waiting'
    )
    if (content?.contentType == 'sortable_question') {
      setOrderedAnswers(shuffleArray(content.answers))
    }
  }, [actualContentIndex, session])

  useEffect(() => {
    if (shoudlValidateContent) {
      validateContent(true)
      setShouldValidateContent(false)
    }
  }, [shoudlValidateContent])

  const actualContent = () => {
    return actualSessionContent() ? actualSessionContent().content : null
  }

  const actualSessionContent = () => {
    return session &&
      session.sessionContentAssociations.length > actualContentIndex
      ? session.sessionContentAssociations[actualContentIndex]
      : null
  }

  const fetchMyLicense = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_MY_LICENSE({ id: store.store.JWT.license_id }));
      setMyLicense(res)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer les licenses'));
      console.warn(e);
    }
  }

  const fetchSession = async () => {
    try {
      const res = await httpClient.req(ROUTES.FETCH_SESSION({ id }))

      res.sessionContentAssociations.map((sca: any) => ({
        ...sca,
        content: {
          ...sca.content,
          answers: sca.content.answers.sort(() => 0.5 - Math.random()),
        },
      }))
      setSession(res)
      setQuestionLength(res.sessionContentAssociations.length)
      setIsSessionRecap(true)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de récupérer la session'))
      console.warn(e)
    }
  }

  const validateContent = async (noAnswers?: boolean) => {
    if (timer) {
      clearTimeout(timer)
      setTimer(undefined)
    }

    try {
      if (actualSessionContent().status == 'waiting') {
        const res = await httpClient.req(
          ROUTES.SUBMIT_SESSION_CONTENT_ANSWER({
            sessionContentAssociation: {
              id: actualSessionContent().id,
              selectedAnswers: noAnswers ? [] : selectedAnswers,
              orderedAnswers: noAnswers ? [] : orderedAnswers,
            },
          })
        )

        session.sessionContentAssociations[actualContentIndex] = res
        // If question is failed, it is not certification or training and max retries for this content is not reached
        if (
          res.status == 'failed' &&
          !['certification', 'certification_training'].includes(
            session.sessionType
          ) &&
          triesForContent(res.content.id) <=
          session.campaign.campaignTemplate.contentMaxRetries
        ) {
          session.sessionContentAssociations.push({
            ...res,
            status: 'waiting',
            selectedAnswers: [],
            orderedAnswers: [],
            content: {
              ...res.content,
              answers: res.content.answers.sort(() => 0.5 - Math.random()),
            },
          })
        }
        setSession({
          ...session,
          sessionContentAssociations: [...session.sessionContentAssociations],
        })
        setTimeout(() => {
          if (correctionRef && correctionRef.current) {
            ; (correctionRef.current as any).scrollIntoView()
            window.scrollBy(0, -96)
          }
        }, 100)
      }
      if (
        ['certification', 'certification_training'].includes(
          session.sessionType
        )
      ) {
        nextQuestion()
      }
      store.notify(Message.RefreshMenu)
    } catch (e) {
      store.notify(Message.Error, t('Impossible de valider la réponse'))
      console.warn(e)
    }
  }

  const contentAlreadyShown = (index: number) => {
    for (let i = 0; i < index; i++) {
      const sca = session.sessionContentAssociations[i]
      if (
        sca.content.id == session.sessionContentAssociations[index].content.id
      ) {
        return true
      }
    }
    return false
  }

  const triesForContent = (content_id: UUID) => {
    let tries = 0

    for (let i = 0; i < session.sessionContentAssociations.length; i++) {
      const sca = session.sessionContentAssociations[i]

      if (sca.content.id == content_id) {
        tries += 1
      }
    }
    return tries
  }

  const nextQuestion = () => {
    if (actualContentIndex + 1 < session.sessionContentAssociations.length) {
      // If content has not been tried yet then it's not spaced repetition
      // If spaced repetition modal has been shown then we can continue to next question
      if (
        !contentAlreadyShown(actualContentIndex + 1) ||
        shownSpacedRepetitionModal
      ) {
        setSelectedAnswers([])
        setDisplayCorrection(false)
        setActualContentIndex((prev) => prev + 1)
        startContentCountdown()
      } else {
        setDisplaySpacedRepetitionModal(true)
        setIsSessionRecap(false)
      }
    } else {
      setDisplayFinishedSessionModal(true)
      setIsSessionRecap(false)
    }
  }

  const startSpacedRepetition = () => {
    setShownSpacedRepetitionModal(true)
    setDisplaySpacedRepetitionModal(false)
  }

  const quitSession = () => {
    setDisplayQuitSessionModal(true)
  }

  const previousContent = () => {
    setActualContentIndex((prev) => (prev > 0 ? prev - 1 : 0))
  }

  const reorderAnswers = (from: number, to: number) => {
    const result = [...orderedAnswers]
    const [removed] = result.splice(from, 1)

    result.splice(to, 0, removed)
    setOrderedAnswers(result)
  }

  const startContentCountdown = () => {
    if (
      session.sessionType == 'certification' ||
      session.sessionType == 'certification_training'
    ) {
      var finish = dayjs().add(actualContent().duration, 'second')

      const timer = () => {
        const diff = finish.diff(dayjs(), 'second')

        if (diff > 0) {
          setTimeLeft(diff)
          setTimer(setTimeout(timer, 1000))
        } else {
          setTimeLeft(0)
          skipCertificationContent()
        }
      }

      timer()
    }
  }

  const skipCertificationContent = () => {
    store.notify(Message.ValidateContent)
  }

  store.listen(Message.ValidateContent, () => setShouldValidateContent(true))
  store.listen(Message.PreviousContent, previousContent)
  store.listen(Message.QuitSession, quitSession)

  return (
    <div className="Session">
      {loading ? (null) :
        (
          <div className='Background'>
            <Background primaryColor={myLicense.primaryColor} secondaryColor={myLicense.secondaryColor}></Background>
          </div>
        )}
      {displayFinishedSessionModal &&
        (['certification', 'certification_training'].includes(
          session.sessionType
        ) ? (
          <CertificationSessionFinishedModal
            session={session}
            dismiss={() => setDisplayFinishedSessionModal(false)}
          />
        ) : (
          <SessionFinishedModal
            session={session}
            dismiss={() => setDisplayFinishedSessionModal(false)}
          ></SessionFinishedModal>
        ))}
      {displayQuitSessionModal && (
        <QuitSessionModal
          dismiss={() => setDisplayQuitSessionModal(false)}
        ></QuitSessionModal>
      )}
      {displaySpacedRepetitionModal && (
        <SpacedRepetitionModal
          session={session}
          dismiss={startSpacedRepetition}
          contentsCount={actualContentIndex + 1}
        ></SpacedRepetitionModal>
      )}
      {session ? (
        <div className="Session__body">
          <div className="Session__header">
            {session.sessionType == 'default' && actualContentIndex != 0 && (
              <div className="Session__headerBack" onClick={previousContent}>
                <i>keyboard_arrow_left</i>
              </div>
            )}
            <div className="Session__headerProgress">
              <ProgressBar
                progress={
                  (100 * (actualContentIndex + 1)) /
                  questionLength
                }
                colorStart="#f8a930"
                colorEnd="#fabe37"
              />
            </div>
            <div className="Session__headerQuit" onClick={quitSession}>
              <i>exit_to_app</i>
            </div>
          </div>
          {actualSessionContent() &&
            (actualContent().contentType != 'sortable_question' ||
              orderedAnswers) && (
              <div
                className={`Session__content${actualContent() ? '' : ' Session__content--loading'
                  }`}
              >
                <Content
                  sessionContent={actualSessionContent()}
                  content={actualContent()}
                  selectedAnswers={selectedAnswers}
                  orderedAnswers={orderedAnswers}
                  onAnswersReorder={reorderAnswers}
                  onChange={(v) => setSelectedAnswers(v)}
                  disabled={actualSessionContent().status != 'waiting'}
                  timeLeft={timeLeft}
                />
              </div>
            )}
          {displayCorrection && (
            <div className="Session__correction" ref={correctionRef}>
              <Correction
                sessionContent={actualSessionContent()}
                content={actualContent()}
                selectedAnswers={selectedAnswers}
                succeeded={actualSessionContent().status == 'succeeded'}
              />
            </div>
          )}
          <div className="Session__buttons">
            {displayCorrection ? (
              <Button title={t('Suite')} onClick={nextQuestion} />
            ) : (
              <Button
                title={t('Valider')}
                onClick={() => validateContent()}
                accentColor
              />
            )}
          </div>
        </div>
      ) : (
        <Loader type="ThreeDots" color="#62a5e2" height={100} width={100} />
      )}
    </div>
  )
}
