export default {
    'Erreur inconnue': {
        en: 'Unknown error',
        fr: 'Erreur inconnue'
    },
    'Impossible de récupérer la complétion': {
        en: 'Unable to retrieve completion',
        fr: 'Impossible de récupérer la complétion'
    },
    "Impossible de vérifier l'inscription à la formation": {
        en: 'Unable to verify the registration to the formation',
        fr: 'Impossible de vérifier l\'inscription à la formation'
    },
    "Invalid Email or password.": {
        en: 'Invalid Email or password',
        fr: 'Email ou mot de passe incorrect'
    },
    "You need to sign in or sign up before continuing.": {
        en: 'You need to sign in or sign up before continuing',
        fr: 'Vous devez vous connecter ou vous inscrire avant de continuer'
    },
    'Votre mot de passe a été changé avec succès': {
        en: 'Your password has been changed successfully',
        fr: 'Votre mot de passe a été changé avec succès'
    },
    'Impossible de changer votre mot de passe': {
        en: 'Unable to change your password',
        fr: 'Impossible de changer votre mot de passe'
    },
    'Si l\'email est correct, vous devriez recevoir un mail sous peu': {
        en: 'If the email is correct, you should receive a mail shortly',
        fr: 'Si l\'email est correct, vous devriez recevoir un mail sous peu'
    },
    'Impossible de demander la réinitialisation du mot de passe': {
        en: 'Unable to request the password reset',
        fr: 'Impossible de demander la réinitialisation du mot de passe'
    }
}