import React, { useState, useEffect } from 'react'
import './Layout.scss'
import store, { Message, Stored } from '../../services/store'
import Toastify from 'toastify-js'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Login from '../Login'
import Home from '../Home'
import LayoutMenu from './LayoutMenu'
import MyAccount from '../MyAccount'
import { useForceUpdate } from '../../utils'
import Session from '../Session'
import Themes from '../Themes'
import ResetPassword from '../ResetPassword'
import FirstConnection from '../FirstConnection'
import DemoLogin from '../DemoLogin'
import { t } from '../../services/i18n'
import Tutorial from '../Tutorial'
import ROUTES from '../../routes'
import httpClient from '../../services/httpClient'
import User from '../../types/user'
export default () => {
  const [loggedIn, setLoggedIn] = useState(store.store.RawJWT)
  const forceUpdate = useForceUpdate()

  store.listen(Stored.Language, forceUpdate)
  store.listen(Stored.RawJWT, setLoggedIn)
  store.listen(Message.Error, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: ' #fc7169',
    }).showToast()
  )
  store.listen(Message.Notification, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: '#4dce76',
    }).showToast()
  )
  store.listen(Message.NeedAuth, () => {
    store.update(Stored.JWT, undefined)
    store.update(Stored.RawJWT, undefined)
    store.update(Stored.RefreshToken, undefined)
  })

  useEffect(() => {
    const refresh = setInterval(() => {
      if (store.store.JWT && Date.now() >= store.store.JWT.exp * 1000) {
        store.notify(Message.Error, t('Token expiré'))
        store.update(Stored.JWT, undefined)
        store.update(Stored.RawJWT, undefined)
        store.update(Stored.RefreshToken, undefined)
      }
    }, 60000);
    return () => {
      clearInterval(refresh)
    }
  }, []);

  return (
    <div className="Layout">
      <BrowserRouter>
        {loggedIn ? (
          <Switch>
            <Route path="/sessions/:id"></Route>
            <Route path="/">
              <LayoutMenu />
            </Route>
          </Switch>
        ) : (null)}
        <main
          className={`Layout__content${loggedIn ? '' : ' Layout__content--full'
            }`}
        >
          {!loggedIn ? (
            <Switch>
              <Route path="/demo">
                <DemoLogin />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/first_connection">
                <FirstConnection />
              </Route>
              <Route path="/reset_password/:token">
                <ResetPassword />
              </Route>
              <Route path="/reset_password">
                <ResetPassword />
              </Route>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path="/my_account">
                <MyAccount />
              </Route>
              <Route path="/tutorial">
                <Tutorial />
              </Route>
              <Route path="/themes">
                <Themes />
              </Route>
              <Route path="/sessions/:id">
                <Session />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          )}
        </main>
      </BrowserRouter>
    </div>
  )
}
