import React, { Fragment, useEffect } from 'react'
import Content from '../../../types/content'
import './Content.scss'
import { UUID } from '../../../types/common'
import SortableList from '../../../components/SortableList'
import { Answer } from '../../../types/answer'
import XAnswer from '../Answer'
import { t } from '../../../services/i18n'
import SessionContent from '../../../types/session_content'

interface Props {
  content: Content
  selectedAnswers: UUID[]
  orderedAnswers: Answer[]
  onAnswersReorder: (from: number, to: number) => void
  onChange: (ids: UUID[]) => void
  disabled?: boolean
  onVideoEnded?: () => void
  timeLeft?: number
  sessionContent: SessionContent
}

export default (props: Props) => {
  useEffect(() => {
    window.addEventListener('resize', setImageSize)
  }, [])

  useEffect(() => {
    setImageSize()
  }, [props.content])

  const onChange = (id: UUID, value: boolean) => {
    if (value) {
      if (props.content.contentType == 'one_answer_question') {
        props.onChange([id])
      } else {
        props.onChange([...props.selectedAnswers, id])
      }
    } else {
      props.onChange(props.selectedAnswers.filter((a) => a != id))
    }
  }

  const setImageSize = () => {
    let sessionBody: HTMLElement = document.querySelector('.Session__content')
    let contentImage: HTMLElement = document.querySelector('.Content__image')

    if (sessionBody && contentImage) {
      contentImage.style.height = `${(+sessionBody.offsetWidth * 2) / 3 + 1}px`
    }
  }

  const getExtension = (filename: string) => {
    var parts = filename.split('.')
    return parts[parts.length - 1]
  }

  const isVideo = (filename: string) => {
    const ext = getExtension(filename)

    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'mkv':
        return true
    }
    return false
  }

  return (
    <div className="Content">
      <a
        target="_blank"
        href={`mailto:support.apprenant@vigiware.com?subject=Problème avec le contenu "${props.content.title}"&body=Bonjour,%0A%0AIl y a un problème dans le contenu "${props.content.title}" : EXPLIQUER ICI LE PROBLÈME.`}
        className="Content__report"
      >
        {/* <i>warning</i> */}
        {t('Signaler un problème')}
      </a>
      {props.content && (
        <Fragment>
          {props.content.contentType != 'video' && props.content.imageUrl && (
            <div className="Content__image mobileVisible">
              {isVideo(props.content.imageUrl) ? (
                <video
                  src={props.content.imageUrl}
                  controls
                  autoPlay
                  playsInline
                />
              ) : (
                <img src={props.content.imageUrl} />
              )}
            </div>
          )}
          <div className="Content__title">
            <div
              className={`Content__titlePoints${
                props.sessionContent.contentValidated
                  ? ' Content__titlePoints--succeeded'
                  : ''
              }`}
            >
              {props.sessionContent.contentValidated
                ? t('Révision')
                : `${Math.ceil(
                    props.content.points /
                      Math.pow(2, props.sessionContent.tries)
                  )} points`}
            </div>
            <div className="Content__titleText">{props.content.title}</div>
          </div>
          <div
            className={`Content__content${
              props.content.mediaXl ? ' Content__content--mediaXL' : ''
            }`}
          >
            <div className="Content__contentAnswers">
              {props.content.contentType == 'video' && (
                <video
                  src={props.content.imageUrl}
                  autoPlay
                  onEnded={props.onVideoEnded}
                  playsInline
                />
              )}
              {['one_answer_question', 'multiple_answer_question'].includes(
                props.content.contentType
              ) && (
                <div className="Content__answers">
                  {props.content.answers.map((a) => {
                    return (
                      <XAnswer
                        title={a.value}
                        singleSelection={
                          props.content.contentType == 'one_answer_question'
                        }
                        value={a.id}
                        onChange={(v) => onChange(a.id, v)}
                        selected={props.selectedAnswers.includes(a.id)}
                        key={a.id}
                        disabled={props.disabled}
                      />
                    )
                  })}
                </div>
              )}
              {props.content.contentType == 'sortable_question' && (
                <div className="Content__answers">
                  <SortableList
                    onReorder={props.onAnswersReorder}
                    items={props.orderedAnswers.map((a) => ({
                      id: a.id,
                      content: (
                        <XAnswer
                          title={a.value}
                          singleSelection={
                            props.content.contentType == 'one_answer_question'
                          }
                          reorderable
                          value={a.id}
                          onChange={(v) => onChange(a.id, v)}
                          selected={props.selectedAnswers.includes(a.id)}
                          key={a.id}
                          disabled={props.disabled}
                        />
                      ),
                    }))}
                  ></SortableList>
                </div>
              )}
              {props.timeLeft > 0 && (
                <div className="Content__timeLeft">
                  {Math.floor(props.timeLeft / 60).toLocaleString('fr-FR', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  }) +
                    ':' +
                    Math.floor(props.timeLeft % 60).toLocaleString('fr-FR', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                </div>
              )}
            </div>
            {props.content.contentType != 'video' && props.content.imageUrl && (
              <div className="Content__image mobileHidden">
                {isVideo(props.content.imageUrl) ? (
                  <video
                    src={props.content.imageUrl}
                    controls
                    autoPlay
                    playsInline
                  />
                ) : (
                  <img src={props.content.imageUrl} />
                )}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  )
}
