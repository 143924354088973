import { UUID } from './types/common';
import Theme from './types/theme';
// import * as dotenv from 'dotenv'

export enum Method {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
}

export interface Route {
  path: string;
  method: Method;
  data?: any;
  params?: any;
  auth?: boolean;
  file?: File;
  extern?: boolean;
  skipHumps?: boolean;
  headers?: any;
  downloadFilename?: string;
}

export const BASE_URL: string = process.env.BASE_URL_API as string;

const ROUTES: { [name: string]: (payload?: any) => Route } = {
  LOGIN: (data: { email: string; password: string }) => ({
    path: '/users/login',
    method: Method.Post,
    auth: false,
    data,
  }),
  FETCH_THEMES: () => ({
    path: '/themes',
    method: Method.Get,
    auth: true,
  }),
  FETCH_CAMPAIGNS: () => ({
    path: '/campaigns/mine',
    method: Method.Get,
    auth: true,
  }),
  FETCH_CAMPAIGN: (data: { id: UUID }) => ({
    path: `/campaigns/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_MY_LICENSE: (data: { id: UUID }) => ({
    path: `/licenses/${data.id}`,
    method: Method.Get,
    auth: true,
  }),
  FETCH_LANGUAGES: () => ({
    path: '/languages',
    method: Method.Get,
    auth: true,
  }),
  UPDATE_ME: (data) => ({
    path: '/users/me',
    method: Method.Patch,
    auth: true,
    data,
  }),
  FETCH_ME: () => ({
    path: '/users/me',
    method: Method.Get,
    auth: true,
  }),
  FETCH_USER_CAMPAIGNS: (data: { userId: UUID }) => ({
    path: `/users/${data.userId}/user_campaigns`,
    method: Method.Get,
    auth: true,
    data,
  }),
  CREATE_SESSION: (data: { session: { themes?: Theme[] } }) => ({
    path: '/sessions',
    method: Method.Post,
    auth: true,
    data,
  }),
  FETCH_SESSION: (data: { id: UUID }) => ({
    path: `/sessions/${data.id}`,
    method: Method.Get,
    auth: true,
    data,
  }),
  FETCH_REGISTRATION: (data: { campaignId: UUID }) => ({
    path: `/campaigns/${data.campaignId}/my_registration`,
    method: Method.Get,
    auth: true,
    data,
  }),
  FETCH_CERTIFICATE: (data: {
    campaignId: UUID;
    filename: string;
  }) => ({
    path: `/campaigns/${data.campaignId}/my_certificate.pdf`,
    method: Method.Get,
    auth: true,
    data,
    downloadFilename: data.filename,
  }),
  FETCH_CAMPAIGN_COMPLETION: (data: { id: UUID }) => ({
    path: `/campaigns/${data.id}/completion`,
    method: Method.Get,
    auth: true,
    data,
  }),
  SUBMIT_SESSION_CONTENT_ANSWER: (data: {
    sessionContentAssociation: { id: UUID; selectedAnswers: UUID[] };
  }) => ({
    path: `/session_content_associations/${data.sessionContentAssociation.id}`,
    method: Method.Patch,
    auth: true,
    data,
  }),
  UPLOAD_FILE: (data: { file: File }) => ({
    path: `/uploads`,
    method: Method.Post,
    auth: true,
    file: data.file,
  }),
  ASK_PASSWORD_RESET: (data: { email: string }) => ({
    path: `/users/reset_password`,
    method: Method.Post,
    data,
  }),
  ASK_PASSWORD_SET: (data: { email: string }) => ({
    path: `/users/set_password`,
    method: Method.Post,
    data,
  }),
  RESET_PASSWORD: (data: { password: string; token: string }) => ({
    path: `/users/reset_password/${data.token}`,
    method: Method.Patch,
    data,
  }),
  REQUEST_DEMO_ACCESS: (data: {
    email: string;
    first_name: string;
    last_name: string;
  }) => ({
    path: `/users/request_demo_access`,
    method: Method.Post,
    data,
  }),
  AUTOLOGIN: (data: { token: string }) => ({
    path: `/users/autologin`,
    method: Method.Post,
    data,
  }),
  SEND_CERTIFICATION_MAIL: (data: { campaignID: UUID }) => ({
    path: `/users/send_certification_email`,
    method: Method.Post,
    auth: true,
    data,
  }),
};

export default ROUTES;
