export default {
    'Définir le mot de passe': {
        en: 'Set the password',
        fr: 'Définir le mot de passe',
    },
    'Rentrez ici votre email afin de définir votre mot de passe': {
        en: 'Enter your email to set your password',
        fr: 'Rentrez ici votre email afin de définir votre mot de passe',
    },
    'Envoyer': {
        en: 'Send',
        fr: 'Envoyer',
    },
    'Déjà inscrit ?': {
        en: 'Already registered ?',
        fr: 'Déjà inscrit ?',
    },
    'Vous avez déjà un compte ? Se connecter': {
        en: 'You already have an account ? Sign in',
        fr: 'Vous avez déjà un compte ? Se connecter',
    },
}